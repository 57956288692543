import {
  driver,
  type AllowedButtons,
  type DriveStep,
  type Side,
} from 'driver.js';

import 'driver.js/dist/driver.css';

import { navigate } from 'svelte-navigator';
import { get } from 'svelte/store';
import { UserRole } from '@/interface/IUser';
import { t } from '@/locales/i18n';
import { userStore } from '@/store/userStore';

import { waitForElement } from './tourHelper';
import { hasUserCompletedTour, markTourAsCompleted } from './tourState';

const tour = driver();

const getConfig = () => {
  const translate = get(t);
  return {
    animate: true,
    showProgress: true,
    allowClose: true,
    showButtons: ['next', 'previous', 'close'] as AllowedButtons[],
    nextBtnText: translate('tour.step.next'),
    prevBtnText: translate('tour.step.previous'),
    doneBtnText: translate('tour.step.close'),
    onDestroyed: () => {
      markTourAsCompleted();
    },
    onCloseClick: () => {
      tour.destroy();
      markTourAsCompleted();
    },
  };
};

const getSteps = () => {
  const translate = get(t);

  return {
    users: [
      {
        element: '[data-tour="sidebar-menu-button-users"]',
        popover: {
          title: translate('tour.step.sidebar-users.title'),
          description: translate('tour.step.sidebar-users.description'),
          onNextClick: () => {
            navigate('/dashboard/users');
            waitForElement('[data-tour="dashboard-users-table"]', () => {
              tour.moveNext();
            });
          },
        },
      },
      {
        element: '[data-tour="dashboard-users-table"]',
        popover: {
          title: translate('tour.step.users-table.title'),
          description: translate('tour.step.users-table.description'),
          side: 'bottom' as Side,
        },
      },
      {
        element: '[data-tour="dashboard-users-invite-button"]',
        popover: {
          title: translate('tour.step.users-invite.title'),
          description: translate('tour.step.users-invite.description'),
          side: 'left' as Side,
        },
      },
    ],
    teams: [
      {
        element: '[data-tour="sidebar-menu-button-teams"]',
        popover: {
          title: translate('tour.step.sidebar-teams.title'),
          description: translate('tour.step.sidebar-teams.description'),
          onNextClick: () => {
            navigate('/dashboard/teams');
            waitForElement(
              '[data-tour="dashboard-teams-table-container"]',
              () => {
                tour.moveNext();
              },
            );
          },
        },
      },
      {
        element: '[data-tour="dashboard-teams-table-container"]',
        popover: {
          title: translate('tour.step.teams-table.title'),
          description: translate('tour.step.teams-table.description'),
          side: 'bottom' as Side,
        },
      },
      {
        element: '[data-tour="dashboard-teams-create-button"]',
        popover: {
          title: translate('tour.step.teams-create.title'),
          description: translate('tour.step.teams-create.description'),
          side: 'left' as Side,
        },
      },
    ],
    experiences: [
      {
        element: '[data-tour="sidebar-menu-button-experiences"]',
        popover: {
          title: translate('tour.step.sidebar-experiences.title'),
          description: translate('tour.step.sidebar-experiences.description'),
          onNextClick: () => {
            navigate('/dashboard/experiences');
            waitForElement('[data-tour="dashboard-experiences-card-0"]', () => {
              tour.moveNext();
            });
          },
        },
      },
      {
        element: '[data-tour="dashboard-experiences-card-0"]',
        popover: {
          title: translate('tour.step.experience-card.title'),
          description: translate('tour.step.experience-card.description'),
        },
      },
      {
        element: '[data-tour="dashboard-experiences-card-0-button"]',
        popover: {
          title: translate('tour.step.experience-start.title'),
          description: translate('tour.step.experience-start.description'),
          side: 'right' as Side,
        },
      },
      {
        element: '[data-tour="dashboard-experiences-monthly-card"]',
        popover: {
          title: translate('tour.step.experience-monthly.title'),
          description: translate('tour.step.experience-monthly.description'),
          side: 'right' as Side,
        },
      },
    ],
    sessions: [
      {
        element: '[data-tour="sidebar-menu-button-sessions"]',
        popover: {
          title: translate('tour.step.sidebar-sessions.title'),
          description: translate('tour.step.sidebar-sessions.description'),
          onNextClick: () => {
            navigate('/dashboard/sessions');
            waitForElement('[data-tour="dashboard-sessions-table"]', () => {
              tour.moveNext();
            });
          },
        },
      },
      {
        element: '[data-tour="dashboard-sessions-table"]',
        popover: {
          title: translate('tour.step.sessions-table.title'),
          description: translate('tour.step.sessions-table.description'),
          side: 'bottom' as Side,
        },
      },
      {
        element:
          '[data-tour="dashboard-sessions-table"] tbody tr:first-child td:last-child',
        popover: {
          title: translate('tour.step.sessions-actions.title'),
          description: translate('tour.step.sessions-actions.description'),
          side: 'left' as Side,
        },
      },
    ],
    settings: [
      {
        element: '[data-tour="sidebar-menu-button-settings"]',
        popover: {
          title: translate('tour.step.sidebar-settings.title'),
          description: translate('tour.step.sidebar-settings.description'),
          onNextClick: () => {
            navigate('/dashboard/settings');
            waitForElement('[data-tour="settings-guide-section"]', () => {
              tour.moveNext();
            });
          },
        },
      },
      {
        element: '[data-tour="settings-guide-section"]',
        popover: {
          title: translate('tour.step.settings-guide.title'),
          description: translate('tour.step.settings-guide.description'),
          side: 'left' as Side,
        },
      },
    ],
  };
};

const getStepsForRole = (role: string): DriveStep[] => {
  const steps = getSteps();
  switch (role) {
    case UserRole.SUPER_ADMIN:
    case UserRole.ADMIN:
      return [
        ...(steps['users'] || []),
        ...(steps['teams'] || []),
        ...(steps['experiences'] || []),
        ...(steps['sessions'] || []),
        ...(steps['settings'] || []),
      ];
    case UserRole.HOST:
      return [
        ...(steps['teams'] || []),
        ...(steps['experiences'] || []),
        ...(steps['sessions'] || []),
        ...(steps['settings'] || []),
      ];
    case UserRole.USER:
      return [
        ...(steps['experiences'] || []),
        ...(steps['sessions'] || []),
        ...(steps['settings'] || []),
      ];
    default:
      return [];
  }
};

const initTourWithRole = (checkCompleted = false) => {
  const role = get(userStore)?.role;
  if (!role || (checkCompleted && hasUserCompletedTour())) return;

  const steps = getStepsForRole(role);
  if (steps.length === 0) return;

  const config = getConfig();
  tour.setConfig(config);
  tour.setSteps(steps);
  tour.drive();
};

export const startTour = () => initTourWithRole();
export const startFirstTimeTour = () => initTourWithRole(true);
