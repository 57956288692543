import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import { ApiError } from './api-error';

const Api = axios.create({
  baseURL: import.meta.env['VITE_API_URL'],
});

export default Api;

const handleApiError = (error: unknown): never => {
  if (axios.isAxiosError(error) && error.response?.data) {
    throw new ApiError(error.response.data);
  }
  throw error;
};

const handleResponse = <T>(response: AxiosResponse<T>): T => {
  return response.data;
};

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await Api.get<T>(url, config);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const post = async <T>(
  url: string,
  payload?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await Api.post(url, payload, config);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const patch = async <T>(
  url: string,
  payload?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await Api.patch(url, payload, config);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const put = async <T>(
  url: string,
  payload?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await Api.put(url, payload, config);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const postFormData = async (
  url: string,
  payload?: FormData,
): Promise<AxiosResponse> => {
  try {
    const response = await Api.post(url, payload);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const del = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response = await Api.delete(url, config);
    return handleResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};
