<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { UserRole } from '@/interface/IUser';
  import type { FilterOption } from '@/lib/components/filter-dropdowns/filter-dropdown.types';
  import FilterDropdown from '@/lib/components/filter-dropdowns/FilterDropdown.svelte';
  import type { UserRoleFilterEvent } from '@/lib/components/filter-dropdowns/user-role-filter/user-role-filter.types';
  import { t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    changeRoles: UserRoleFilterEvent;
  }>();

  export let selectedRoles: UserRole[] = [];

  const roles: FilterOption<UserRole>[] = [
    { value: UserRole.ADMIN, label: 'Admin' },
    { value: UserRole.HOST, label: 'Host' },
    { value: UserRole.USER, label: 'User' },
  ];

  function handleSelectionChange(event: CustomEvent<{ values: UserRole[] }>) {
    const values = event.detail.values;
    selectedRoles = values;
    dispatch('changeRoles', { roles: selectedRoles });
  }
</script>

<FilterDropdown
  selectedValues={selectedRoles}
  options={roles}
  title={$t('dashboard.users.filter-roles')}
  placeholderText={$t('dashboard.users.filter-all-roles')}
  multipleSelectionText={$t('dashboard.users.roles-selected')}
  clearText={$t('dashboard.users.clear-selection')}
  on:changeSelection={handleSelectionChange}
/>
