<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    exchangeOAuthCode,
    refreshSession,
  } from '@/lib/services/together/user.api';
  import { t } from '@/locales/i18n';

  onMount(async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const returnedState = urlParams.get('state');
      const storedState = localStorage.getItem('oauthState');

      if (code && returnedState === storedState) {
        localStorage.removeItem('oauthState');
        await exchangeOAuthCode(code);
        await refreshSession();
        ToastNotification.success($t('oauth.connect-success'));
        navigate('/dashboard', { replace: true });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      ToastNotification.error($t('oauth.connect-error'));
      navigate('/dashboard', { replace: true });
    }
  });
</script>

<svelte:head>
  <title>{$t('dashboard.oauth.route-title')}</title>
</svelte:head>

<div class="flex h-screen items-center justify-center">
  <Loading loadingSize="h-32 w-32" />
</div>
