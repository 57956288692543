<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import type { FilterOption } from '@/lib/components/filter-dropdowns/filter-dropdown.types';
  import FilterDropdown from '@/lib/components/filter-dropdowns/FilterDropdown.svelte';
  import type { StarRatingFilterEvent } from '@/lib/components/filter-dropdowns/star-rating-filter/star-rating-filter.types';
  import { t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    changeRating: StarRatingFilterEvent;
  }>();

  export let selectedStars: number[] = [];

  const starsOptions: FilterOption<number>[] = [
    { value: 1, label: '1 ' + $t('dashboard.experiences.stars') },
    { value: 2, label: '2 ' + $t('dashboard.experiences.stars') },
    { value: 3, label: '3 ' + $t('dashboard.experiences.stars') },
    { value: 4, label: '4 ' + $t('dashboard.experiences.stars') },
    { value: 5, label: '5 ' + $t('dashboard.experiences.stars') },
  ];

  function handleSelectionChange(event: CustomEvent<{ values: number[] }>) {
    const values = event.detail.values;
    selectedStars = values;
    dispatch('changeRating', { rating: selectedStars });
  }
</script>

<FilterDropdown
  selectedValues={selectedStars}
  options={starsOptions}
  title={$t('dashboard.experiences.rating')}
  placeholderText={$t('dashboard.experiences.select-rating')}
  multipleSelectionText={$t('dashboard.experiences.ratings-selected')}
  clearText={$t('common.clear')}
  on:changeSelection={handleSelectionChange}
/>
