<script lang="ts">
  export let label = '';
  export let name = '';
  export let testId = '';
  export let value: string | string[] | number | undefined = '';
  export let checked = false;
  export let placeholder = '';
  export let styleClass = '';
  export let error = '';
  export let type = 'text';
  export let options: { value: string; label: string }[] = [];
  export let disabled = false;
  export let rows: number | undefined = undefined;
  export let max: number | undefined = undefined;
  export let min: number | undefined = undefined;
  export let minDate: string | undefined = undefined;
  export let accept: string | undefined = undefined;
</script>

<div class={styleClass}>
  {#if label}
    <div class="mb-1">
      <label class="w-full text-sm font-medium text-gray-900" for={name}
        ><span>{label}</span></label
      >
    </div>
  {/if}
  {#if type === 'password'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'text'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'email'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'number'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'textarea'}
    <textarea
      id={name}
      data-cy={testId}
      name={name}
      disabled={disabled}
      bind:value={value}
      on:change
      on:input
      placeholder={placeholder}
      class="w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
      rows={rows}
    />
  {:else if type === 'date'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={minDate}
        placeholder={placeholder}
        bind:value={value}
      />
      <slot />
    </div>
  {:else if type === 'checkbox'}
    <div class="relative flex w-full flex-row items-center justify-start">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="me-3 rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        type="checkbox"
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:checked={checked}
      />
      <slot />
    </div>
  {:else if type === 'select'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <select
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        placeholder={placeholder}
        bind:value={value}
      >
        {#each options as option}
          <option value={option.value}>{option.label}</option>
        {/each}
      </select>
    </div>
  {:else if type === 'image'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        {...{ type }}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        bind:value={value}
        type="file"
        accept="image/*"
      />
      <slot />
    </div>
  {:else if type === 'file'}
    <div class="relative flex w-full flex-row items-center justify-center">
      <input
        id={name}
        data-cy={testId}
        disabled={disabled}
        on:change
        on:input
        class="w-full rounded-xl p-3 text-dark-grey shadow-sm ring-1 ring-inset ring-grey placeholder:text-grey focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400"
        type="file"
        name={name}
        accept={accept}
      />
      <slot />
    </div>
  {/if}

  {#if error}
    <span
      data-cy={`${name}-error`}
      class="w-full bg-white text-xs font-medium text-red-600">{error}</span
    >
  {/if}
</div>
