<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
  import Api from '@/lib/services/axios/api-axios';
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';
  import type { IMiniGamesResponse } from '@/routes/mini-games/@types/IMiniGamesResponse';
  import { MiniGamesEnum } from '@/routes/mini-games/@types/MiniGames.enum';

  const getMiniGames = async () => {
    try {
      const { data } = await Api.get<IMiniGamesResponse>('/mini-game');
      const miniGames = data.miniGames;
      return miniGames;
    } catch {
      return [];
    }
  };

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link);
    ToastNotification.success('Link copied to clipboard');
  };

  const gameNameMapper = (gameName: MiniGamesEnum) => {
    switch (gameName) {
      case MiniGamesEnum.ROULETTE:
        return 'Roulette';
      case MiniGamesEnum.LUCKY_CARDS:
        return 'Lucky Cards';
    }
  };
</script>

<svelte:head>
  <title>{$t('dashboard.mini-games.route-title')}</title>
</svelte:head>

<Page>
  <div class="flex h-full w-full grow flex-col items-center bg-slate-100">
    <div class="my-10 text-center">
      <h2 class="text-4xl font-bold" data-cy="mini-games-title">Mini Games</h2>
      <h3
        class="text-xl font-semibold text-black/50"
        data-cy="mini-games-subtitle"
      >
        List of loaded mini games ready to play
      </h3>
    </div>
    <div class="flex flex-wrap justify-center gap-1" data-cy="mini-games-list">
      <div
        class="game-card cols-span-1 flex flex-col items-center justify-center"
      >
        <h2 class="text-2xl font-bold">Dice</h2>
        <h3 class="py-1 text-xl font-semibold">Roll Dices Mini Game</h3>
        <p class="break-words py-1 text-sm">You can roll one or two dices</p>
        <div class="mt-4 flex flex-row">
          <a class="play-button" href="/mini-games/dice">Play</a>
          <button
            class="copy-link-button"
            on:click={() =>
              copyToClipboard(
                `${import.meta.env['VITE_BASE_URL']}/mini-games/dice`,
              )}
          >
            Copy link
          </button>
        </div>
      </div>

      {#await getMiniGames() then miniGames}
        {#each miniGames as { id, gameName, title, description }, i}
          <div
            class="game-card cols-span-1 flex flex-col items-center justify-center"
            data-cy="mini-game-card-{i + 1}"
          >
            <h2
              class="text-2xl font-bold"
              data-cy="mini-game-card-{i + 1}-game-name"
            >
              {gameNameMapper(gameName)}
            </h2>
            <h3
              class="py-1 text-xl font-semibold"
              data-cy="mini-game-card-{i + 1}-title"
            >
              {title}
            </h3>
            <p
              class="break-words py-1 text-sm"
              data-cy="mini-game-card-{i + 1}-description"
            >
              {description}
            </p>
            <div
              class="mt-4 flex flex-row"
              data-cy="mini-game-card-{i + 1}-options"
            >
              <a
                class="play-button"
                href="/mini-games/{gameName}/{id}"
                data-cy="mini-game-card-{i + 1}-options-play">Play</a
              >
              <button
                class="copy-link-button"
                on:click={() =>
                  copyToClipboard(
                    `${
                      import.meta.env['VITE_BASE_URL']
                    }/mini-games/${gameName}/${id}`,
                  )}
                data-cy="mini-game-card-{i + 1}-options-copy-link"
                >Copy link</button
              >
            </div>
          </div>
        {/each}
        {#if !miniGames.length}
          <p class="text-center" data-cy="no-mini-games-message">
            No mini games found
          </p>
        {/if}
      {:catch}
        <p class="text-center" data-cy="error-message">Something went wrong</p>
      {/await}
    </div>
  </div>
</Page>

<style>
  .game-card {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    margin: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    min-height: 200px;
  }

  .game-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .play-button,
  .copy-link-button {
    padding: 5px 8px;
    margin-top: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    display: inline-block;
    text-decoration: none;
  }

  .play-button {
    background-color: #4a90e2;
    color: white;
  }

  .copy-link-button {
    background-color: #ccc;
    color: black;
    margin-left: 4px;
  }
</style>
