import { get } from 'svelte/store';
import { ExperienceLanguages } from '@/interface/IExperience';
import type { IUser } from '@/interface/IUser';
import {
  EnvelopeIcon,
  PowerIcon,
  TrashIcon,
  UserPlusIcon,
} from '@/lib/components/icons';
import { openModal } from '@/lib/components/modal/modal.store';
import AssignRoleModal from '@/lib/components/modal/modals/AssignRoleModal.svelte';
import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
import type { Action, TableItem } from '@/lib/components/tables/table.types';
import { ApiError } from '@/lib/services/axios/api-error';
import { ToastNotification } from '@/lib/services/toast';
import { updateUser } from '@/lib/services/together/together.api';
import {
  deleteUser,
  resendUserCredentials,
} from '@/lib/services/together/user.api';
import { locale, t } from '@/locales/i18n';
import { userStore } from '@/store/userStore';

const ActionsNames = {
  ASSIGN_ROLE: 'assign-role',
  RESEND_CREDENTIALS: 'resend-credentials',
  DELETE_USER: 'delete-user',
  ACTIVATE_USER: 'activate-user',
  DEACTIVATE_USER: 'deactivate-user',
};

export const handleAssignRole = (
  user: IUser,
  fetchUsers: () => Promise<void>,
) =>
  openModal(AssignRoleModal, {
    allowCloseClick: false,
    props: { user, onSubmit: fetchUsers },
  });

export const handleResendCredentials = async (user: IUser) => {
  const translate = get(t);
  const resendCredentials = async (user: IUser) => {
    try {
      const currentLocale = get(locale);
      const language =
        currentLocale === 'en'
          ? ExperienceLanguages.english
          : ExperienceLanguages.spanish;
      await resendUserCredentials(user, language);
      ToastNotification.success(
        translate('dashboard.users.resend-credentials-success'),
      );
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        ToastNotification.error({
          title: translate('dashboard.users.resend-credentials-error'),
          message: error.details ?? error.message,
        });
      } else {
        ToastNotification.error({
          title: translate('dashboard.users.resend-credentials-error'),
          message: 'Contact support for more information',
        });
      }
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      text:
        translate('dashboard.users.resend-credentials-modal-text') + user.email,
      onSubmit: () => resendCredentials(user),
    },
  });
};

export const handleDeleteUser = async (
  user: IUser,
  fetchUsers: () => Promise<void>,
  updateOffset?: () => void,
) => {
  const translate = get(t);
  const confirmDeleteUser = async (user: IUser) => {
    try {
      await deleteUser(user);
      if (updateOffset) {
        updateOffset();
      }
      fetchUsers();
      ToastNotification.success(
        translate('dashboard.users.delete-user-success'),
      );
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        ToastNotification.error({
          title: translate('dashboard.users.delete-user-error'),
          message: error.details ?? error.message,
        });
      } else {
        ToastNotification.error({
          title: translate('dashboard.users.delete-user-error'),
          message: 'Contact support for more information',
        });
      }
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      title: translate('dashboard.users.delete-user-modal-title'),
      text: translate('dashboard.users.delete-user-modal-text') + user.email,
      onSubmit: () => confirmDeleteUser(user),
    },
  });
};

export const handleSetActive = async (
  user: IUser,
  fetchUsers: () => Promise<void>,
) => {
  const translate = get(t);
  const confirmSetActive = async (user: IUser) => {
    try {
      await updateUser(user.id, {
        isActive: !user.isActive,
        role: user.role,
      });
      fetchUsers();
      const succesMessage = user.isActive
        ? translate('dashboard.users.deactivate-user-success')
        : translate('dashboard.users.activate-user-success');
      ToastNotification.success(succesMessage);
    } catch (error: unknown) {
      const errorMessage = user.isActive
        ? translate('dashboard.users.deactivate-user-error')
        : translate('dashboard.users.activate-user-error');

      if (error instanceof ApiError) {
        ToastNotification.error({
          title: errorMessage,
          message: error.details ?? error.message,
        });
      } else {
        ToastNotification.error({
          title: errorMessage,
          message: 'Contact support for more information',
        });
      }
    }
  };

  const modalTitle = user.isActive
    ? translate('dashboard.users.deactivate-user-modal-title')
    : translate('dashboard.users.activate-user-modal-title');
  const modalText = user.isActive
    ? translate('dashboard.users.deactivate-user-modal-text')
    : translate('dashboard.users.activate-user-modal-text');

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      title: modalTitle,
      text: modalText.replace('{{email}}', user.email),
      onSubmit: () => confirmSetActive(user),
    },
  });
};

export const getUserActions = (
  fetchUsers: () => Promise<void>,
  updateOffset?: () => void,
): Action<TableItem>[] => {
  const translate = get(t);
  const currentUser = get(userStore);

  return [
    {
      name: ActionsNames.ASSIGN_ROLE,
      infoText: translate('dashboard.users.table-actions-assign-role'),
      onAction: (item: TableItem) => {
        handleAssignRole(item as IUser, fetchUsers);
      },
      disabledCondition: (user: TableItem) =>
        (user as IUser).id === currentUser!.id || !(user as IUser).isActive,
      hideOnDisabled: true,
      icon: UserPlusIcon,
    },
    {
      name: ActionsNames.RESEND_CREDENTIALS,
      infoText: translate('dashboard.users.table-actions-resend-credentials'),
      onAction: (item: TableItem) => {
        void handleResendCredentials(item as IUser);
      },
      disabledCondition: (user: TableItem) =>
        (user as IUser).id === currentUser!.id || !(user as IUser).isActive,
      hideOnDisabled: true,
      icon: EnvelopeIcon,
    },
    {
      name: ActionsNames.DELETE_USER,
      infoText: translate('dashboard.users.table-actions-delete'),
      onAction: (item: TableItem) => {
        void handleDeleteUser(item as IUser, fetchUsers, updateOffset);
      },
      disabledCondition: (user: TableItem) =>
        (user as IUser).id === currentUser!.id ||
        (user as IUser).sessions.length > 0,
      hideOnDisabled: true,
      icon: TrashIcon,
    },
    {
      name: ActionsNames.ACTIVATE_USER,
      infoText: translate('dashboard.users.table-actions-activate'),
      onAction: (item: TableItem) => {
        void handleSetActive(item as IUser, fetchUsers);
      },
      disabledCondition: (user: TableItem) =>
        (user as IUser).id === currentUser!.id || (user as IUser).isActive,
      hideOnDisabled: true,
      icon: PowerIcon,
    },
    {
      name: ActionsNames.DEACTIVATE_USER,
      infoText: translate('dashboard.users.table-actions-deactivate'),
      onAction: (item: TableItem) => {
        void handleSetActive(item as IUser, fetchUsers);
      },
      disabledCondition: (user: TableItem) =>
        (user as IUser).id === currentUser!.id || !(user as IUser).isActive,
      hideOnDisabled: true,
      icon: PowerIcon,
    },
  ];
};
