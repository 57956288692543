import { toast } from '@zerodevx/svelte-toast';
import type { SvelteToastOptions } from '@zerodevx/svelte-toast/stores';

interface ToastMessage {
  title?: string;
  message: string;
}

export class ToastNotification {
  private static formatMessage(message: string | ToastMessage): string {
    if (typeof message === 'string') {
      return message;
    }

    const titleSection = message.title ? `<b>${message.title}</b><br>` : '';
    return (
      titleSection + `<span data-cy="toast-message">${message.message}</span>`
    );
  }

  static success(message: string | ToastMessage, options?: SvelteToastOptions) {
    const toastMessage = this.formatMessage(message);

    toast.push(toastMessage, {
      theme: {
        '--toastColor': 'black',
        '--toastBackground': '#90EE90',
        '--toastBarBackground': '#008000',
      },
      ...options,
    });
  }

  static error(message: string | ToastMessage, options?: SvelteToastOptions) {
    const toastMessage = this.formatMessage(message);

    toast.push(toastMessage, {
      theme: {
        '--toastColor': '#FF0000',
        '--toastBackground': '#FFC0CB',
        '--toastBarBackground': '#FF0000',
      },
      ...options,
    });
  }

  static warning(message: string | ToastMessage, options?: SvelteToastOptions) {
    const toastMessage = this.formatMessage(message);

    toast.push(toastMessage, {
      theme: {
        '--toastColor': 'black',
        '--toastBackground': '#FFD700',
        '--toastBarBackground': '#FFA500',
      },
      ...options,
    });
  }

  static normal(message: string | ToastMessage, options?: SvelteToastOptions) {
    const toastMessage = this.formatMessage(message);

    toast.push(toastMessage, {
      theme: {
        '--toastBackground': 'white',
        '--toastColor': 'black',
        '--toastBarBackground': '#6346FA',
      },
      ...options,
    });
  }

  static customizable(options?: SvelteToastOptions) {
    toast.push({
      ...options,
    });
  }

  static removeToasts() {
    toast.pop();
  }

  static removeToastById(id: number) {
    toast.pop(id);
  }
}
