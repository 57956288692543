<script lang="ts">
  import ExperienceCatalog from '@/lib/components/experiences-catalog/ExperienceCatalog.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>{$t('dashboard.experiences.route-title')}</title>
</svelte:head>

<div
  class="flex h-full w-full flex-col bg-gray-50"
  data-tour="dashboard-experiences"
>
  <div
    class="flex min-h-[4rem] w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 ps-4 text-2xl font-bold text-primary">
      {$t('dashboard.experiences.title')}
    </h1>
  </div>
  <div class="flex-1 overflow-auto px-6 py-3">
    <div
      class="flex h-fit min-h-full w-full flex-col justify-center rounded-lg border border-light-grey bg-white shadow-sm"
      data-tour="dashboard-experiences-catalog-container"
    >
      <div class="px-6 pb-2 pt-2">
        <ExperienceCatalog />
      </div>
    </div>
  </div>
</div>
