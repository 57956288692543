<script lang="ts">
  import { startTour } from '@/lib/actions/tour/tour';
  import { t } from '@/locales/i18n';

  import ArrowIcon from '../icons/ArrowIcon.svelte';

  const handleTourStart = () => {
    startTour();
  };
</script>

<button
  class="flex w-fit items-center gap-2 rounded-lg bg-primary px-4 py-2 text-white transition-all hover:bg-primary/90 active:scale-95"
  on:click={handleTourStart}
  data-cy="tour-trigger-button"
>
  <ArrowIcon style="h-5 w-5 -rotate-90" />
  <span class="font-medium"
    >{$t('dashboard.settings.general.guide.start-tour')}</span
  >
</button>
