<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import LockIcon from '@/assets/svg/LockIcon.svelte';
  import StarIcon from '@/assets/svg/StarIcon.svelte';
  import { planNames, type IExperience } from '@/interface/IExperience';
  import Button from '@/lib/components/button/Button.svelte';
  import UserIcon from '@/lib/components/icons/UserIcon.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { locale, t } from '@/locales/i18n';

  export let experience: IExperience;
  export let isLandingPageCard = false;
  export let blockUser = false;
  export let startSession: ((experience: IExperience) => void) | undefined =
    undefined;
  export let monthlyExperience = false;
  export let tourIdentifier = '';
  export let testId = '';

  const handleBlockUser = () => {
    const errorMessage = $t('dashboard.experiences.block-user-error').split(
      '-',
    );
    ToastNotification.error(
      `${errorMessage[0]} ${
        monthlyExperience ? planNames[1] : planNames[2]
      } plan to use this experience`,
    );
  };

  const navigateToDetails = () => {
    navigate(`/dashboard/experiences/${experience.id}`);
  };

  $: categoryName =
    $locale === 'en'
      ? experience.category.englishName
      : experience.category.spanishName;

  $: rating =
    experience.totalRaters > 0
      ? (experience.totalRatings / experience.totalRaters).toFixed(1)
      : '0';
  $: ratingValue = parseFloat(rating) || 0;

  $: participantsText = experience.minParticipants
    ? `${experience.minParticipants}${experience.maxParticipants ? ` - ${experience.maxParticipants}` : ''}`
    : '';

  $: cardBorderClasses = monthlyExperience
    ? 'border-glow'
    : 'border border-light-grey';

  $: cardWidthClasses = isLandingPageCard
    ? 'w-fit min-w-[24rem] sm:max-w-[16rem] md:min-w-[22rem] md:max-w-fit lg:min-w-[18rem] lg:max-w-[30rem] xl:min-w-[20rem] min-[1650px]:w-[28rem]'
    : 'w-80';

  $: truncatedDescription =
    experience.description.length > 100
      ? `${experience.description.substring(0, 100)}...`
      : experience.description;

  function getStarType(starPosition: number, rating: number) {
    const ratingNum = rating || 0;

    if (starPosition <= Math.floor(ratingNum)) {
      return 'filled';
    } else if (starPosition === Math.ceil(ratingNum) && ratingNum % 1 !== 0) {
      return 'half';
    } else {
      return 'empty';
    }
  }
</script>

<div
  class="flex flex-col justify-between rounded-lg {cardBorderClasses} {cardWidthClasses} relative h-[460px]"
  data-tour={tourIdentifier}
  data-cy={testId}
>
  {#if monthlyExperience}
    <div class="ribbon ribbon-top-left">
      <span>{$t('dashboard.experiences.monthly-experience')}</span>
    </div>
  {/if}

  <div class="card-content relative flex h-full flex-col">
    <img
      class="h-56 w-full rounded-t-[0.3rem] object-cover object-center"
      src={experience.imageUrl}
      alt={experience.name}
    />

    <div class="flex w-full flex-col p-3 pb-0">
      <div class="mb-1 flex items-center justify-between">
        <span
          class="rounded-sm bg-primary px-2 py-0.5 text-xs font-medium text-white"
          >{categoryName}</span
        >

        {#if !isLandingPageCard && experience.minParticipants}
          <div class="flex flex-row items-center gap-1">
            <UserIcon
              style="bg-primary w-5 h-5 p-1 relative bottom-[.03rem] rounded-full fill-white"
            /><span class="text-sm font-medium text-primary"
              >{participantsText}</span
            >
          </div>
        {/if}
      </div>

      <div class="flex flex-row items-start justify-between">
        <h4
          class="max-w-[85%] truncate pr-2 font-bogart text-lg font-bold text-primary"
          title={experience.name}
        >
          {experience.name}
        </h4>

        <div class="ml-2 mt-1 flex flex-shrink-0 items-center">
          <div class="flex items-center" title={`Rating: ${rating}`}>
            <span class="mr-1 text-xs font-medium text-grey">{rating}</span>
            {#each [1, 2, 3, 4, 5] as i}
              {@const starType = getStarType(i, ratingValue)}
              <StarIcon
                style="mb-[0.15rem] w-3 h-3 mx-[1px] {starType === 'filled'
                  ? 'fill-primary'
                  : starType === 'half'
                    ? 'fill-primary-light'
                    : 'fill-none stroke-grey stroke-1'}"
              />
            {/each}
          </div>
        </div>
      </div>

      <div class="mt-2 flex h-[100px] flex-col">
        <p class="line-clamp-3 text-sm text-dark-grey">
          {truncatedDescription}
        </p>
        {#if experience.description.length > 100}
          <button
            class="mt-1 self-end text-xs font-medium text-primary hover:underline"
            on:click={navigateToDetails}
          >
            {$t('common.view-more')}
          </button>
        {/if}
      </div>
    </div>

    <div class="mt-auto flex flex-row items-center justify-between p-3">
      {#if isLandingPageCard}
        <Button
          style="outline-reverse"
          testId={`landing-page-play-experience-button-${experience.id}`}
          on:click={() => navigate('/login')}
        >
          <span>{$t('experience.play-button')}</span>
          <ChevronIcon
            style="w-[0.4rem] mx-1 fill-white group-hover:fill-gray-500 transition ease-in duration-100"
          />
        </Button>
      {:else}
        <Button
          testId={`play-experience-button-${experience.id}`}
          disabled={!startSession}
          style={blockUser ? 'outline' : 'fill'}
          tourIdentifier={tourIdentifier + '-button'}
          on:click={() =>
            blockUser ? handleBlockUser() : startSession?.(experience)}
        >
          <span>{$t('experience.play-button')}</span>
          {#if blockUser}
            <LockIcon
              buttonLockIconTextColor="fill-grey group-hover:fill-white transition ease-in duration-100"
            />
          {:else}
            <ChevronIcon style="w-[0.4rem] mx-1 fill-white" />
          {/if}
        </Button>
        <Button
          disabled={blockUser}
          testId={`view-experience-board-button-${experience.id}`}
          style="outline"
          on:click={() =>
            !blockUser && window.open(experience.templateUrl, '_blank')}
        >
          <span>{$t('experience.view-board-button')}</span>
        </Button>
      {/if}
    </div>
  </div>
</div>

<style>
  .ribbon {
    width: 180px;
    height: 180px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
  }

  .ribbon span {
    position: absolute;
    display: block;
    width: 260px;
    padding: 5px 0;
    background-color: var(--brand-yellow, #ffd600);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: var(--primary, #1d2939);
    font-size: 0.65rem;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  .ribbon-top-left {
    top: -6px;
    left: -10px;
  }

  .ribbon-top-left span {
    right: -16px;
    top: 54px;
    transform: rotate(-45deg);
  }

  @keyframes shine {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .ribbon span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
    animation: shine 8s linear infinite;
  }

  .border-glow {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 3px;
    background: linear-gradient(90deg, #ffd600, #ffeb80, #ffd600);
    background-size: 300% 100%;
    animation: gradient 8s ease infinite;
  }

  .card-content {
    background-color: white;
    border-radius: 0.3rem;
    overflow: hidden;
    height: 100%;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  :global(.fill-primary-light) {
    fill: #5d96c5; /* Un tono más claro del color primario para estrellas parcialmente llenas */
  }
</style>
