export interface IApiError {
  code: string;
  message: string;
  details?: string;
  statusCode?: number;
}

export class ApiError extends Error implements IApiError {
  code: string;
  details?: string;
  statusCode?: number;

  constructor(error: IApiError) {
    super(error.message);
    this.code = error.code;
    this.details = error.details;
    this.statusCode = error.statusCode;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
