<script lang="ts">
  import { validator } from '@felte/validator-yup';
  import { createForm } from 'felte';
  import type * as yup from 'yup';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import Button from '@/lib/components/button/Button.svelte';
  import Input from '@/lib/components/input/Input.svelte';
  import type { SignupFormStepType } from '@/lib/components/signup-form/@types/SubmissionFormTypes';
  import { signUpFormSchema } from '@/lib/components/signup-form/schema/submission-form.schema';
  import { t } from '@/locales/i18n';

  export let initialValues: SignupFormStepType;
  export let onSubmit: (values: any) => void;
  export let onBack: ((values: SignupFormStepType) => void) | undefined =
    undefined;

  const { form, errors, data, isSubmitting, isValid } =
    createForm<SignupFormStepType>({
      initialValues: {
        orgName: initialValues.orgName || '',
        name: initialValues.name || '',
        lastName: initialValues.lastName || '',
        email: initialValues.email || '',
        acceptTerms: initialValues.acceptTerms || false,
        language: initialValues.language || ExperienceLanguages.english,
      },
      extend: [validator({ schema: signUpFormSchema })],
      onSubmit: onSubmit,
    });

  function handleBack() {
    if (onBack) {
      onBack($data as SignupFormStepType);
    }
  }

  type FormData = Omit<yup.InferType<typeof signUpFormSchema>, 'acceptTerms'>;

  type Field = {
    name: keyof FormData;
    label: string;
    type: string;
    placeholder: string;
  };

  const fields: Field[] = [
    {
      name: 'orgName',
      label: $t('signup.form-orgname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-orgname-input-placeholder'),
    },
    {
      name: 'name',
      label: $t('signup.form-name-input-label'),
      type: 'text',
      placeholder: $t('signup.form-name-input-placeholder'),
    },
    {
      name: 'lastName',
      label: $t('signup.form-lastname-input-label'),
      type: 'text',
      placeholder: $t('signup.form-lastname-input-placeholder'),
    },
    {
      name: 'email',
      label: $t('signup.form-email-input-label'),
      type: 'email',
      placeholder: $t('signup.form-email-input-placeholder'),
    },
  ];
</script>

<p
  class="mb-4 text-center text-xl text-dark-grey"
  data-cy="submission-form-subtitle"
>
  {@html $t('signup.form-subtitle')}
</p>
<form use:form class="flex w-full flex-col gap-4">
  {#each fields as field}
    <Input
      bind:value={initialValues[field.name]}
      name={field.name}
      type={field.type}
      placeholder={field.placeholder}
      testId={`${field.name}-input`}
      error={$errors[field.name] ? $errors[field.name]?.[0] : ''}
      styleClass="mt-0.5"
    />
  {/each}
  <Input
    type="checkbox"
    bind:checked={initialValues.acceptTerms}
    name="acceptTerms"
    error={$errors['acceptTerms'] ? $errors['acceptTerms']?.[0] : ''}
    testId="accept-terms"
  >
    <span>
      {@html $t('signup.form-accept-terms')}
    </span>
  </Input>
  <div class="mt-4 flex items-center justify-center gap-4">
    {#if onBack}
      <Button testId="signup-form-back-btn" on:click={handleBack}>
        {$t('signup.button-back')}
      </Button>
    {/if}
    <Button
      submit={true}
      disabled={$isSubmitting || !$isValid}
      testId="signup-form-next-btn"
    >
      {$t('signup.button-next')}
    </Button>
  </div>
</form>
