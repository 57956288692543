import moment from 'moment';
import { get } from 'svelte/store';
import { SessionStatus, type ISession } from '@/interface/ISession';
import {
  BadgeVariant,
  TemplateType,
  type Column,
  type TableItem,
} from '@/lib/components/tables/table.types';
import { t } from '@/locales/i18n';

const teamSessionTableKeys = {
  host: 'host',
  name: 'name',
  experience: 'experience',
  participants: 'participants',
  date: 'date',
  status: 'status',
};

export function getTeamSessionColumns(): Column<TableItem>[] {
  const translate = get(t);
  return [
    {
      key: teamSessionTableKeys.host,
      label: translate('dashboard.sessions.table-host'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const session = item as ISession;
        return {
          text: `${session.host.name} ${session.host.lastName}`,
        };
      },
    },
    {
      key: teamSessionTableKeys.name,
      label: translate('dashboard.sessions.table-name'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const session = item as ISession;
        return { text: session.name };
      },
    },
    {
      key: teamSessionTableKeys.experience,
      label: translate('dashboard.sessions.table-experience'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const session = item as ISession;
        return { text: session.experience.name };
      },
    },
    {
      key: teamSessionTableKeys.date,
      label: translate('dashboard.sessions.table-date'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const session = item as ISession;
        return {
          text: moment(session.scheduledDate.slice(0, 10)).format('DD MMM YY'),
        };
      },
    },
    {
      key: teamSessionTableKeys.status,
      label: translate('dashboard.sessions.table-status'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const session = item as ISession;
        const status = session.status;
        let variant: BadgeVariant = BadgeVariant.WARNING;

        switch (status) {
          case SessionStatus.FINISHED:
            variant = BadgeVariant.SUCCESS;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-finished'),
            };
          case SessionStatus.CANCELED:
            variant = BadgeVariant.ERROR;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-canceled'),
            };
          case SessionStatus.PENDING:
          default:
            variant = BadgeVariant.WARNING;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-pending'),
            };
        }
      },
    },
  ];
}
