<script lang="ts">
  import moment from 'moment';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import type { IExperience } from '@/interface/IExperience';
  import { UserRole } from '@/interface/IUser';
  import Button from '@/lib/components/button/Button.svelte';
  import PlayIcon from '@/lib/components/icons/PlayIcon.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import StartSessionModal from '@/lib/components/modal/modals/StartSessionModal.svelte';
  import {
    getExperienceById,
    getExperienceFeedback,
    getSessionSchedule,
  } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import ExperienceDetails from '@/routes/dashboard/experiences/ExperienceDetails.svelte';
  import UserFeedbackSection from '@/routes/dashboard/experiences/UserFeedbackSection.svelte';
  import { userStore } from '@/store/userStore';

  export let id: string;

  const isUser = $userStore?.role === UserRole.USER;
  let monthlyExperience: IExperience | null = null;

  const handleStartSession = (experience: IExperience) => {
    openModal(StartSessionModal, {
      allowCloseClick: false,
      props: {
        experience,
      },
    });
  };

  onMount(async () => {
    try {
      const thisMonth = moment().format('MMMM');
      const sessionEvent = await getSessionSchedule(thisMonth);
      const newMonthlyExperience =
        $locale === 'en'
          ? sessionEvent.englishExperience
          : sessionEvent.spanishExperience;

      monthlyExperience = newMonthlyExperience || null;
    } catch (error) {
      monthlyExperience = null;
    }
  });

  $: loadingText = $t('dashboard.experiences.loading-experience');
  $: backButtonLabel = $t('dashboard.experiences.back-to-list');
  $: mainContentLabel = $t('dashboard.experiences.main-content-aria-label');
</script>

<svelte:head>
  <title>{$t('dashboard.experiences.route-title')}</title>
</svelte:head>

<div class="flex h-full w-full flex-col" role="main">
  {#await getExperienceById(Number(id))}
    <div
      class="flex h-full w-full grow flex-col items-center justify-center"
      role="status"
      aria-label={loadingText}
    >
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:then experience}
    <header
      class="sticky top-0 z-10 flex h-16 w-full flex-row items-center justify-between border-b border-light-grey bg-white px-6 shadow-sm"
    >
      <button
        class="hover:text-primary-dark flex flex-row items-center gap-2 py-4 transition-colors hover:cursor-pointer"
        on:click={() => navigate(-1)}
        aria-label={backButtonLabel}
      >
        <span aria-hidden="true">
          <ChevronIcon style="rotate-180 fill-primary" />
        </span>
        <h1 class="mt-1 text-2xl font-bold text-primary">{experience.name}</h1>
      </button>

      {#if !isUser}
        <div>
          <Button
            testId="start-session-button"
            rounded="rounded-md"
            on:click={() => handleStartSession(experience)}
          >
            <span class="flex items-center gap-2">
              <span aria-hidden="true">
                <PlayIcon style="w-4 h-4 fill-white" />
              </span>
              {$t('dashboard.experiences.start-session-section-button')}
            </span>
          </Button>
        </div>
      {/if}
    </header>

    <main
      class="flex flex-col gap-4 bg-gray-50 p-4"
      role="region"
      aria-label={mainContentLabel}
    >
      <section
        class="rounded-lg border border-light-grey bg-white p-4 shadow-sm"
      >
        <ExperienceDetails
          experience={experience}
          isMonthlyExperience={monthlyExperience?.id === experience.id}
        />
      </section>

      {#await getExperienceFeedback( Number(id), { limit: 100, offset: 0, minRating: 0, onlyVisible: true }, )}
        <div
          class="flex justify-center py-6"
          role="status"
          aria-label={$t('dashboard.experiences.loading-feedback')}
        >
          <Loading loadingSize="h-20 w-20" />
        </div>
      {:then { sessionUsers }}
        <section
          class="rounded-lg border border-light-grey bg-white p-4 shadow-sm"
        >
          <UserFeedbackSection sessionUsers={sessionUsers} />
        </section>
      {/await}
    </main>
  {/await}
</div>
