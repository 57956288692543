<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import Button from '@/lib/components/button/Button.svelte';
  import { SessionStateFilter } from '@/lib/components/filter-dropdowns';
  import { PlusIcon } from '@/lib/components/icons';
  import type { SearchByOption } from '@/lib/components/search-input/search-input.types';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import type { ISessionQuery } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  export let query: ISessionQuery;
  export let isSearchLoading = false;
  export let searchInputValue = '';

  const navigateToExperiences = () => {
    navigate('/dashboard/experiences');
  };

  const searchOptions: SearchByOption[] = [
    { value: 'name', label: $t('search.by-name') },
    { value: 'host', label: $t('search.by-host') },
    { value: 'experience', label: $t('search.by-experience') },
  ];
</script>

<div class="flex flex-row items-center justify-between">
  <div class="flex flex-row items-center gap-4">
    <SearchInput
      searchByOptions={searchOptions}
      isAsync
      isLoading={isSearchLoading}
      bind:value={searchInputValue}
      on:search
    />

    <SessionStateFilter bind:selectedStates={query.status} on:changeStates />
  </div>

  <div>
    <Button
      testId="start-session-button"
      on:click={navigateToExperiences}
      style="fill"
      textSize="md"
    >
      <PlusIcon />
      <span>{$t('dashboard.sessions.create-session')}</span>
    </Button>
  </div>
</div>
