<script lang="ts">
  import type { IPaginatedQuery } from '@/interface/IPaginatedQuery';
  import type { IUser, UserRole } from '@/interface/IUser';
  import type { IUserQuery } from '@/interface/IUserQuery';
  import type { UserRoleFilterEvent } from '@/lib/components/filter-dropdowns';
  import { openModal } from '@/lib/components/modal/modal.store';
  import InviteUsersModal from '@/lib/components/modal/modals/InviteUsersModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import type { SearchEvent } from '@/lib/components/search-input/search-input.types';
  import Table from '@/lib/components/tables/Table.svelte';
  import type {
    SortEvent,
    TableItem,
  } from '@/lib/components/tables/table.types';
  import { getUserActions } from '@/lib/components/tables/users/user-actions';
  import { getUserColumns } from '@/lib/components/tables/users/user-columns';
  import UsersTableTools from '@/lib/components/tables/users/UsersTableTools.svelte';
  import { ApiError } from '@/lib/services/axios/api-error';
  import { ToastNotification } from '@/lib/services/toast';
  import { getTenantUsers } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  let showInactive = false;
  let query: IUserQuery = {
    fullName: '',
    email: '',
    limit: 6,
    offset: 0,
    role: [] as UserRole[],
  };

  let users: IUser[] = [];
  let count = 0;
  let isTableLoading = false;
  let isSearchLoading = false;
  let hasActiveSearch = false;
  let searchInputValue = '';

  const asTableItem = (user: IUser): TableItem => user;

  const handleInviteUsers = () => {
    openModal(InviteUsersModal, {
      allowCloseClick: false,
    });
  };

  const handleShowInactiveChange = () => {
    showInactive = !showInactive;
    query.offset = 0;
  };

  const updateOffset = () => {
    if (
      users.length === 1 &&
      query?.offset &&
      query?.offset > 0 &&
      query?.limit
    ) {
      query.offset = query?.offset - query?.limit;
    }
  };

  async function fetchUsers() {
    isTableLoading = true;
    if (hasActiveSearch) {
      isSearchLoading = true;
    }
    try {
      const response = await getTenantUsers(query);
      users = response.users;
      count = response.count;
    } catch (error: unknown) {
      console.error('Error fetching users:', error);
      if (error instanceof ApiError) {
        ToastNotification.error({
          title: $t('dashboard.users.fetch-users-error'),
          message: error.details || error.message,
        });
      } else {
        ToastNotification.error({
          title: $t('dashboard.users.fetch-users-error'),
          message: 'Contact support for more information',
        });
      }
      users = [];
      count = 0;
    } finally {
      isTableLoading = false;
      isSearchLoading = false;
    }
  }

  const handleClearSearch = () => {
    query = {
      ...query,
      fullName: '',
      email: '',
      offset: 0,
    };
    searchInputValue = '';
    hasActiveSearch = false;
  };

  const handleSearch = (event: CustomEvent<SearchEvent>) => {
    const { searchBy, searchQuery } = event.detail;
    isSearchLoading = true;
    hasActiveSearch = !!searchQuery.trim();

    if (!searchQuery.trim()) {
      handleClearSearch();
      return;
    }

    query.offset = 0;
    query.fullName = '';
    query.email = '';

    let words: string[];
    switch (searchBy) {
      case 'name':
        words = searchQuery.split(' ');
        query.fullName = words[0];
        break;
      case 'email':
        query.email = searchQuery;
        break;
      default:
        break;
    }
  };

  const handleSort = (event: CustomEvent<SortEvent>) => {
    const { column, order } = event.detail;
    query = { ...query, sortBy: column, sortOrder: order };
  };

  const handleRoleChange = (event: CustomEvent<UserRoleFilterEvent>) => {
    const { roles } = event.detail;
    query = { ...query, role: roles, offset: 0 };
  };

  const handlePageChange = (newQuery: IPaginatedQuery) => {
    query = { ...query, offset: newQuery.offset, limit: newQuery.limit };
  };

  $: columns = getUserColumns();
  $: actions = getUserActions(fetchUsers, updateOffset);

  $: {
    query.isActive = showInactive ? undefined : true;
    query.offset = 0;
  }

  $: query, fetchUsers();
</script>

<div class="flex h-full flex-col gap-4">
  <UsersTableTools
    query={query}
    isSearchLoading={isSearchLoading}
    searchInputValue={searchInputValue}
    onShowInactiveChange={handleShowInactiveChange}
    onInviteUsers={handleInviteUsers}
    bind:showInactive={showInactive}
    on:search={handleSearch}
    on:changeRoles={handleRoleChange}
  />

  <div
    class="flex h-full min-h-[500px] flex-col"
    data-tour="dashboard-users-table"
  >
    <Table
      columns={columns}
      data={users.map(asTableItem)}
      actions={actions}
      isLoading={isTableLoading}
      emptyMessage={$t('dashboard.users.table-no-users')}
      loadingMessage={$t('dashboard.users.searching')}
      sortBy={query.sortBy}
      sortOrder={query.sortOrder}
      on:sort={handleSort}
    />

    {#if !isTableLoading && users.length > 0}
      <div class="mt-auto border-t px-4 py-2">
        <Paginator total={count} query={query} onChange={handlePageChange} />
      </div>
    {/if}
  </div>
</div>
