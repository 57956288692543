<script lang="ts">
  import type { ISession } from '@/interface/ISession';
  import { UsersIcon } from '@/lib/components/icons';
  import { t } from '@/locales/i18n';

  import SessionUserCard from '../../cards/SessionUserCard.svelte';
  import { closeModal } from '../modal.store';

  export let session: ISession;

  $: hasUsers = session.users && session.users.length > 0;
  $: gridCols = hasUsers
    ? session.users.length > 3
      ? '3'
      : `${session.users.length}`
    : '1';
</script>

<div
  class="flex max-h-[80vh] min-w-[700px] flex-col gap-4 rounded-lg bg-white p-6"
>
  <div class="flex items-center justify-between border-b border-gray-200 pb-4">
    <h2 class="text-xl font-bold text-gray-800">
      {$t('dashboard.sessions.modal-participants-title', {
        session: session.name,
      })}
    </h2>
    <span class="text-sm text-gray-500">
      {hasUsers ? session.users.length : 0}
      {$t('dashboard.sessions.modal-participants-count')}
    </span>
  </div>

  {#if hasUsers}
    <div
      on:keypress
      on:click={() => closeModal()}
      class="session-users-modal grid gap-4 overflow-auto rounded-lg p-1"
      style={`--grid-cols: ${gridCols};`}
    >
      {#each session.users as sessionUser}
        <div on:keypress on:click|stopPropagation>
          <SessionUserCard sessionUser={sessionUser} />
        </div>
      {/each}
    </div>
  {:else}
    <div
      class="flex h-48 flex-col items-center justify-center rounded-lg bg-gray-50 p-6 text-center"
    >
      <div
        class="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gray-200"
      >
        <UsersIcon class_="h-8 w-8 text-gray-400" />
      </div>
      <p class="text-lg font-medium text-gray-600">
        {$t('dashboard.sessions.modal-no-participants')}
      </p>
      <p class="mt-2 text-sm text-gray-500">
        {$t('dashboard.sessions.modal-invite-participants')}
      </p>
    </div>
  {/if}

  <div class="mt-auto border-t border-gray-200 pt-4">
    <button
      class="hover:bg-primary-dark w-full rounded-md bg-primary px-4 py-2 text-white transition-colors"
      on:click={() => closeModal()}
    >
      {$t('common.close')}
    </button>
  </div>
</div>

<style>
  .session-users-modal {
    grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
    max-height: calc(80vh - 150px);
  }
</style>
