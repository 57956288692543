<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>Together - {$t('support.route-title')}</title>
</svelte:head>

<Page>
  <div class="flex justify-center">
    <h1 class="text-3xl font-bold" data-cy="support-page-title">Support</h1>
  </div>
  <div class="mt-4 flex items-center justify-center">
    <div
      class="mx-auto max-w-4xl rounded-md border-gray-500 bg-white p-8 shadow-md"
    >
      <p class="mb-4 text-justify text-lg">
        {@html $t('support.text-1')}
      </p>
      <p class="text-justify text-lg">
        {@html $t('support.text-2')}
      </p>
    </div>
  </div>
</Page>
