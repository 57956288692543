<script lang="ts">
  import type { IPaginatedQuery } from '@/interface/IPaginatedQuery';
  import type { ISession, SessionStatus } from '@/interface/ISession';
  import type { SessionStateFilterEvent } from '@/lib/components/filter-dropdowns';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import type { SearchEvent } from '@/lib/components/search-input/search-input.types';
  import { sessionCountStore } from '@/lib/components/sidebar/session.count.store';
  import { getSessionActions } from '@/lib/components/tables/sessions/session-actions';
  import { getSessionColumns } from '@/lib/components/tables/sessions/session-columns';
  import SessionsTableTools from '@/lib/components/tables/sessions/SessionsTableTools.svelte';
  import Table from '@/lib/components/tables/Table.svelte';
  import type {
    SortEvent,
    TableItem,
  } from '@/lib/components/tables/table.types';
  import { ApiError } from '@/lib/services/axios/api-error';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    getSessions,
    type ISessionQuery,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  let query: ISessionQuery = {
    limit: 6,
    offset: 0,
    name: '',
    hostName: '',
    experienceName: '',
    scheduledDate: '',
    status: [] as SessionStatus[],
  };

  let sessions: ISession[] = [];
  let count = 0;
  let isTableLoading = false;
  let isSearchLoading = false;
  let searchInputValue = '';
  let hasActiveSearch = false;

  const asTableItem = (session: ISession): TableItem => session;

  async function fetchSessions() {
    isTableLoading = true;
    if (hasActiveSearch) {
      isSearchLoading = true;
    }
    try {
      const { sessions: newSessions, count: newCount } =
        await getSessions(query);
      $sessionCountStore = newCount;
      sessions = newSessions;
      count = newCount;
    } catch (error: unknown) {
      console.error('Error fetching sessions:', error);
      if (error instanceof ApiError) {
        ToastNotification.error({
          title: $t('dashboard.sessions.fetch-sessions-error'),
          message: error.details || error.message,
        });
      } else {
        ToastNotification.error({
          title: $t('dashboard.sessions.fetch-sessions-error'),
          message: 'Contact support for more information',
        });
      }
      sessions = [];
      count = 0;
    } finally {
      isTableLoading = false;
      isSearchLoading = false;
    }
  }

  const handleClearSearch = () => {
    query = {
      ...query,
      name: '',
      hostName: '',
      experienceName: '',
      offset: 0,
    };
    searchInputValue = '';
    hasActiveSearch = false;
  };

  const handleSearch = (event: CustomEvent<SearchEvent>) => {
    const { searchBy, searchQuery } = event.detail;
    isSearchLoading = true;
    hasActiveSearch = !!searchQuery.trim();

    if (!searchQuery.trim()) {
      handleClearSearch();
      return;
    }

    query.offset = 0;
    query.hostName = '';
    query.name = '';
    query.experienceName = '';

    switch (searchBy) {
      case 'host':
        query.hostName = searchQuery;
        break;
      case 'name':
        query.name = searchQuery;
        break;
      case 'experience':
        query.experienceName = searchQuery;
        break;
      default:
        break;
    }
  };

  const handleStateChange = (event: CustomEvent<SessionStateFilterEvent>) => {
    const { states } = event.detail;
    query = { ...query, status: states, offset: 0 };
  };

  const handleSort = (event: CustomEvent<SortEvent>) => {
    const { column, order } = event.detail;
    query = { ...query, sortBy: column, sortOrder: order, offset: 0 };
  };

  const handlePageChange = (newQuery: IPaginatedQuery) => {
    query = { ...query, offset: newQuery.offset, limit: newQuery.limit };
  };

  $: columns = getSessionColumns();
  $: actions = getSessionActions(fetchSessions);
  $: query, fetchSessions();
</script>

<div class="flex h-full flex-col gap-4">
  <SessionsTableTools
    query={query}
    isSearchLoading={isSearchLoading}
    searchInputValue={searchInputValue}
    on:search={handleSearch}
    on:changeStates={handleStateChange}
  />

  <div class="flex h-full min-h-[500px] flex-col">
    <Table
      columns={columns}
      data={sessions.map(asTableItem)}
      actions={actions}
      isLoading={isTableLoading}
      emptyMessage={$t('dashboard.sessions.no-sessions')}
      loadingMessage={$t('dashboard.sessions.loading')}
      sortBy={query.sortBy}
      sortOrder={query.sortOrder}
      on:sort={handleSort}
    />

    {#if !isTableLoading && sessions.length > 0}
      <div class="mt-auto border-t px-4 py-2">
        <Paginator total={count} query={query} onChange={handlePageChange} />
      </div>
    {/if}
  </div>
</div>
