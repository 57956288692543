<script lang="ts">
  import SlackIntegrationEn1 from '@/assets/img/integrations/slack-integration-en-1.png';
  import SlackIntegrationEn2 from '@/assets/img/integrations/slack-integration-en-2.png';
  import SlackIntegrationEn3 from '@/assets/img/integrations/slack-integration-en-3.png';
  import SlackIntegrationEn4 from '@/assets/img/integrations/slack-integration-en-4.png';
  import SlackIntegrationEn5 from '@/assets/img/integrations/slack-integration-en-5.png';
  import SlackIntegrationEn6 from '@/assets/img/integrations/slack-integration-en-6.png';
  import SlackIntegrationEn7 from '@/assets/img/integrations/slack-integration-en-7.png';
  import SlackIntegrationEn8 from '@/assets/img/integrations/slack-integration-en-8.png';
  import SlackIntegrationEs1 from '@/assets/img/integrations/slack-integration-es-1.png';
  import SlackIntegrationEs2 from '@/assets/img/integrations/slack-integration-es-2.png';
  import SlackIntegrationEs3 from '@/assets/img/integrations/slack-integration-es-3.png';
  import SlackIntegrationEs4 from '@/assets/img/integrations/slack-integration-es-4.png';
  import SlackIntegrationEs6 from '@/assets/img/integrations/slack-integration-es-6.png';
  import SlackIntegrationEs7 from '@/assets/img/integrations/slack-integration-es-7.png';
  import SlackIntegrationEs8 from '@/assets/img/integrations/slack-integration-es-8.png';
  import SlackLogoExtended from '@/assets/svg/SlackLogoExtend.svg';
  import Page from '@/lib/components/page/Page.svelte';
  import { locale, t } from '@/locales/i18n';

  $: slackIntegration1 =
    $locale === 'en' ? SlackIntegrationEn1 : SlackIntegrationEs1;
  $: slackIntegration2 =
    $locale === 'en' ? SlackIntegrationEn2 : SlackIntegrationEs2;
  $: slackIntegration3 =
    $locale === 'en' ? SlackIntegrationEn3 : SlackIntegrationEs3;
  $: slackIntegration4 =
    $locale === 'en' ? SlackIntegrationEn4 : SlackIntegrationEs4;
  $: slackIntegration5 = SlackIntegrationEn5;
  $: slackIntegration6 =
    $locale === 'en' ? SlackIntegrationEn6 : SlackIntegrationEs6;
  $: slackIntegration7 =
    $locale === 'en' ? SlackIntegrationEn7 : SlackIntegrationEs7;
  $: slackIntegration8 =
    $locale === 'en' ? SlackIntegrationEn8 : SlackIntegrationEs8;
</script>

<svelte:head>
  <title>Together - {$t('integrations.route-title')}</title>
</svelte:head>

<Page>
  <div class=" m-1 flex w-full grow items-center justify-center">
    <div
      class="z-10 mx-8 my-12 flex h-fit flex-col gap-4 text-center md:mx-32 xl:mx-60"
    >
      <div class="mb-4 flex flex-row items-center justify-center">
        <span class="me-3 text-4xl font-semibold"
          >{@html $t('integrations.title')}</span
        >
        <img src={SlackLogoExtended} alt="Slack logo" class="w-28" />
      </div>
      <div
        class="flex w-full flex-col items-start justify-start gap-6 rounded-lg px-14"
      >
        <ul class="w-full" data-cy="integrations-info-list">
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-1')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-2')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            {@html $t('integrations.claim-text-3')}
          </li>
          <li class="mb-4 text-start text-2xl text-dark-grey">
            <img
              src={slackIntegration1}
              alt="Slack integration 1"
              class=" w-3/4"
            />
          </li>
        </ul>
        <h2 class="mt-6 text-3xl font-semibold">
          {@html $t('integrations.instructions-title')}
        </h2>
        <ul class="mb-1 list-decimal pl-4 text-start text-lg">
          <li class="mt-2">
            <p>
              {@html $t('integrations.instructions-text-1')}
            </p>
            <div class="mt-2 flex flex-row gap-4">
              <img src={slackIntegration2} alt="Slack integration 2" />
              <img src={slackIntegration3} alt="Slack integration 3" />
            </div>
          </li>
          <li class="mt-6">
            <p>
              {@html $t('integrations.instructions-text-2')}
            </p>
            <img
              src={slackIntegration4}
              alt="Slack integration 4"
              class="mt-2"
            />
          </li>
          <li class="mt-6">
            <p>
              {@html $t('integrations.instructions-text-3')}
            </p>
            <img
              src={slackIntegration5}
              alt="Slack integration 5"
              class="mt-2 w-1/2"
            />
          </li>
          <li class="mt-6">
            <p>
              {@html $t('integrations.instructions-text-4')}
            </p>
            <img
              src={slackIntegration6}
              alt="Slack integration 6"
              class="mt-2"
            />
          </li>
          <li class="mt-6">
            <p>
              {@html $t('integrations.instructions-text-5')}
            </p>
            <img
              src={slackIntegration7}
              alt="Slack integration 7"
              class="mt-2 w-1/2"
            />
          </li>
        </ul>
        <div class="m-1 flex flex-col">
          <h3 class="mb-4 mt-6 text-start text-2xl font-semibold">
            {@html $t('integrations.instructions-unlink-title')}
          </h3>
          <p class="text-start">
            {@html $t('integrations.instructions-unlink-text-1')}
          </p>
          <img
            src={slackIntegration8}
            alt="Slack integration 8"
            class="mt-2 w-1/2"
          />
        </div>
        <div class="m-1 flex flex-col items-start justify-start">
          <h3 class="mb-4 mt-2 text-2xl font-semibold">
            {$t('integrations.privacy-title')}
          </h3>
          <p class="text-start">
            {@html $t('integrations.privacy-text-1')}
          </p>
        </div>
        <div class="m-1 flex flex-col items-start justify-start">
          <h3 class="mb-4 mt-2 text-2xl font-semibold">
            {@html $t('integrations.support-title')}
          </h3>
          <p class="text-start">
            {@html $t('integrations.support-text-1')}
          </p>
        </div>
      </div>
    </div>
  </div>
</Page>
