import { get } from 'svelte/store';
import type { IUser } from '@/interface/IUser';
import { TrashIcon } from '@/lib/components/icons';
import { openModal } from '@/lib/components/modal/modal.store';
import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
import type { Action, TableItem } from '@/lib/components/tables/table.types';
import { ToastNotification } from '@/lib/services/toast';
import { removeTeamMember } from '@/lib/services/together/together.api';
import { t } from '@/locales/i18n';

const teamMemberActionsKeys = {
  removeMember: 'remove-member',
};

export const handleRemoveMember = async (
  teamId: number,
  user: IUser,
  onSuccess: () => void,
) => {
  const translate = get(t);

  const confirmRemoveMember = async (user: IUser) => {
    try {
      await removeTeamMember(teamId, user.id);
      onSuccess();
      ToastNotification.success(
        translate('dashboard.teams.view.remove-member-modal-success'),
      );
    } catch (error) {
      ToastNotification.error(
        translate('dashboard.teams.view.remove-member-modal-error'),
      );
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      title: translate('dashboard.teams.view.remove-member-modal-title'),
      text:
        translate('dashboard.teams.view.remove-member-modal-text') +
        ' ' +
        user.name +
        ' ' +
        user.lastName +
        '?',
      onSubmit: () => confirmRemoveMember(user),
    },
  });
};

export function getTeamMemberActions(
  teamId: number,
  onSuccess: () => void,
): Action<TableItem>[] {
  const translate = get(t);
  return [
    {
      name: teamMemberActionsKeys.removeMember,
      infoText: translate('dashboard.teams.view.table-action-remove-tooltip'),
      onAction: (item: TableItem) => {
        handleRemoveMember(teamId, item as IUser, onSuccess);
      },
      icon: TrashIcon,
      disabledCondition: () => false,
      hideOnDisabled: false,
    },
  ];
}
