<script lang="ts">
  import { onMount, type ComponentProps } from 'svelte';
  import { navigate, useLocation } from 'svelte-navigator';
  import { get } from 'svelte/store';
  import Nut from '@/assets/svg/NutIcon.svelte';
  import type NutIcon from '@/assets/svg/NutIcon.svelte';
  import TogetherLogo from '@/assets/svg/TogetherLogo.svelte';
  import { SubscriptionPlan, type IExperience } from '@/interface/IExperience';
  import type { ISessionSchedule } from '@/interface/ISessionSchedule';
  import { UserRole } from '@/interface/IUser';
  import Button from '@/lib/components/button/Button.svelte';
  import SidebarMenuButton from '@/lib/components/buttons-group/buttons/SidebarMenuButton.svelte';
  import WelcomeUserCard from '@/lib/components/cards/WelcomeUserCard.svelte';
  import ExperienceIcon from '@/lib/components/icons/ExperienceIcon.svelte';
  import LogoutIcon from '@/lib/components/icons/LogoutIcon.svelte';
  import PersonIcon from '@/lib/components/icons/PersonIcon.svelte';
  import UsersIcon from '@/lib/components/icons/UsersIcon.svelte';
  import { sessionCountStore } from '@/lib/components/sidebar/session.count.store';
  import TenantSelector from '@/lib/components/tenant-selector/TenantSelector.svelte';
  import {
    getSessions,
    getSessionSchedule,
  } from '@/lib/services/together/together.api';
  import { logout } from '@/lib/services/together/user.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  $: isHost = $userStore?.role === UserRole.HOST;
  $: isAdmin =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;
  $: isUser = $userStore?.role === UserRole.USER;

  const location = useLocation();
  const handleLogout = async (): Promise<void> => {
    await logout();
    navigate('/');
  };

  $: dashboardButtons = [
    {
      name: 'users',
      text: $t('dashboard.sidebar.users'),
      icon: PersonIcon,
      url: '/dashboard/users',
      selected: isAdmin,
      ariaLabel: $t('dashboard.sidebar.users-aria'),
    },
    {
      name: 'teams',
      text: $t('dashboard.sidebar.teams'),
      icon: UsersIcon,
      url: '/dashboard/teams',
      ariaLabel: $t('dashboard.sidebar.teams-aria'),
    },
    {
      name: 'experiences',
      text: $t('dashboard.sidebar.experiences'),
      icon: ExperienceIcon,
      url: '/dashboard/experiences',
      selected: isHost || isUser,
      ariaLabel: $t('dashboard.sidebar.experiences-aria'),
    },
    {
      name: 'sessions',
      text: $t('dashboard.sidebar.sessions'),
      icon: UsersIcon,
      url: '/dashboard/sessions',
      ariaLabel: $t('dashboard.sidebar.sessions-aria'),
    },
  ];

  let bottomSidebarButtons: (ComponentProps<SidebarMenuButton> & {
    icon: typeof NutIcon | typeof LogoutIcon;
    name: string;
    url: string;
    ariaLabel?: string;
    onClick?: () => Promise<void> | void;
  })[] = [
    {
      name: 'settings',
      text: $t('dashboard.sidebar.settings'),
      icon: Nut,
      url: '/dashboard/settings',
      ariaLabel: $t('dashboard.sidebar.settings-aria'),
    },
    {
      name: 'logout',
      text: $t('dashboard.sidebar.logout'),
      icon: LogoutIcon,
      url: '#',
      ariaLabel: $t('dashboard.sidebar.logout-aria'),
      onClick: handleLogout,
    },
  ];

  $: {
    if (isUser) {
      dashboardButtons = dashboardButtons.filter(
        (button) => button.name === 'experiences' || button.name === 'sessions',
      );
    } else if (isHost) {
      dashboardButtons = dashboardButtons.filter(
        (button) =>
          button.name === 'teams' ||
          button.name === 'experiences' ||
          button.name === 'sessions',
      );
    }
  }

  $: $locale,
    (dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      text: $t(`dashboard.sidebar.${button.name}`),
      ariaLabel: $t(`dashboard.sidebar.${button.name}-aria`),
    }))),
    (bottomSidebarButtons = bottomSidebarButtons.map((button) => ({
      ...button,
      text: $t(`dashboard.sidebar.${button.name}`),
      ariaLabel: $t(`dashboard.sidebar.${button.name}-aria`),
    })));

  $: $location,
    ((bottomSidebarButtons = bottomSidebarButtons.map((button) => ({
      ...button,
      selected: get(location).pathname.includes(button.url),
    }))),
    (dashboardButtons = dashboardButtons.map((button) => ({
      ...button,
      selected: get(location).pathname.includes(button.url),
    }))));

  const handleNavigation = (url: string) => {
    navigate(url);
  };

  let currentAmountOfSessions = 0;
  let trialExperience: IExperience;

  $: {
    currentAmountOfSessions = $sessionCountStore;
  }

  let freeTrialSchedule: ISessionSchedule | undefined;

  onMount(async () => {
    const { count } = await getSessions();
    $sessionCountStore = count;
    if (count > 3) {
      currentAmountOfSessions = $sessionCountStore;
    } else {
      freeTrialSchedule = await getSessionSchedule('Free Trial');
      currentAmountOfSessions = count;
    }
  });

  $: {
    if (freeTrialSchedule) {
      trialExperience =
        $locale === 'en'
          ? freeTrialSchedule.englishExperience
          : freeTrialSchedule.spanishExperience;
    }
  }

  const handleTrialExperience = () => {
    navigate(`/dashboard/experiences/${trialExperience.id}`);
  };

  const handleKeyNav = (
    event: KeyboardEvent,
    index: number,
    buttonArray: any[],
    type: 'main' | 'bottom',
  ) => {
    const len = buttonArray.length;
    let nextIndex;

    switch (event.key) {
      case 'ArrowDown':
        nextIndex = (index + 1) % len;
        (
          document.querySelector(
            `[data-${type}-index="${nextIndex}"]`,
          ) as HTMLElement
        )?.focus();
        event.preventDefault();
        break;
      case 'ArrowUp':
        nextIndex = (index - 1 + len) % len;
        (
          document.querySelector(
            `[data-${type}-index="${nextIndex}"]`,
          ) as HTMLElement
        )?.focus();
        event.preventDefault();
        break;
    }
  };
</script>

<div
  class="z-30 flex w-80 flex-col justify-between border-r border-light-grey bg-gray-50 py-4 shadow-sm transition-all duration-200"
  role="navigation"
  aria-label={$t('dashboard.sidebar.navigation')}
>
  <div class="flex w-full flex-col items-center justify-center gap-8 pt-4">
    <a
      href="/dashboard"
      class="rounded-md px-6 transition-transform duration-200 hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
      aria-label={$t('dashboard.sidebar.home')}
    >
      <TogetherLogo className="h-16" />
    </a>
    <WelcomeUserCard />

    <nav
      class="flex w-full flex-col items-start justify-center"
      aria-label={$t('dashboard.sidebar.main-menu')}
    >
      <h2 id="main-menu-heading" class="sr-only">
        {$t('dashboard.sidebar.main-menu')}
      </h2>

      <div class="w-full px-2" role="menu" aria-labelledby="main-menu-heading">
        {#each dashboardButtons as button, index}
          <div
            role="menuitem"
            data-main-index={index}
            tabindex={button.selected ? 0 : -1}
            on:keydown={(e) => handleKeyNav(e, index, dashboardButtons, 'main')}
          >
            <SidebarMenuButton
              name={button.name}
              text={button.text}
              selected={button.selected}
              on:click={() => handleNavigation(button.url)}
              ariaLabel={button.ariaLabel}
              ariaCurrent={button.selected ? 'page' : undefined}
            >
              <svelte:component this={button.icon} />
            </SidebarMenuButton>
          </div>
        {/each}
      </div>

      {#if (isAdmin || isHost) && $userStore?.tenant.subscriptionPlan === SubscriptionPlan.FREE && trialExperience}
        <section
          class="mx-2 my-4 flex w-[calc(100%-1rem)] flex-col items-center justify-center gap-2 rounded-md bg-white p-4 shadow-sm transition-all duration-200 hover:shadow-md"
          aria-labelledby="trial-section-heading"
        >
          <h3 id="trial-section-heading" class="sr-only">
            {$t('dashboard.sidebar.trial-section')}
          </h3>
          <Button
            disabled={currentAmountOfSessions === 3}
            on:click={handleTrialExperience}
            testId="use-free-trial-session"
            ><span>{$t('use-trial-session-button')}</span></Button
          >
          <div class="flex items-center gap-2" aria-live="polite">
            <span class="text-xl font-bold text-grey"
              >{currentAmountOfSessions}/3</span
            >
            <span class="text-xs text-gray-500"
              >{$t('dashboard.sidebar.sessions-left')}</span
            >
          </div>
        </section>
      {/if}
    </nav>
  </div>

  <div class="mt-auto flex w-full flex-col gap-4">
    {#if $userStore?.role === UserRole.SUPER_ADMIN}
      <div class="px-2">
        <TenantSelector />
      </div>
    {/if}

    <nav
      class="flex w-full flex-col items-start justify-center"
      aria-label={$t('dashboard.sidebar.secondary-menu')}
    >
      <h2 id="secondary-menu-heading" class="sr-only">
        {$t('dashboard.sidebar.secondary-menu')}
      </h2>

      <div
        class="w-full px-2"
        role="menu"
        aria-labelledby="secondary-menu-heading"
      >
        {#each bottomSidebarButtons as button, index}
          <div
            role="menuitem"
            data-bottom-index={index}
            tabindex={button.selected ? 0 : -1}
            on:keydown={(e) =>
              handleKeyNav(e, index, bottomSidebarButtons, 'bottom')}
          >
            <SidebarMenuButton
              name={button.name}
              text={button.text}
              selected={button.selected}
              on:click={() =>
                button.onClick
                  ? button.onClick()
                  : handleNavigation(button.url)}
              ariaLabel={button.ariaLabel}
              ariaCurrent={button.selected ? 'page' : undefined}
            >
              <svelte:component this={button.icon} />
            </SidebarMenuButton>
          </div>
        {/each}
      </div>
    </nav>
  </div>
</div>
