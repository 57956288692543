type KeyboardHandler = {
  onEscape?: () => void;
  onEnter?: () => void;
  onSpace?: () => void;
};

export function handleKeyboardControls(
  event: KeyboardEvent,
  handlers: KeyboardHandler,
) {
  switch (event.key) {
    case 'Escape':
      handlers.onEscape?.();
      break;
    case 'Enter':
      event.preventDefault();
      handlers.onEnter?.();
      break;
    case ' ':
      handlers.onSpace?.();
      event.preventDefault();
      break;
  }
}
