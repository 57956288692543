import { get } from 'svelte/store';
import { SessionStatus, type ISession } from '@/interface/ISession';
import { UserRole } from '@/interface/IUser';
import {
  CalendarIcon,
  CheckIcon,
  ClipboardIcon,
  FeedbackIcon,
  PencilIcon,
  TrashIcon,
  UserGroupIcon,
} from '@/lib/components/icons';
import { openModal } from '@/lib/components/modal/modal.store';
import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
import SessionUsersModal from '@/lib/components/modal/modals/SessionUsersModal.svelte';
import UpdateSessionModal from '@/lib/components/modal/modals/UpdateSessionModal.svelte';
import type { Action, TableItem } from '@/lib/components/tables/table.types';
import { ApiError } from '@/lib/services/axios/api-error';
import { ToastNotification } from '@/lib/services/toast';
import {
  cancelSession,
  finishSession,
} from '@/lib/services/together/together.api';
import { t } from '@/locales/i18n';
import { userStore } from '@/store/userStore';

const ActionsNames = {
  FINISH_SESSION: 'finish-session',
  EDIT_SESSION: 'edit-session',
  CANCEL_SESSION: 'cancel-session',
  COPY_FEEDBACK_LINK: 'copy-feedback-link',
  SEND_FEEDBACK_LINK: 'send-feedback-link',
  OPEN_BOARD: 'open-board',
  SHOW_PARTICIPANTS: 'show-participants',
};

export const isFinishedAndHasUnratedUsers = (session: ISession) => {
  const user = get(userStore);
  return (
    session.status === SessionStatus.FINISHED &&
    user?.role !== UserRole.USER &&
    session.users.some(
      (sessionUser) =>
        sessionUser.feedbackRating === 0 || sessionUser.feedbackRating === null,
    )
  );
};

export const isUserAndHasUnratedFeedback = (session: ISession) => {
  const user = get(userStore);
  return (
    session.status === SessionStatus.FINISHED &&
    user?.role === UserRole.USER &&
    session.users.some(
      (sessionUser) =>
        sessionUser.user.id === user?.id && !sessionUser.feedbackRating,
    )
  );
};

export const canModifyCheck = (session: ISession) => {
  const user = get(userStore);
  const isHost = session.host.id === user?.id;
  const isAdmin =
    user?.role === UserRole.ADMIN || user?.role === UserRole.SUPER_ADMIN;
  const sessionCanBeModified = session.status === SessionStatus.PENDING;
  return (isHost || isAdmin) && sessionCanBeModified;
};

export const handleCopyFeedbackLink = async (session: ISession) => {
  const translate = get(t);
  try {
    await navigator.clipboard.writeText(
      import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
    );
    ToastNotification.success(
      translate('dashboard.sessions.feedback-copy-success'),
    );
  } catch (error: unknown) {
    if (error instanceof ApiError) {
      ToastNotification.error({
        title: translate('dashboard.sessions.feedback-copy-error'),
        message: error.details ?? error.message,
      });
    } else {
      ToastNotification.error({
        title: translate('dashboard.sessions.feedback-copy-error'),
        message: 'Contact support for more information',
      });
    }
  }
};

export const handleOpenFeedbackPage = (session: ISession) => {
  window.open(
    import.meta.env.VITE_BASE_URL + `/session/${session.id}/feedback`,
    '_blank',
  );
};

export const handleShowSessionUsers = (session: ISession) => {
  openModal(SessionUsersModal, { props: { session } });
};

export const handleOpenBoard = (session: ISession) => {
  window.open(session.boardUrl, '_blank');
};

export const handleEditSessionModal = (
  session: ISession,
  onSubmit?: () => void,
) => {
  openModal(UpdateSessionModal, {
    allowCloseClick: false,
    props: {
      session,
      onSubmit: () => onSubmit?.(),
    },
  });
};
export const handleFinishSession = async (
  session: ISession,
  onSubmit?: () => void,
) => {
  const finishCurrentSession = async () => {
    try {
      await finishSession(session.id);
      ToastNotification.success(get(t)('dashboard.sessions.finish-success'));
      onSubmit?.();
    } catch (error) {
      ToastNotification.error(get(t)('dashboard.sessions.finish-error'));
      console.error(error);
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      text: get(t)('dashboard.sessions.finish-title'),
      onSubmit: () => finishCurrentSession(),
    },
  });
};

export const handleCancelSession = async (
  session: ISession,
  onSubmit?: () => void,
) => {
  const cancelCurrentSession = async () => {
    try {
      await cancelSession(session.id);
      ToastNotification.success(get(t)('dashboard.sessions.cancel-success'));
      onSubmit?.();
    } catch (error) {
      ToastNotification.error(get(t)('dashboard.sessions.cancel-error'));
      console.error(error);
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      text: get(t)('dashboard.sessions.cancel-title'),
      onSubmit: () => cancelCurrentSession(),
    },
  });
};

export const getSessionActions = (
  fetchSessions: () => Promise<void>,
): Action<TableItem>[] => {
  const translate = get(t);
  const actions: Action<TableItem>[] = [];
  const user = get(userStore);
  const isUser = user?.role === UserRole.USER;

  if (!isUser) {
    actions.push({
      name: ActionsNames.FINISH_SESSION,
      infoText: translate('dashboard.sessions.table-action-finish'),
      onAction: (item: TableItem) => {
        handleFinishSession(item as ISession, fetchSessions);
      },
      disabledCondition: (item: TableItem) => !canModifyCheck(item as ISession),
      hideOnDisabled: true,
      icon: CheckIcon,
    });

    actions.push({
      name: ActionsNames.EDIT_SESSION,
      infoText: translate('dashboard.teams.view.session-table-action-edit'),
      onAction: (item: TableItem) => {
        handleEditSessionModal(item as ISession, fetchSessions);
      },
      disabledCondition: (item: TableItem) => !canModifyCheck(item as ISession),
      hideOnDisabled: true,
      icon: PencilIcon,
    });

    actions.push({
      name: ActionsNames.CANCEL_SESSION,
      infoText: translate('dashboard.teams.view.session-table-action-cancel'),
      onAction: (item: TableItem) => {
        handleCancelSession(item as ISession, fetchSessions);
      },
      disabledCondition: (item: TableItem) => !canModifyCheck(item as ISession),
      hideOnDisabled: true,
      icon: TrashIcon,
    });
  }

  actions.push({
    name: ActionsNames.COPY_FEEDBACK_LINK,
    infoText: translate('dashboard.sessions.table-action-copy-feedback'),
    onAction: (item: TableItem) => {
      handleCopyFeedbackLink(item as ISession);
    },
    disabledCondition: (item: TableItem) =>
      !isFinishedAndHasUnratedUsers(item as ISession),
    hideOnDisabled: true,
    icon: ClipboardIcon,
  });

  actions.push({
    name: ActionsNames.SEND_FEEDBACK_LINK,
    infoText: translate('dashboard.sessions.table-action-send-feedback'),
    onAction: (item: TableItem) => handleOpenFeedbackPage(item as ISession),
    disabledCondition: (item: TableItem) =>
      !isUserAndHasUnratedFeedback(item as ISession),
    hideOnDisabled: true,
    icon: FeedbackIcon,
  });

  actions.push({
    name: ActionsNames.OPEN_BOARD,
    infoText: translate('dashboard.sessions.table-action-board'),
    onAction: (item: TableItem) => handleOpenBoard(item as ISession),
    disabledCondition: (item: TableItem) =>
      (item as ISession).status === SessionStatus.CANCELED,
    hideOnDisabled: true,
    icon: CalendarIcon,
  });

  actions.push({
    name: ActionsNames.SHOW_PARTICIPANTS,
    infoText: translate('dashboard.sessions.table-participants'),
    onAction: (item: TableItem) => handleShowSessionUsers(item as ISession),
    hideOnDisabled: false,
    icon: UserGroupIcon,
  });

  return actions;
};
