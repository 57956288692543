import { navigate } from 'svelte-navigator';
import { get } from 'svelte/store';
import type { ITeam } from '@/interface/ITeam';
import { ManageIcon, TrashIcon } from '@/lib/components/icons';
import { openModal } from '@/lib/components/modal/modal.store';
import ConfirmationModal from '@/lib/components/modal/modals/ConfirmationModal.svelte';
import type { Action, TableItem } from '@/lib/components/tables/table.types';
import { ToastNotification } from '@/lib/services/toast';
import { deleteTeam } from '@/lib/services/together/together.api';
import { t } from '@/locales/i18n';

const ActionsNames = {
  MANAGE_TEAM: 'manage-team',
  DELETE_TEAM: 'delete-team',
};

export function getTeamActions(
  fetchTeams: () => void,
  updateOffset?: () => void,
): Action<TableItem>[] {
  const translate = get(t);
  return [
    {
      name: ActionsNames.MANAGE_TEAM,
      infoText: translate('dashboard.teams.table-actions-manage-tooltip'),
      onAction: (item: TableItem) => {
        const team = item as ITeam;
        navigate(`/dashboard/teams/${team.id}`);
      },
      icon: ManageIcon,
    },
    {
      name: ActionsNames.DELETE_TEAM,
      infoText: translate('dashboard.teams.table-actions-delete-tooltip'),
      onAction: (item: TableItem) => {
        const team = item as ITeam;
        handleDeleteTeam(team, fetchTeams, updateOffset);
      },
      icon: TrashIcon,
    },
  ];
}

async function handleDeleteTeam(
  team: ITeam,
  fetchTeams: () => void,
  updateOffset?: () => void,
) {
  const translate = get(t);
  const confirmDeleteTeam = async (team: ITeam) => {
    try {
      await deleteTeam(team.id);

      if (updateOffset) {
        updateOffset();
      }

      ToastNotification.success({
        title: translate('dashboard.teams.team-deleted'),
        message: translate('dashboard.teams.team-deleted-message'),
      });

      fetchTeams();
    } catch (error) {
      console.error(error);
      ToastNotification.error({
        title: translate('dashboard.teams.delete-team-error'),
        message: translate('dashboard.teams.delete-team-error-message'),
      });
    }
  };

  openModal(ConfirmationModal, {
    allowCloseClick: false,
    props: {
      title: translate('dashboard.teams.delete-team-modal-title'),
      text: translate('dashboard.teams.delete-team-modal-text', {
        values: { team: team.name },
      }),
      onSubmit: () => confirmDeleteTeam(team),
    },
  });
}
