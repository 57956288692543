<script lang="ts">
  import Compressor from 'compressorjs';
  import { createEventDispatcher, onDestroy } from 'svelte';

  import 'cropperjs/dist/cropper.css';

  import AvatarPlaceholder from '@/assets/img/avatar-placeholder.png';
  import Input from '@/lib/components/input/Input.svelte';
  import { t } from '@/locales/i18n';

  import BinIcon from '../icons/BinIcon.svelte';
  import ScissorIcon from '../icons/ScissorIcon.svelte';
  import CropperModal from './components/CropperModal.svelte';
  import LoaderModal from './components/LoaderModal.svelte';
  import { IMAGE_VALIDATION } from './constants';
  import { cropperStore } from './stores/cropper.store';
  import { ImageProcessingState } from './types/processing-state.enum';
  import type { ImageChangeEvent } from './types/types';

  export let currentImageUrl: string | undefined = undefined;

  let imageFile: File | null = null;
  let imageError: string | null = null;
  let imgPreview = AvatarPlaceholder;
  let aspectRatioValid = true;
  let aspectRatioMessage: string | null = null;

  const dispatch = createEventDispatcher<{
    change: ImageChangeEvent;
  }>();

  onDestroy(() => {
    cropperStore.destroyCropper();
  });

  const handlePreview = async (e: Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    imageError = null;

    if (file.size > IMAGE_VALIDATION.MAX_FILE_SIZE) {
      imageError = $t('dashboard.settings.profile.form.avatar-max-size');
      dispatch('change', { file: null, error: imageError });
    }

    imgPreview = URL.createObjectURL(file);
    imageFile = file;

    aspectRatioValid = false;
    aspectRatioMessage = $t(
      'dashboard.settings.profile.form.avatar-needs-crop',
    );

    if (!imageError) {
      dispatch('change', { file: null, error: aspectRatioMessage });
    }
  };

  const handleOpenCropper = () => {
    if (!imageFile) return;

    cropperStore.destroyCropper();
    cropperStore.setShowCropper(true);

    setTimeout(() => {
      const img = document.querySelector('#cropperImage');
      if (img instanceof HTMLImageElement) {
        cropperStore.initCropper(img);
      }
    }, 100);
  };

  const handleCropSave = async () => {
    if (!$cropperStore.cropper || !imageFile) return;
    cropperStore.setProcessing(true, ImageProcessingState.CROPPING);

    const currentFile = imageFile;

    try {
      const canvas = $cropperStore.cropper.getCroppedCanvas({
        width: IMAGE_VALIDATION.DIMENSIONS.width,
        height: IMAGE_VALIDATION.DIMENSIONS.height,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });

      if (!canvas) {
        throw new Error('Failed to create canvas');
      }

      canvas.toBlob(
        async (blob) => {
          try {
            if (blob) {
              new Compressor(blob, {
                quality: IMAGE_VALIDATION.QUALITY,
                maxWidth: IMAGE_VALIDATION.DIMENSIONS.width,
                maxHeight: IMAGE_VALIDATION.DIMENSIONS.height,
                success: (compressedFile) => {
                  if (compressedFile.size > IMAGE_VALIDATION.MAX_FILE_SIZE) {
                    imageError = $t(
                      'dashboard.settings.profile.form.avatar-compression-failed',
                    );
                    cropperStore.setProcessing(
                      false,
                      ImageProcessingState.ERROR,
                    );
                    cropperStore.destroyCropper();
                    aspectRatioValid = false;
                    const finalFile = new File(
                      [compressedFile],
                      currentFile.name,
                      {
                        type: currentFile.type,
                        lastModified: Date.now(),
                      },
                    );

                    imageFile = finalFile;
                    imgPreview = URL.createObjectURL(finalFile);
                    dispatch('change', { file: null, error: imageError });
                    return;
                  }

                  if (!compressedFile.type.startsWith('image/')) {
                    imageError = $t(
                      'dashboard.settings.profile.form.avatar-type-error',
                    );
                  }

                  const finalFile = new File(
                    [compressedFile],
                    currentFile.name,
                    {
                      type: currentFile.type,
                      lastModified: Date.now(),
                    },
                  );

                  imageFile = finalFile;
                  imgPreview = URL.createObjectURL(finalFile);
                  cropperStore.destroyCropper();
                  aspectRatioValid = true;
                  aspectRatioMessage = null;
                  imageError = null;
                  cropperStore.setProcessing(
                    false,
                    ImageProcessingState.SUCCESS,
                  );
                  dispatch('change', { file: finalFile, error: null });
                },
                error: (err) => {
                  console.error('Compression error:', err);
                  imageError = $t(
                    'dashboard.settings.profile.form.avatar-compress-error',
                  );
                  cropperStore.setProcessing(false, ImageProcessingState.ERROR);
                  cropperStore.destroyCropper();
                  dispatch('change', { file: null, error: imageError });
                },
              });
            }
          } catch (error) {
            console.error('Error processing blob:', error);
            imageError = $t(
              'dashboard.settings.profile.form.avatar-process-error',
            );
            cropperStore.setProcessing(false, ImageProcessingState.ERROR);
            cropperStore.destroyCropper();
            dispatch('change', { file: null, error: imageError });
          }
        },
        currentFile.type,
        0.8,
      );
    } catch (error) {
      console.error('Error cropping image:', error);
      imageError = $t('dashboard.settings.profile.form.avatar-crop-error');
      cropperStore.setProcessing(false, ImageProcessingState.ERROR);
      cropperStore.destroyCropper();
      dispatch('change', { file: null, error: imageError });
    }
  };

  const handleRemoveImage = () => {
    imgPreview = AvatarPlaceholder;
    imageFile = null;
    imageError = null;
    aspectRatioValid = true;
    aspectRatioMessage = null;
    cropperStore.destroyCropper();
    dispatch('change', { file: null, error: null });
  };
</script>

<div class="flex h-fit w-full flex-col items-center justify-center">
  {#if currentImageUrl || imgPreview}
    <div class="mb-4 flex items-center gap-4">
      <div
        class="h-20 w-20 overflow-hidden rounded-lg border-2 border-gray-200 bg-white"
      >
        <img
          src={imgPreview !== AvatarPlaceholder
            ? imgPreview
            : currentImageUrl || AvatarPlaceholder}
          alt="avatar thumbnail"
          class="h-full w-full object-cover"
          on:error={() => {
            imgPreview = AvatarPlaceholder;
          }}
        />
      </div>
      {#if imgPreview !== AvatarPlaceholder && imgPreview}
        <div class="flex flex-col gap-2">
          <button
            type="button"
            aria-label="Edit image"
            class="rounded bg-blue-500 px-2 py-1 font-bold text-white hover:bg-blue-700"
            on:click={handleOpenCropper}
          >
            <ScissorIcon />
          </button>
          <button
            type="button"
            aria-label="Remove image"
            class="rounded bg-red-500 px-2 py-1 font-bold text-white hover:bg-red-700"
            on:click={handleRemoveImage}
          >
            <BinIcon />
          </button>
        </div>
      {/if}
    </div>
    {#if imageError}
      <p class="mt-2 max-w-[300px] text-center text-sm text-red-600">
        {imageError}
      </p>
    {/if}
  {/if}
  <div class="flex h-full w-full flex-col gap-2">
    <label
      for="avatarFileInput"
      class="w-full text-sm font-medium text-gray-900"
      >{$t('dashboard.settings.profile.form.avatar-label')}</label
    >
    <Input
      name="avatarFileInput"
      styleClass="w-full"
      type="file"
      accept="image/*"
      on:change={handlePreview}
      testId="profile-avatar-input"
    />
  </div>
  {#if aspectRatioMessage && imgPreview !== AvatarPlaceholder && !aspectRatioValid}
    <p class="mt-2 max-w-[300px] text-center text-sm text-amber-600">
      {aspectRatioMessage}
    </p>
  {/if}
</div>

{#if $cropperStore.showCropper && imageFile}
  <CropperModal
    imageFile={imageFile}
    onSave={handleCropSave}
    onCancel={() => {
      cropperStore.destroyCropper();
      aspectRatioValid = false;
      aspectRatioMessage = $t(
        'dashboard.settings.profile.form.avatar-needs-crop',
      );
      dispatch('change', { file: null, error: aspectRatioMessage });

      if (imageFile) {
        imgPreview = URL.createObjectURL(imageFile);
      }
    }}
  />
{/if}

{#if $cropperStore.isProcessing}
  <LoaderModal />
{/if}
