<script lang="ts">
  import type { IPaginatedQuery } from '@/interface/IPaginatedQuery';
  import type { ITeam } from '@/interface/ITeam';
  import { openModal } from '@/lib/components/modal/modal.store';
  import CreateTeamModal from '@/lib/components/modal/modals/CreateTeamModal.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import type { SearchEvent } from '@/lib/components/search-input/search-input.types';
  import Table from '@/lib/components/tables/Table.svelte';
  import type {
    SortEvent,
    TableItem,
  } from '@/lib/components/tables/table.types';
  import { getTeamActions } from '@/lib/components/tables/teams/team-actions';
  import { getTeamColumns } from '@/lib/components/tables/teams/team-columns';
  import TeamTableTools from '@/lib/components/tables/teams/TeamsTableTools.svelte';
  import { ApiError } from '@/lib/services/axios/api-error';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    getTeams,
    type ITeamsQuery,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  let query: ITeamsQuery = {
    name: '',
    hostName: '',
    limit: 6,
    offset: 0,
  };

  let teams: ITeam[] = [];
  let count = 0;
  let isTableLoading = false;
  let isSearchLoading = false;
  let searchInputValue = '';
  let hasActiveSearch = false;

  const asTableItem = (team: ITeam): TableItem => team;

  async function fetchTeams() {
    isTableLoading = true;
    if (hasActiveSearch) {
      isSearchLoading = true;
    }

    try {
      const { teams: newTeams, count: newCount } = await getTeams(query);
      teams = newTeams;
      count = newCount;
    } catch (error: unknown) {
      console.error('Error fetching teams:', error);
      if (error instanceof ApiError) {
        ToastNotification.error({
          title: $t('dashboard.teams.fetch-teams-error'),
          message: error.details || error.message,
        });
      } else {
        ToastNotification.error({
          title: $t('dashboard.teams.fetch-teams-error'),
          message: 'Contact support for more information',
        });
      }
      teams = [];
      count = 0;
    } finally {
      isTableLoading = false;
      isSearchLoading = false;
    }
  }

  const handleClearSearch = () => {
    query = {
      ...query,
      name: '',
      offset: 0,
    };
    searchInputValue = '';
    hasActiveSearch = false;
  };

  const handleSearch = (event: CustomEvent<SearchEvent>) => {
    const { searchBy, searchQuery } = event.detail;
    isSearchLoading = true;
    hasActiveSearch = !!searchQuery.trim();

    if (!searchQuery.trim()) {
      handleClearSearch();
      return;
    }

    query.offset = 0;
    query.hostName = '';
    query.name = '';

    switch (searchBy) {
      case 'hostName':
        query.hostName = searchQuery;
        break;
      case 'name':
        query.name = searchQuery;
        break;
      default:
        break;
    }
  };

  const updateOffset = () => {
    if (
      teams.length === 1 &&
      query?.offset &&
      query?.offset > 0 &&
      query?.limit
    ) {
      query.offset = query?.offset - query?.limit;
    }
  };

  const handleCreateTeam = () => {
    openModal(CreateTeamModal, {
      allowCloseClick: false,
      onClose: fetchTeams,
    });
  };

  const handleSort = (event: CustomEvent<SortEvent>) => {
    const { column, order } = event.detail;
    query = { ...query, sortBy: column, sortOrder: order };
  };

  const handlePageChange = (newQuery: IPaginatedQuery) => {
    query = { ...query, offset: newQuery.offset, limit: newQuery.limit };
  };

  $: columns = getTeamColumns();
  $: actions = getTeamActions(fetchTeams, updateOffset);

  $: query, fetchTeams();
</script>

<div class="flex h-full flex-col gap-4">
  <TeamTableTools
    isSearchLoading={isSearchLoading}
    searchInputValue={searchInputValue}
    handleCreateTeam={handleCreateTeam}
    on:search={handleSearch}
  />

  <div class="flex h-full min-h-[500px] flex-col">
    <Table
      columns={columns}
      data={teams.map(asTableItem)}
      sortBy={query.sortBy}
      sortOrder={query.sortOrder}
      actions={actions}
      isLoading={isTableLoading}
      emptyMessage={$t('dashboard.teams.no-teams-found')}
      loadingMessage={$t('dashboard.teams.loading')}
      on:sort={handleSort}
    />

    {#if !isTableLoading && teams.length > 0}
      <div class="mt-auto border-t px-4 py-2">
        <Paginator total={count} query={query} onChange={handlePageChange} />
      </div>
    {/if}
  </div>
</div>
