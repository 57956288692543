<script lang="ts">
  import type { ISession } from '@/interface/ISession';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import { getSessionActions } from '@/lib/components/tables/sessions/session-actions';
  import Table from '@/lib/components/tables/Table.svelte';
  import type { TableItem } from '@/lib/components/tables/table.types';
  import { getTeamSessionColumns } from '@/lib/components/tables/teams/team-session-columns';
  import {
    getSessions,
    type ISessionQuery,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  export let teamId: number;

  let loading = false;
  let sessions: ISession[] | null = null;
  let count = 0;

  let sessionQuery: ISessionQuery = {
    limit: 10,
    offset: 0,
    teamId: teamId,
  };

  const fetchSessions = async () => {
    loading = true;
    const result = await getSessions(sessionQuery);
    sessions = result.sessions;
    count = result.count;
    loading = false;
  };

  const asTableItem = (session: ISession): TableItem => session;

  $: columns = getTeamSessionColumns();
  $: actions = getSessionActions(fetchSessions);
  $: sessionQuery, fetchSessions();
</script>

<div
  class="flex w-full flex-col gap-4 rounded-lg border border-light-grey bg-white p-8"
>
  {#if loading}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:else if sessions}
    <div class="w-full overflow-x-auto">
      <Table
        columns={columns}
        data={sessions.map(asTableItem)}
        actions={actions}
        isLoading={false}
        emptyMessage={$t('dashboard.sessions.no-sessions')}
      />
    </div>
    <Paginator
      total={count}
      query={sessionQuery}
      onChange={(newQuery) => (sessionQuery = { ...newQuery })}
    />
  {/if}
</div>
