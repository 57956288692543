<script lang="ts">
  import TeamsTable from '@/lib/components/tables/teams/TeamsTable.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>{$t('dashboard.teams.route-title')}</title>
</svelte:head>
<div class="flex h-full w-full flex-col bg-gray-50">
  <div
    class="flex min-h-[4rem] w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 ps-4 text-2xl font-bold text-primary">
      {$t('dashboard.teams.title')}
    </h1>
  </div>
  <div class="flex-1 overflow-auto px-6 py-4">
    <div
      class="flex h-fit min-h-full w-full flex-col justify-center rounded-lg border border-light-grey bg-white shadow-sm"
      data-tour="dashboard-teams-table-container"
    >
      <div class="px-6 pb-2 pt-4">
        <TeamsTable />
      </div>
    </div>
  </div>
</div>
