<script script lang="ts">
  import moment from 'moment';
  import { onMount } from 'svelte';
  import { planNames, SubscriptionPlan } from '@/interface/IExperience';
  import Input from '@/lib/components/input/Input.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import {
    createBillingPortal,
    getActiveTenantUsers,
    getSubscriptionPlans,
    upgradePlan,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  import PriceCard from './PriceCard.svelte';

  let activeSubscription = $userStore?.tenant?.subscriptionPlan;
  let selectedSeats = $userStore?.tenant?.seats || 0;
  let activeSeats = $userStore?.tenant?.seats || 0;

  const handleCheckoutForPlan = async (plan: SubscriptionPlan) => {
    try {
      const { checkoutUrl } = await upgradePlan({ plan, seats: selectedSeats });
      window.location.href = checkoutUrl;
    } catch (err) {
      console.log(err);
    }
  };

  let currentAmountofUsers = 0;
  const handleSetToFree = async () => {
    try {
      const { billingPortalUrl } = await createBillingPortal();
      window.open(billingPortalUrl, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  let starterPrice = 0;
  let proPrice = 0;

  let seatOptions:
    | {
        label: string;
        seats: number;
        starterPrice: number;
        proPrice: number;
        testId: string;
      }[]
    | null = null;

  $: {
    if (seatOptions) {
      const selectedOption = seatOptions.find(
        (option) => option.seats === selectedSeats,
      );
      starterPrice = selectedOption?.starterPrice || 0;
      proPrice = selectedOption?.proPrice || 0;
    }
  }

  const handleSeatsSelection = (seats: number) => {
    selectedSeats = selectedSeats === seats ? 0 : seats;
  };

  $: freeButtonLabel =
    activeSubscription === SubscriptionPlan.FREE
      ? $t('dashboard.settings.billing.free-plan-card-btn-current')
      : $t('dashboard.settings.billing.free-plan-card-btn-set');

  $: starterButtonLabel =
    activeSubscription === SubscriptionPlan.STARTER &&
    selectedSeats === $userStore?.tenant?.seats
      ? $t('dashboard.settings.billing.starter-plan-card-btn-current')
      : activeSubscription === SubscriptionPlan.STARTER
        ? $t('dashboard.settings.billing.starter-plan-card-btn-update')
        : $t('dashboard.settings.billing.starter-plan-card-btn-set');

  $: proButtonLabel =
    activeSubscription === SubscriptionPlan.PRO &&
    selectedSeats === $userStore?.tenant?.seats
      ? $t('dashboard.settings.billing.pro-plan-card-btn-current')
      : activeSubscription === SubscriptionPlan.PRO
        ? $t('dashboard.settings.billing.pro-plan-card-btn-update')
        : $t('dashboard.settings.billing.pro-plan-card-btn-set');
  let currentPlanText = [''];
  $: {
    currentPlanText = $t('dashboard.settings.billing.current-plan').split('-');
  }
  onMount(async () => {
    const { count } = await getActiveTenantUsers();
    currentAmountofUsers = count;
    const plans = await getSubscriptionPlans();
    if (!plans.length) return;
    seatOptions = [
      {
        label: $t('dashboard.settings.billing.small-checkbox-label').replace(
          '{{seats}}',
          `${plans[1]?.smallCompanySeats}`,
        ),
        seats: plans[1]?.smallCompanySeats ?? 0,
        starterPrice: plans[0]?.smallCompanyPrice ?? 0,
        proPrice: plans[1]?.smallCompanyPrice ?? 0,
        testId: 'checkbox-option-small',
      },
      {
        label: $t('dashboard.settings.billing.medium-checkbox-label').replace(
          '{{seats}}',
          `${plans[1]?.mediumCompanySeats}`,
        ),
        seats: plans[1]?.mediumCompanySeats ?? 0,
        starterPrice: plans[0]?.mediumCompanyPrice ?? 0,
        proPrice: plans[1]?.mediumCompanyPrice ?? 0,
        testId: 'checkbox-option-medium',
      },
      {
        label: $t('dashboard.settings.billing.large-checkbox-label').replace(
          '{{seats}}',
          `${plans[1]?.largeCompanySeats}`,
        ),
        seats: plans[1]?.largeCompanySeats ?? 0,
        starterPrice: plans[0]?.largeCompanyPrice ?? 0,
        proPrice: plans[1]?.largeCompanyPrice ?? 0,
        testId: 'checkbox-option-large',
      },
    ];
  });
</script>

<svelte:head>
  <title>{$t('dashboard.settings.billing.route-title')}</title>
</svelte:head>

<Page>
  <div
    class="h-max-fit flex w-full flex-col gap-4 rounded-none border-x border-t border-light-grey bg-white p-8"
  >
    <h1 class="text-3xl font-bold">{$t('dashboard.settings.billing.title')}</h1>
    {#if activeSubscription !== SubscriptionPlan.FREE}
      <p>
        {currentPlanText[0]}
        <span class="font-bold">
          {planNames[activeSubscription || SubscriptionPlan.FREE]}
          ( {activeSeats} )
        </span>
        {currentPlanText[1]}
        <span class="font-bold"
          >{moment($userStore?.tenant?.subscriptionExpirationDate).format(
            'MM/DD/YYYY',
          )}</span
        >
        . {currentPlanText[2]}
        <span
          on:keypress
          on:click={handleSetToFree}
          data-cy="open-billing-portal"
          class="cursor-pointer font-bold text-primary hover:underline"
          >{currentPlanText[3]}</span
        >
      </p>
    {/if}
    <p>
      {$t('dashboard.settings.billing.description')}
      <span class="font-bold"
        >{currentAmountofUsers}/{$userStore?.tenant?.seats}</span
      >
    </p>
    <div class="flex flex-col items-center justify-center gap-8">
      <h1 class="text-3xl font-bold">
        {$t('dashboard.settings.billing.choose-company-size')}
      </h1>
      <div class="flex flex-row gap-4">
        {#if seatOptions}
          {#each seatOptions as option}
            <div class="flex flex-row items-center gap-2">
              <span class="text-lg">{option.label}</span>
              <Input
                type="checkbox"
                testId={option.testId}
                checked={selectedSeats === option.seats}
                on:change={() => handleSeatsSelection(option.seats)}
              />
            </div>
          {/each}
        {/if}
      </div>
    </div>
    <div
      class="mb-12 flex flex-col items-center justify-center gap-12 p-8 md:flex-row"
    >
      <PriceCard
        title={$t('dashboard.settings.billing.free-plan-card-title')}
        price="$0"
        priceLabel={$t('dashboard.settings.billing.free-plan-card-price-label')}
        descriptions={[
          $t('dashboard.settings.billing.free-plan-card-description-1'),
        ]}
        buttonLabel={freeButtonLabel}
        buttonTestId="free-button"
        buttonDisabled={activeSubscription === SubscriptionPlan.FREE}
        hideButton={true}
      />
      <PriceCard
        title={$t('dashboard.settings.billing.starter-plan-card-title')}
        price={`$${starterPrice || 'x'}`}
        priceLabel={$t(
          'dashboard.settings.billing.starter-plan-card-price-label',
        )}
        descriptions={[
          $t('dashboard.settings.billing.starter-plan-card-description-1'),
        ]}
        buttonLabel={starterButtonLabel}
        buttonTestId="starter-button"
        buttonDisabled={(activeSubscription === SubscriptionPlan.STARTER &&
          selectedSeats === activeSeats) ||
          selectedSeats === 0 ||
          currentAmountofUsers > selectedSeats}
        isPrimary={true}
        onClick={() => handleCheckoutForPlan(SubscriptionPlan.STARTER)}
      />
      <PriceCard
        title={$t('dashboard.settings.billing.pro-plan-card-title')}
        price={`$${proPrice || 'x'}`}
        priceLabel={$t('dashboard.settings.billing.pro-plan-card-price-label')}
        descriptions={[
          $t('dashboard.settings.billing.pro-plan-card-description-1'),
        ]}
        buttonLabel={proButtonLabel}
        buttonTestId="pro-button"
        buttonDisabled={(activeSubscription === SubscriptionPlan.PRO &&
          selectedSeats === activeSeats) ||
          selectedSeats === 0 ||
          currentAmountofUsers > selectedSeats}
        isPrimary={true}
        onClick={() => handleCheckoutForPlan(SubscriptionPlan.PRO)}
      />
    </div>
  </div>
</Page>
