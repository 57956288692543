<script lang="ts">
  import Avatar from '@/lib/components/avatar/Avatar.svelte';
  import { t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  const navigateToProfile = () => {
    window.location.href = '/dashboard/settings/profile';
  };

  const displayName = `${$userStore?.name} ${$userStore?.lastName}` || 'N/A';
</script>

<div
  class="flex w-fit flex-row items-center justify-center gap-2 hover:cursor-pointer hover:text-primary"
  on:click={navigateToProfile}
  on:keypress
>
  <Avatar
    src={$userStore?.avatarUrl}
    size="lg"
    clickable={true}
    className="border-2"
  />
  <div class="flex w-fit flex-col">
    <span class="font-bold text-grey">{$t('dashboard.sidebar.welcome')}</span>
    <span class="text-sm font-bold">{displayName}</span>
  </div>
</div>
