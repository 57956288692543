<script lang="ts">
  import LocaleSelector from '@/lib/components/locale-selector/LocaleSelector.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import TourTrigger from '@/lib/components/tour/TourTrigger.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>{$t('dashboard.settings.general.route-title')}</title>
</svelte:head>

<Page>
  <div class="flex w-full flex-col gap-8">
    <div
      class="flex w-full flex-col gap-4 border border-light-grey bg-white p-8"
    >
      <h2 class="text-xl font-bold text-dark-grey">
        {$t('dashboard.settings.general.language.title')}
      </h2>
      <p class="text-grey">
        {$t('dashboard.settings.general.language.description')}
      </p>
      <LocaleSelector navbarPlacement={false} />
    </div>

    <div
      class="flex w-full flex-col gap-4 border border-light-grey bg-white p-8"
      data-tour="settings-guide-section"
    >
      <h2 class="text-xl font-bold text-dark-grey">
        {$t('dashboard.settings.general.guide.title')}
      </h2>
      <p class="text-grey">
        {$t('dashboard.settings.general.guide.description')}
      </p>
      <div class="flex flex-col gap-4">
        <TourTrigger />
        <p class="text-sm text-grey">
          {$t('dashboard.settings.general.guide.tip')}
        </p>
      </div>
    </div>
  </div>
</Page>
