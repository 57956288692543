<script lang="ts">
  import { quintOut } from 'svelte/easing';
  import { fly } from 'svelte/transition';
  import type { ISessionUser } from '@/interface/ISessionUser';
  import FeedbackCard from '@/lib/components/cards/FeedbackCard.svelte';
  import { t } from '@/locales/i18n';

  export let sessionUsers: ISessionUser[];
  let page = 1;
  $: pages = Math.ceil(sessionUsers.length / 3);
  $: maxPage = Math.max(1, pages);
  $: currentPage = Math.min(page, maxPage);

  function changePage(newPage: number) {
    if (newPage === page) return;
    page = newPage;
  }

  function nextPage() {
    if (page < maxPage) {
      page++;
    }
  }

  function prevPage() {
    if (page > 1) {
      page--;
    }
  }

  $: feedbackSectionLabel = $t(
    'dashboard.experiences.feedback-section-aria-label',
  );
  $: paginationLabel = $t(
    'dashboard.experiences.feedback-pagination-aria-label',
  );
  $: previousPageLabel = $t('dashboard.experiences.feedback-previous-page');
  $: nextPageLabel = $t('dashboard.experiences.feedback-next-page');
  $: pageButtonLabel = (pageNum: number) =>
    $t('dashboard.experiences.feedback-page-button', {
      page: pageNum,
      totalPages: maxPage,
    });
  $: noFeedbackLabel = $t('dashboard.experiences.no-feedback');
</script>

<div
  class="flex w-full flex-col justify-center gap-3"
  role="region"
  aria-labelledby="feedback-section-title"
>
  <h2
    id="feedback-section-title"
    class="mb-4 border-b border-light-grey pb-2 text-xl font-bold text-primary"
  >
    {$t('dashboard.experiences.feedback-title')}
  </h2>
  {#if sessionUsers.length > 0}
    <div class="flex flex-col gap-2">
      <div
        class="relative h-auto min-h-[200px] w-full overflow-hidden"
        aria-label={feedbackSectionLabel}
        role="list"
      >
        {#key currentPage}
          <div
            style="position: absolute; left: 0; top: 0; width: 100%;"
            in:fly={{ x: 300, y: 0, duration: 500, easing: quintOut }}
            out:fly={{ x: -300, y: 0, duration: 300 }}
            class="flex w-full flex-row items-center justify-start gap-4"
          >
            {#each sessionUsers.slice((currentPage - 1) * 3, currentPage * 3) as sessionUser}
              <FeedbackCard sessionUser={sessionUser} />
            {/each}
          </div>
        {/key}
      </div>

      {#if pages > 1}
        <div
          class="mt-2 flex h-10 w-full flex-row items-center justify-center gap-4"
          role="navigation"
          aria-label={paginationLabel}
        >
          <button
            on:click={prevPage}
            disabled={page === 1}
            class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-primary hover:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label={previousPageLabel}
          >
            &lt;
          </button>

          {#each new Array(pages)
            .fill(0)
            .map((_, index) => index + 1) as pageNum}
            <button
              data-cy="feedback-page-button-{pageNum}"
              on:click={() => changePage(pageNum)}
              class={`shrink-0 rounded-full transition-all duration-300 ease-in-out ${
                currentPage === pageNum
                  ? 'h-4 w-12 bg-primary'
                  : 'h-3 w-3 bg-light-grey hover:bg-gray-300'
              }`}
              aria-label={pageButtonLabel(pageNum)}
              aria-current={currentPage === pageNum ? 'page' : undefined}
            />
          {/each}

          <button
            on:click={nextPage}
            disabled={page >= maxPage}
            class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-primary hover:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label={nextPageLabel}
          >
            &gt;
          </button>
        </div>
      {/if}
    </div>
  {:else}
    <div
      class="flex h-[230px] w-full flex-col items-center justify-center gap-4"
      role="status"
      aria-label={noFeedbackLabel}
    >
      <p class="text-sm text-grey">{noFeedbackLabel}</p>
    </div>
  {/if}
</div>
