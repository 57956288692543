<script lang="ts">
  import type { ISessionUser } from '@/interface/ISessionUser';
  import Avatar from '@/lib/components/avatar/Avatar.svelte';
  import { StarFilledIcon, StarIcon } from '@/lib/components/icons';
  import { t } from '@/locales/i18n';

  export let sessionUser: ISessionUser;

  const { user, feedbackRating, feedbackComment } = sessionUser;

  const commentLimit = 100;
  let showFullComment = false;

  function getStars(rating: number) {
    return Array.from({ length: 5 }, (_, i) => i < rating);
  }
</script>

<div
  class="flex h-fit w-80 flex-col items-start gap-3 rounded-lg bg-white p-6 shadow-lg"
>
  <div class="flex w-full items-center gap-3">
    <Avatar
      src={user.avatarUrl}
      alt={`${user.name} ${user.lastName}`}
      size="md"
      className="border-2 border-gray-200"
    />
    <div>
      <p class="text-base font-bold capitalize">
        {user.name}
        {user.lastName}
      </p>
      <p data-cy="session-user-email" class="text-sm text-gray-600">
        {user.email}
      </p>
    </div>
  </div>

  <div class="mt-2 h-px w-full bg-gray-100"></div>

  {#if feedbackRating}
    <div class="flex w-full flex-col gap-2">
      <h4 class="font-semibold text-gray-700">
        {$t('dashboard.sessions.card-rating')}
      </h4>
      <div class="flex items-center">
        {#each getStars(feedbackRating) as filled}
          <span class="text-yellow-400">
            {#if filled}
              <StarFilledIcon class_="h-5 w-5" />
            {:else}
              <StarIcon class_="h-5 w-5" />
            {/if}
          </span>
        {/each}
        <span class="ml-2 font-medium">{feedbackRating}/5</span>
      </div>
    </div>

    {#if feedbackComment}
      <div class="mt-2 w-full">
        <h4 class="font-semibold text-gray-700">
          {$t('dashboard.sessions.card-comment')}
        </h4>
        {#if feedbackComment.length > commentLimit}
          {#if showFullComment}
            <div
              class="mt-1 max-h-28 overflow-y-auto rounded bg-gray-50 p-2 text-sm"
            >
              <p>{feedbackComment}</p>
            </div>
            <button
              class="hover:text-primary-dark mt-2 text-sm text-primary hover:cursor-pointer hover:underline"
              on:click={() => (showFullComment = false)}
            >
              {$t('common.view-less')}
            </button>
          {:else}
            <div class="mt-1 rounded bg-gray-50 p-2 text-sm">
              <p>
                {feedbackComment.substring(0, commentLimit)}...
              </p>
            </div>
            <button
              class="hover:text-primary-dark mt-2 text-sm text-primary hover:cursor-pointer hover:underline"
              on:click={() => (showFullComment = true)}
            >
              {$t('common.view-more')}
            </button>
          {/if}
        {:else}
          <div class="mt-1 rounded bg-gray-50 p-2 text-sm">
            <p>{feedbackComment}</p>
          </div>
        {/if}
      </div>
    {/if}
  {:else}
    <div
      class="mt-2 flex w-full items-center justify-center rounded-md bg-gray-50 p-4 text-center"
    >
      <h4 class="font-semibold text-gray-500">
        {$t('dashboard.sessions.card-feedback-pending')}
      </h4>
    </div>
  {/if}
</div>
