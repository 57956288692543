<script lang="ts">
  import moment from 'moment';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';
  import {
    ExperienceLanguages,
    SubscriptionPlan,
    type IExperience,
  } from '@/interface/IExperience';
  import type { ISessionSchedule } from '@/interface/ISessionSchedule';
  import ExperienceCard from '@/lib/components/cards/ExperienceCard.svelte';
  import ExperienceCatalogTools from '@/lib/components/experiences-catalog/ExperienceCatalogTools.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Paginator from '@/lib/components/paginator/Paginator.svelte';
  import type { SearchEvent } from '@/lib/components/search-input/search-input.types';
  import { ApiError } from '@/lib/services/axios/api-error';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    getExperiences,
    getSessionSchedule,
    type IExperienceQuery,
  } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';
  import { userStore } from '@/store/userStore';

  let experiences: IExperience[] = [];
  let count = 0;
  let query: IExperienceQuery = {
    limit: 3,
    offset: 0,
    categoryId: [],
    language:
      $locale === 'en'
        ? ExperienceLanguages.english
        : ExperienceLanguages.spanish,
    name: '',
    rating: [],
  };

  let sessionEvent: ISessionSchedule | undefined = undefined;
  let monthlyExperience: IExperience | null = null;
  let isLoading = false;
  let error: Error | null = null;

  let selectedLanguage: string =
    $locale === 'en'
      ? ExperienceLanguages.english
      : ExperienceLanguages.spanish;

  let selectedCategories: number[] = [];
  let selectedRating: number[] = [];
  let isSearchLoading = false;
  let searchInputValue = '';
  let hasActiveSearch = false;

  async function fetchExperiences() {
    isLoading = true;
    if (hasActiveSearch) {
      isSearchLoading = true;
    }

    try {
      const response = await getExperiences(query);
      experiences = response.experiences;
      count = response.count;
      error = null;
    } catch (err: unknown) {
      console.error('Error fetching experiences:', err);
      if (err instanceof ApiError) {
        ToastNotification.error({
          title: $t('dashboard.experiences.fetch-experiences-error'),
          message: err.details || err.message,
        });
      } else if (err instanceof Error) {
        error = err;
        ToastNotification.error({
          title: $t('dashboard.experiences.fetch-experiences-error'),
          message: 'Contact support for more information',
        });
      }
      experiences = [];
      count = 0;
    } finally {
      isLoading = false;
      isSearchLoading = false;
    }
  }

  $: query, fetchExperiences();

  const handleExperienceDetails = (experience: IExperience | null) => {
    if (!experience) return;
    navigate(`/dashboard/experiences/${experience.id}`);
  };

  const blockUser = (experience: IExperience) => {
    const isMonthly = experience.id === monthlyExperience?.id;

    if ($userStore?.tenant.subscriptionPlan === SubscriptionPlan.FREE) {
      return true;
    }

    if (
      $userStore?.tenant.subscriptionPlan === SubscriptionPlan.STARTER &&
      isMonthly
    ) {
      return false;
    }
    if ($userStore?.tenant.subscriptionPlan === SubscriptionPlan.PRO) {
      return false;
    }
    return true;
  };

  const handleLanguageChange = (event: CustomEvent<string>) => {
    const language = event.detail;
    query = { ...query, offset: 0, language: language as ExperienceLanguages };
    const newMonthlyExperience =
      language === ExperienceLanguages.english
        ? sessionEvent?.englishExperience
        : sessionEvent?.spanishExperience;

    monthlyExperience = newMonthlyExperience || null;
  };

  const handleCategoryChange = (event: CustomEvent<number[]>) => {
    selectedCategories = event.detail;
    query = {
      ...query,
      offset: 0,
      categoryId: selectedCategories,
    };
  };

  const handleRatingChange = (event: CustomEvent<number[]>) => {
    selectedRating = event.detail;
    query = {
      ...query,
      offset: 0,
      rating: selectedRating,
    };
  };

  const handleSearch = (event: CustomEvent<SearchEvent>) => {
    const { searchQuery } = event.detail;
    isSearchLoading = true;
    hasActiveSearch = !!searchQuery.trim();

    if (!searchQuery.trim()) {
      handleClearSearch();
      return;
    }

    query = {
      ...query,
      offset: 0,
      name: searchQuery,
    };
  };

  const handleClearSearch = () => {
    query = {
      ...query,
      name: '',
      offset: 0,
    };
    searchInputValue = '';
    hasActiveSearch = false;
    isSearchLoading = false;
  };

  const handlePaginationChange = (newQuery: Partial<IExperienceQuery>) => {
    query = { ...query, ...newQuery };
  };

  const hasValidMonthlyExperience = (): boolean => {
    if (!monthlyExperience) return false;

    const isInCurrentResults = experiences.some(
      (exp) => exp.id === monthlyExperience?.id,
    );

    if (isInCurrentResults) return true;

    if (
      query.name &&
      !monthlyExperience.name.toLowerCase().includes(query.name.toLowerCase())
    ) {
      return false;
    }

    if (
      selectedCategories.length > 0 &&
      !selectedCategories.includes(monthlyExperience.category.id)
    ) {
      return false;
    }

    if (selectedRating.length > 0) {
      const rating =
        monthlyExperience.totalRaters > 0
          ? Math.round(
              monthlyExperience.totalRatings / monthlyExperience.totalRaters,
            )
          : 0;
      if (!selectedRating.includes(rating)) {
        return false;
      }
    }

    return true;
  };

  onMount(async () => {
    try {
      const thisMonth = moment().format('MMMM');
      sessionEvent = await getSessionSchedule(thisMonth);
      const newMonthlyExperience =
        $locale === 'en'
          ? sessionEvent.englishExperience
          : sessionEvent.spanishExperience;

      monthlyExperience = newMonthlyExperience || null;
    } catch (error) {
      monthlyExperience = null;
    }
  });
</script>

<div class="flex h-full flex-col gap-4">
  <ExperienceCatalogTools
    selectedLanguage={selectedLanguage}
    selectedCategories={selectedCategories}
    selectedRating={selectedRating}
    isSearchLoading={isSearchLoading}
    searchInputValue={searchInputValue}
    on:languageChange={handleLanguageChange}
    on:categoryChange={handleCategoryChange}
    on:ratingChange={handleRatingChange}
    on:search={handleSearch}
  />

  <div class="flex h-full min-h-[500px] flex-col">
    {#if isLoading}
      <div class="flex h-[30rem] w-full grow items-center justify-center">
        <Loading loadingSize="h-32 w-32" />
      </div>
    {:else if error}
      <p class="text-center" data-cy="error-message">
        {$t('dashboard.experiences.fetch-error', { error: error.message })}
      </p>
    {:else if count === 0}
      <div class="flex h-[30rem] w-full grow items-center justify-center">
        <span class="text-dark-grey"
          >{$t('dashboard.experiences.no-experiences')}</span
        >
      </div>
    {:else}
      <div class="mx-auto w-full max-w-7xl">
        <div
          class="grid grid-cols-1 gap-x-6 gap-y-6 pb-4 md:grid-cols-2 lg:grid-cols-3"
        >
          {#if hasValidMonthlyExperience() && monthlyExperience}
            <ExperienceCard
              blockUser={blockUser(monthlyExperience)}
              experience={monthlyExperience}
              monthlyExperience={true}
              startSession={() => handleExperienceDetails(monthlyExperience)}
              tourIdentifier="dashboard-experiences-monthly-card"
              testId="dashboard-experiences-monthly-card"
            />
          {/if}

          {#each experiences.filter((exp) => exp.id !== monthlyExperience?.id) as experience, index}
            <ExperienceCard
              blockUser={blockUser(experience)}
              experience={experience}
              startSession={() => handleExperienceDetails(experience)}
              tourIdentifier={`dashboard-experiences-card-${index}`}
              testId={`dashboard-experiences-card-${index}`}
            />
          {/each}
        </div>
      </div>

      {#if count > 0}
        <div class="mt-auto border-t px-4 py-1">
          <Paginator
            total={count}
            query={query}
            onChange={handlePaginationChange}
          />
        </div>
      {/if}
    {/if}
  </div>
</div>
