<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { SessionStatus } from '@/interface/ISession';
  import type { FilterOption } from '@/lib/components/filter-dropdowns/filter-dropdown.types';
  import FilterDropdown from '@/lib/components/filter-dropdowns/FilterDropdown.svelte';
  import type { SessionStateFilterEvent } from '@/lib/components/filter-dropdowns/session-state-filter/session-state-filter.types';
  import { t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    changeStates: SessionStateFilterEvent;
  }>();

  export let selectedStates: SessionStatus[] = [];

  const states: FilterOption<SessionStatus>[] = [
    {
      value: SessionStatus.PENDING,
      label: $t('dashboard.sessions.state.pending'),
    },
    {
      value: SessionStatus.FINISHED,
      label: $t('dashboard.sessions.state.finished'),
    },
    {
      value: SessionStatus.CANCELED,
      label: $t('dashboard.sessions.state.cancelled'),
    },
  ];

  function handleSelectionChange(
    event: CustomEvent<{ values: SessionStatus[] }>,
  ) {
    const values = event.detail.values;
    selectedStates = values;
    dispatch('changeStates', { states: selectedStates });
  }
</script>

<FilterDropdown
  selectedValues={selectedStates}
  options={states}
  title={$t('dashboard.sessions.filter-states')}
  placeholderText={$t('dashboard.sessions.filter-all-states')}
  multipleSelectionText={$t('dashboard.sessions.states-selected')}
  clearText={$t('dashboard.sessions.clear-selection')}
  on:changeSelection={handleSelectionChange}
/>
