interface Translations {
  [locale: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  en: {
    // Navbar
    'navbar.element-home': 'Home',
    'navbar.element-together': 'What is Together?',
    'navbar.element-catalogue': 'Catalogue',
    'navbar.element-aboutus': 'About Us',
    'navbar.element-integrations': 'Integrations',
    'navbar.element-faq': 'FAQ',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Logout',
    'navbar.element-login': 'Login',
    'navbar.element-signup': 'Sign Up',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Try Together!',
    'home.info-title': 'What is Together?',
    'home.info-text-1': `Together is a platform that offers unique experiences that strengthen teamwork and foster meaningful relationships between members, regardless of physical distance. 
                        <br /><br />
                        Integrated with platforms like Miro and Slack, which teams use regularly, allows them to quickly dive into the activities and connect. 
                        <br /><br />
                        With our platform, you will also cultivate a work environment where each member feels connected and engaged by reducing feelings of isolation from remote work.
                        <br />`,
    'home.info-subtitle': 'Connect your Team Today!',
    'home.info-text-2': `Don't let physical distance affect your team connection.<br /> Discover how Together can take your remote collaboration to the next level. 
                        <br /><br />
                        Sign up for a free demo and start building a stronger, more connected and productive team.`,
    'home.catalogue-title': 'Our Experiences',
    'home.aboutus-title': 'About Us',
    'home.aboutus-text-1':
      "Together was born in response to Bigger Tech's need to bring closer its more than 100 employees working from all over the world.",
    'home.aboutus-text-2':
      'The experiences offered by Together are designed based on Agile and coaching principles to foster conversations, fun, and moments of reflection between team members.',
    'home.aboutus-text-3':
      'From the experience of using Together in Bigger Tech teams, we highlight the following benefits:',
    'home.aboutus-list-1':
      'Greater connection between team members<br /><br />',
    'home.aboutus-list-2':
      'A more positive and motivating work environment<br /><br />',
    'home.aboutus-list-3': 'Increased sense of belonging despite the distance',

    // Footer
    'footer.copyright': '© 2025 Together - All Rights Reserved',
    'footer.home': 'Home',
    'footer.login': 'Login',
    'footer.signup': 'Sign Up',
    'footer.terms': 'Terms',
    'footer.privacy': 'Privacy',
    'footer.support': 'Support',
    'footer.faq': 'FAQ',

    // Integrations
    'integrations.claim-text-1': `<a href="/" class="text-blue-600 hover:text-blue-800">Together</a> provides a simple integration with Slack that allows you to easily onboard users, automatically send reminders, and create a dedicated channel for each experience.`,
    'integrations.claim-text-2': `After registering on our platform, you will be able to install the Together App in your Slack workspace and invite your team.`,
    'integrations.claim-text-3': `<strong>Important:</strong> For installing the app, you must be a tenant admin in the Together platform.`,
    'integrations.claim-text-4': `For any questions, please contact us at <a href="emailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>`,
    'integrations.info-title-1':
      'Connect with your Slack workspace and invite your team members quickly',
    'integrations.info-title-2':
      'Start Sessions for you team and automatically create conversation channels to get the most of the Experience',
    'integrations.info-text-1':
      'If your team is already organized and connected with Slack, you do not need to worry about asking them to join the Together platform by themselves. Our platform is designed to be easily integrated so you can connect to your workspace and invite your team members to join your organization. The integration is done in just a few clicks and will create accounts for every member of your team that you want to invite to the platform.',
    'integrations.info-text-2':
      'Together integrations with Slack is meant to make it easy for you and for you team to be ready to use. Enjoy the convenience of having Slack channels created to share and communicate with your team when a Session is scheduled. Also, your team can start sharing their opinions and expectations. We will send you notifications when needed and provide you with all that your teams need for get the most of the Experience. From reminders to updates, we will be there to help you every step of the way. You only will need to do one thing: enjoy the Experience.',
    'integrations.title': 'Integration with ',
    'integrations.instructions-title': 'Connect with Slack',
    'integrations.instructions-text-1': `After signing in, go to the "Settings" Section in the sidebar and
    click on the "Organization" tab:`,
    'integrations.instructions-text-2': `On the Integrations apart you will see the button "Add to Slack":`,
    'integrations.instructions-text-3': `It will take you to the Slack website to authorize the connection and choose a workspace:`,
    'integrations.instructions-text-4': `Once authorized, you will be redirected to the Together platform
                                      and you see a notification that the connection was successful.`,
    'integrations.instructions-text-5': `Now you can invite users from your workspace to your Together
                                      organization and start communications through Slack channels with
                                      them.`,
    'integrations.instructions-unlink-title': 'Unlink workspace',
    'integrations.instructions-unlink-text-1': `If you want to unlink your workspace from Together, you can do it in
                                      the "Organization" tab of the "Settings" section. By clicking on the
                                      "Disconnect" button.`,
    'integrations.privacy-title': 'Privacy',
    'integrations.privacy-text-1': `To read more about how we collect and store data, check out our
                                      <a href="/privacy" class="text-blue-600 hover:text-blue-800">Privacy Policy</a>.`,
    'integrations.support-title': 'Support',
    'integrations.support-text-1': `If you have any questions or concerns, please contact us at
                                      <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>.`,

    // FAQ\
    'faq.title': 'Frequently Asked Questions',
    'faq.about-platform.title': 'About the Platform',
    'faq.about-platform.question-1': 'What is Together?',
    'faq.about-platform.answer-1':
      'Together is a platform designed to strengthen relationships within remote teams through collaborative activities and interactive experiences. Our goal is to replicate the social interactions of a physical office in a digital environment.',
    'faq.about-platform.question-2': 'Who can use Together?',
    'faq.about-platform.answer-2':
      'The platform is designed for remote teams of all sizes who want to improve communication, collaboration, and team bonding.',
    'faq.about-platform.question-3':
      'Do I need any special software to use Together?',
    'faq.about-platform.answer-3':
      'No, you just need a browser and internet connection. Some features may require third-party tools such as Slack or Miro.',

    'faq.registration-and-setup.title': 'Registration and Setup',
    'faq.registration-and-setup.question-1':
      'How do I sign up for the platform?',
    'faq.registration-and-setup.answer-1':
      'You can sign up by completing the form on our homepage. Once registered, you will receive a welcome email with detailed information and admin access.',
    'faq.registration-and-setup.question-2': 'Can I add other team members?',
    'faq.registration-and-setup.answer-2':
      "Yes, admins can invite hosts and users from the platform's dashboard.",

    'faq.experiences-and-activities.title': 'Experiences and Activities',
    'faq.experiences-and-activities.question-1':
      'What types of activities are available?',
    'faq.experiences-and-activities.answer-1':
      'We offer a variety of team-building activities, including interactive games, group dynamics, and collaboration exercises using tools like Slack and Miro.',
    'faq.experiences-and-activities.question-2':
      'Can I customize the activities?',
    'faq.experiences-and-activities.answer-2':
      'Some activities allow basic customization, such as selecting the number of participants or themes. You can check the description of each activity for more details.',

    'faq.plans-and-payments.title': 'Plans and Payments',
    'faq.plans-and-payments.question-1': 'What plans are available?',
    'faq.plans-and-payments.answer-1':
      'We offer monthly and annual plans designed for teams of various sizes. You can find details on our pricing page.',
    'faq.plans-and-payments.question-2': 'Can I change my plan at any time?',
    'faq.plans-and-payments.answer-2':
      'Yes, you can change your plan from the settings panel. Pricing adjustments will be calculated automatically (prorated, if applicable).',
    'faq.plans-and-payments.question-3': 'What happens if my payment fails?',
    'faq.plans-and-payments.answer-3':
      'You will receive a notification with instructions to update your payment details or retry the transaction.',

    'faq.integrations.title': 'Integrations',
    'faq.integrations.question-1': 'What tools does Together integrate with?',
    'faq.integrations.answer-1':
      'Currently, we integrate with Slack and Miro. We are working on adding more integrations in the future.',
    'faq.integrations.question-2':
      'Is it necessary to have a Slack account to use Togethe',
    'faq.integrations.answer-2':
      'No, if your company does not use Slack, notifications are sent by email.',
    'faq.integrations.question-3': 'Do I need a Miro account to use Together?',
    'faq.integrations.answer-3':
      'No, the boards are created automatically and you do not need to log in to Miro to use them.',

    'faq.support-and-assistance.title': 'Support and Assistance',
    'faq.support-and-assistance.question-1':
      'What should I do if I encounter issues with the platform?',
    'faq.support-and-assistance.answer-1':
      'You can contact us through the support form on our contact page or email us at <a href="emailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',

    'faq.security-and-privacy.title': 'Security and Privacy',
    'faq.security-and-privacy.question-1':
      "Can I delete my account or organization's data?",
    'faq.security-and-privacy.answer-1':
      'Yes, you can request account or data deletion by contacting our support team.',

    'faq.trial-and-demo.title': 'Free Trials and Demonstrations',
    'faq.trial-and-demo.question-1': 'Is there a free trial available?',
    'faq.trial-and-demo.answer-1':
      "Yes, we offer a free trial for new users to explore the platform's features.",
    'faq.trial-and-demo.question-2': 'Can I request a demonstration?',
    'faq.trial-and-demo.answer-2':
      ' Absolutely! Contact us at <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a> to schedule a personalized demonstration for your team.',

    // Support
    'support.title': 'Support',
    'support.text-1': `If you need help, have any questions, or just want to say hello, please don't hesitate to contact our dedicated support team at <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a>. We're here to help and want to ensure you have an amazing experience with our app. We'll do our best to respond promptly and help resolve any issues you may be facing.`,
    'support.text-2': `Please read our <a href="/privacy" class="text-blue-600 hover:text-blue-800">privacy policy</a> and our <a href="/terms" class="text-blue-600 hover:text-blue-800">terms of service</a> to learn more. You can execute your rights at any moment by contacting us.`,

    // Signup
    'signup.step-1': 'Info',
    'signup.step-2': 'Sign Up',
    'signup.button-back': 'Back',
    'signup.button-next': 'Next',
    'signup.button-submit': 'Submit',
    'signup.info-text-1': `Thanks for signing up for the trial of our platform! We're excited to have you with us.<br /><br />
                          After your trial period is approved, you'll receive your access credentials via email.`,
    'signup.info-text-2': `If you have any questions, please contact us at together@togetherexperiences.com.<br /><br />
                          Enjoy your trial!`,
    'signup.form-title': 'Sign Up',
    'signup.form-subtitle': `Complete the form below and let's get Together`,
    'signup.form-confirm': 'Try Together!',
    'signup.form-success':
      'Thank you for your submission. We will send your credentials once the submission is approved.',
    'signup.form-error': "We couldn't submit your request.",
    'signup.form-orgname-input-label': 'Your organization name',
    'signup.form-orgname-input-placeholder': 'Organization name',
    'signup.form-orgname-required': 'Organization name is required',
    'signup.form-orgname-min':
      'Organization name must be at least 2 characters long',
    'signup.form-orgname-max':
      'Organization name must be at most 25 characters long',
    'signup.form-name-required': 'Name is required',
    'signup.form-name-min': 'Name must be at least 2 characters long',
    'signup.form-name-max': 'Name must be at most 25 characters long',
    'signup.form-name-input-label': 'Name',
    'signup.form-name-input-placeholder': 'Name',
    'signup.form-lastname-required': 'Last name is required',
    'signup.form-lastname-min': 'Last name must be at least 2 characters long',
    'signup.form-lastname-max': 'Last name must be at most 25 characters long',
    'signup.form-lastname-input-label': 'Last name',
    'signup.form-lastname-input-placeholder': 'Last name',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'Email is required',
    'signup.form-email-invalid': 'Email is not valid',
    'signup.form-trial-session-participants-mails-invalid':
      'Emails are not valid or are duplicated',
    'signup.form-trial-session-participants-mails-repeated':
      'Invalid email list (Valid and no repeated mails separated by commas and no spaces)',

    'signup.form-trial-session-apply-trial-label':
      'I want to get a trial session scheduled after signing up',
    'signup.form-trial-session-trial-instructions':
      'Enter the emails of the people you want to invite to the trial session and the date you want to schedule it',
    'signup.form-trial-session-schedule-date-input-label': 'Date',
    'signup.form-trial-session-schedule-date-input-placeholder': 'Date',
    'signup.form-trial-date-required': 'Date is required',
    'signup.form-trial-date-not-past': 'Date must not be in the past',
    'signup.form-trial-mail-required': 'Emails are required',
    'signup.form-trial-session-participants-mails-input-label': 'Emails',
    'signup.form-trial-session-participants-mails-input-placeholder':
      'Not repeated emails (even the main organizer email) separated by commas and no spaces',
    'signup.form-accept-terms':
      "I agree to the <a href='/terms' class='text-blue-600 hover:text-blue-800'>Terms and Conditions</a> and <a href='/privacy' class='text-blue-600 hover:text-blue-800'>Privacy Policy</a>",
    'signup.form-terms-required': 'You must accept the terms and conditions',
    'signup.home-button': 'Go Home',

    // Login
    'login.title': 'Login',
    'login.home-button': 'Go Home',
    'login.form-title': 'Login',
    'login.form-subtitle': 'Connect with your team from anywhere in the world',
    'login.form-forgot-password': 'Forgot password?',
    'login.form-submit-button': 'Login',
    'login.form-no-account': "Don't have an account? ",
    'login.form-signup-link': 'Sign Up',
    'login.form-email-invalid': 'Email is not valid',
    'login.form-email-required': 'Email is required',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'Password is required',
    'login.form-password-input-label': 'Password',
    'login.form-password-input-placeholder': 'Password',
    'login.form-new-password-required': 'You need to create a new password',
    'login.form-success': 'Login successful!',
    'login.form-error': 'Failed to login, please try again',
    'login.form-user-not-active':
      'Your account is not active, please contact your administrator',

    // Login - New Password
    'login.new-password-form-title':
      'You have to set a new password for your account',
    'login.new-password-form-label': 'New password',
    'login.new-password-form-placeholder': 'Enter your new password',
    'login.new-password-form-repeat-placeholder': 'Repeat your new password',
    'login.new-password-form-submit-button': 'Confirm password',
    'login.new-password-form-required': 'Password is required',
    'login.new-password-form-min': 'Password must be at least 8 characters',
    'login.new-password-form-invalid':
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character',
    'login.new-password-form-mismatch': 'Passwords must match',
    'login.new-password-form-success': 'Password updated successfully',
    'login.new-password-form-error':
      'Error updating password, please try again',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Welcome',
    'dashboard.sidebar.tenant-selector': 'Current tenant:',
    'dashboard.sidebar.users': 'Users',
    'dashboard.sidebar.teams': 'Teams',
    'dashboard.sidebar.experiences': 'Experiences',
    'dashboard.sidebar.sessions': 'Sessions',
    'dashboard.sidebar.settings': 'Settings',
    'dashboard.sidebar.logout': 'Logout',

    // Dashboard - Users
    'dashboard.users.title': 'Users',
    'dashboard.users.subtitle': 'Your team',
    'dashboard.users.button': 'Add user',
    'dashboard.users.table-name': 'Name',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Role',
    'dashboard.users.table-status': 'Status',
    'dashboard.users.table-status-active': 'Active',
    'dashboard.users.table-status-inactive': 'Inactive',
    'dashboard.users.table-actions': 'Actions',
    'dashboard.users.table-actions-assign-role': 'Assign role',
    'dashboard.users.table-actions-resend-credentials': 'Resend credentials',
    'dashboard.users.table-actions-delete': 'Delete',
    'dashboard.users.table-actions-deactivate': 'Deactivate',
    'dashboard.users.table-actions-activate': 'Activate',
    'dashboard.users.show-inactive-users-label': 'Show inactive users',
    'dashboard.users.deactivate-user-success': 'User deactivated successfully',
    'dashboard.users.activate-user-success': 'User activated successfully',
    'dashboard.users.deactivate-user-error': 'Error deactivating user',
    'dashboard.users.activate-user-modal-title': 'Activate user',
    'dashboard.users.activate-user-modal-text':
      'Are you sure you want to activate the user with email {{email}}?',
    'dashboard.users.deactivate-user-modal-title': 'Deactivate user',
    'dashboard.users.deactivate-user-modal-text': `Are you sure you want to deactivate the user with email {{email}}?\nPlease note that deactivating the user will remove them from all teams. If the user is the host of a team, the team will be deleted.`,
    'dashboard.users.activate-user-error': 'Error activating user',
    'dashboard.users.table-no-users': 'No users found',
    'dashboard.users.assign-role-title': `Update {{name}}'s role`,
    'dashboard.users.assign-role-confirm': 'Confirm',
    'dashboard.users.assign-role-close': 'Close',
    'dashboard.users.assign-role-success': 'User role updated successfully!',
    'dashboard.users.assign-role-error': 'Error updating user role',
    'dashboard.users.invite-users-title': 'Invite Users',
    'dashboard.users.upgrade-plan-modal-title': 'Seats limit reached',
    'dashboard.users.upgrade-plan-modal-text':
      'You need to upgrade your plan to invite more users. Do you want to upgrade your plan now?',
    'dashboard.users.invite-users.description':
      'Select the users from your Slack workspace that you want to invite',
    'dashboard.users.invite-users.not-found': 'No workspace users found',
    'dashboard.users.invite-users.integration-required': `⚠ To invite users you need to install and allow permissions from Together App in your Slack workspace.<br /> ✋ Remember that you must be a <b> workspace administrator</b> in your company's Slack to proceed with the installation. <br /> 🚧 The Together app is in process of being approved by Slack team.`,
    'dashboard.users.invite-users.get-success':
      'Slack users fetched successfully!',
    'dashboard.users.invite-users.get-error': 'Error connecting with Slack',
    'dashboard.users.invite-users.invite-success': 'User invited successfully!',
    'dashboard.users.invite-users.email-invite-success':
      'Users invited successfully! an email with credentials has been sent to each user.-User invited successfully!, an email with credentials has been sent.',
    'dashboard.users.invite-users.invite-error': 'Error inviting user',
    'dashboard.users.invite-users.email-invitation-description':
      'Or you can invite them by email',
    'dashboard.users.invite-users.email-invitation-btn': 'Invite',
    'dashboard.users.invite-users.table-name': 'Full Name',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Options',
    'dashboard.users.invite-users.name-placeholder': 'Name',
    'dashboard.users.invite-users.last-name-placeholder': 'Last Name',
    'dashboard.users.invite-users.email-placeholder': 'Email',
    'dashboard.users.invite-users.table-no-users':
      'There are no users from your workspace available to invite',
    'dashboard.users.resend-credentials-success':
      'Credentials sent successfully!',
    'dashboard.users.resend-credentials-error': 'Error sending credentials',
    'dashboard.users.resend-credentials-modal-text': 'Resend credentials to ',
    'dashboard.users.delete-user-success': 'User deleted successfully!',
    'dashboard.users.delete-user-error': 'Error deleting user',
    'dashboard.users.delete-user-modal-title': 'Confirm',
    'dashboard.users.delete-user-modal-text':
      'Are you sure you want to delete the user with email ',
    'dashboard.users.invite-users.slack-not-integrated': `To integrate your Slack workspace, go to <a
                                                          class="text-blue-600 hover:text-blue-800"
                                                          href="/dashboard/settings/organization">Organization settings</a
                                                          >`,
    'dashboard.users.no-search-results': 'No users found matching your search',
    'dashboard.users.try-different-search':
      'Try different search terms or check your spelling',
    'dashboard.users.clear-search': 'Clear search',
    'dashboard.users.searching': 'Searching users...',
    'dashboard.users.filter-all-roles': 'All roles',
    'dashboard.users.filter-roles': 'Filter by roles',
    'dashboard.users.roles-selected': 'roles selected',
    'dashboard.users.clear-selection': 'Clear all',
    'dashboard.users.fetch-users-error': 'Error fetching users',

    // Dashboard - Teams
    'dashboard.teams.title': 'Teams',
    'dashboard.teams.table-name': 'Name',
    'dashboard.teams.table-host': 'Host',
    'dashboard.teams.table-members': 'Members',
    'dashboard.teams.table-monthly-sessions': 'Monthly session',
    'dashboard.teams.table-actions': 'Actions',
    'dashboard.teams.add-team-button': 'Add team',
    'dashboard.teams.table-actions-manage': 'Manage',
    'dashboard.teams.table-actions-delete': 'Delete',
    'dashboard.teams.delete-team-modal-title': 'Delete team',
    'dashboard.teams.delete-team-modal-text':
      'Are you sure you want to delete team',
    'dashboard.teams.create-team-modal.title': 'Create team',
    'dashboard.teams.create-team-modal.name-label': 'Name',
    'dashboard.teams.create-team-modal.language-label': 'Language',
    'dashboard.teams.create-team-modal.name-error': 'Name is required',
    'dashboard.teams.create-team-modal.name-placeholder': 'Team name',
    'dashboard.teams.create-team-modal.language-error': 'Language is required',
    'dashboard.teams.create-team-modal.monthly-sessions-tooltip':
      'Automatically schedules a session every month for the team. Adjustable in team settings. (Starter & Pro plans only)',
    'dashboard.teams.create-team-modal.language-english-option': 'English',
    'dashboard.teams.create-team-modal.language-spanish-option': 'Spanish',
    'dashboard.teams.create-team-modal.success-title':
      'Team created successfully!',
    'dashboard.teams.create-team-modal.success-message':
      'Team created successfully!',
    'dashboard.teams.create-team-modal.error-title': 'Error creating team',
    'dashboard.teams.create-team-modal.error-message': 'Error creating team',
    'dashboard.teams.create-team-modal.submit-button': 'Confirm',
    'dashboard.teams.create-team-modal.cancel-button': 'Cancel',
    'dashboard.teams.edit-team-modal.title': 'Edit team',
    'dashboard.teams.view.team-title': 'Team',
    'dashboard.teams.view.team-sessions-title': 'Team sessions',
    'dashboard.teams.view.update-session-modal.title': 'Update session',
    'dashboard.teams.view.update-session-modal.subtitle':
      'Modify the details of your session below',
    'dashboard.teams.view.update-session-modal.no-users': 'No users found',
    'dashboard.teams.view.update-session-modal.experiences-error':
      'Error fetching experiences',
    'dashboard.teams.view.update-session-modal.users-error':
      'Error fetching users',
    'dashboard.teams.view.update-session-modal.confirm': 'Update',
    'dashboard.teams.view.update-session-modal.success':
      'Session updated successfully!',
    'dashboard.teams.view.update-session-modal.error': 'Error updating session',
    'dashboard.teams.view.add-member-button': 'Member',
    'dashboard.teams.view.edit-team-button': 'Edit',
    'dashboard.teams.view.session-table-action-edit': 'Edit',
    'dashboard.teams.view.session-table-action-cancel': 'Cancel',
    'dashboard.teams.view.language-label': 'Language',
    'dashboard.teams.view.monthly-sessions-label': 'Monthly sessions',
    'dashboard.teams.view.monthly-sessions.enabled': 'Yes',
    'dashboard.teams.view.monthly-sessions.disabled': 'No',
    'dashboard.teams.view.edit-team-modal-title': 'Edit team',
    'dashboard.teams.create-team-modal.monthly-sessions-label':
      'Monthly session',
    'dashboard.teams.view.edit-team-modal.success':
      'Team updated successfully!',
    'dashboard.teams.view.edit-team-modal.error': 'Error updating team',
    'dashboard.teams.view.add-member-modal-title': 'Select new team members',
    'dashboard.teams.view.add-member-modal-cancel-button': 'Cancel',
    'dashboard.teams.view.add-member-modal-add-button': 'Add',
    'dashboard.teams.view.add-member-modal-error': 'Error adding member',
    'dashboard.teams.view.add-member-modal-success':
      'Member added successfully!',
    'dashboard.teams.view.member.table-no-members': 'No members found',
    'dashboard.teams.view.table-action-remove': 'Remove',
    'dashboard.teams.view.table-action-remove-tooltip':
      'Remove the member from the team',
    'dashboard.teams.view.remove-member-modal-text':
      'Are you sure you want to remove the member',
    'dashboard.teams.view.remove-member-modal-title': 'Remove member',
    'dashboard.teams.view.remove-member-modal-success':
      'Member removed successfully!',
    'dashboard.teams.view.remove-member-modal-error': 'Error removing member',
    'dashboard.teams.view.change-host-modal-success':
      'Host changed successfully!',
    'dashboard.teams.view.change-host-modal-error': 'Error changing host',
    'dashboard.teams.view.change-host-modal-title': 'Select new host',
    'dashboard.teams.view.change-host-modal-cancel-button': 'Cancel',
    'dashboard.teams.view.change-host-modal-change-button': 'Confirm',
    'dashboard.teams.table-actions-manage-tooltip': 'Manage team',
    'dashboard.teams.table-actions-delete-tooltip': 'Delete team',
    'dashboard.teams.no-teams-found': 'No teams found',
    'dashboard.teams.fetch-teams-error': 'Error fetching teams',
    'dashboard.teams.loading': 'Loading teams...',
    'dashboard.teams.team-deleted': 'Team deleted',
    'dashboard.teams.team-deleted-message':
      'The team has been deleted successfully',
    'dashboard.teams.delete-team-error': 'Error deleting team',
    'dashboard.teams.delete-team-error-message': 'Error deleting team',
    'dashboard.teams.view.edit-team-modal.success-title':
      'Team updated successfully!',
    'dashboard.teams.view.edit-team-modal.success-message':
      'Team updated successfully!',
    'dashboard.teams.view.edit-team-modal.error-title': 'Error updating team',
    'dashboard.teams.view.edit-team-modal.error-message': 'Error updating team',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiences',
    'dashboard.experiences.fetch-error': 'Something went wrong: {{error}}',
    'dashboard.experiences.block-user-error':
      'You must have a-plan to use this experience',
    'dashboard.experiences.monthly-experience': 'Experience of the month',
    'dashboard.experiences.details.min-participants': 'Suggested group size',
    'dashboard.experiences.details.res-download':
      'Download resource for this experience',
    'dashboard.experiences.details.instructions-title':
      'Instructions for the team',
    'dashboard.experiences.start-session-title': 'Who do you want to invite?',
    'dashboard.experiences.start-session-subtitle':
      'Select the users you want to invite',
    'dashboard.experiences.start-session-no-users':
      'No users found. Trying inviting them from your Slack workspace!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error at trying to get users.',
    'dashboard.experiences.start-session-success':
      'Session created successfully!',
    'dashboard.experiences.start-session-error': 'Error creating session',
    'dashboard.experiences.start-session-name-input-label': 'Session name',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Session name',
    'dashboard.experiences.start-session-name-input-error':
      'Session name can only contain letters and numbers',
    'dashboard.experiences.start-session-date-input-label': 'Date',
    'dashboard.experiences.start-session-confirm-button': 'Confirm',
    'dashboard.experiences.start-session-section-title': 'Start session',
    'dashboard.experiences.start-session-section-subtitle':
      'You can schedule this experience by adding your team members or inviting new members',
    'dashboard.experiences.start-session-section-button': 'Start experience',
    'dashboard.experiences.start-session-select-team-label': 'Select team',
    'dashboard.experiences.start-session-select-team-option-label': 'No team',
    'dashboard.experiences.feedback-title': 'Users review',
    'dashboard.experiences.no-feedback': 'No feedback yet',
    'dashboard.experiences.language-label': 'Experiences language: ',
    'dashboard.experiences.category': 'Category: ',
    'dashboard.experiences.no-experiences':
      'There are no experiences available',
    'dashboard.experiences.start-session-max-users-error':
      'Session cannot have more than 30 participants',
    'dashboard.experiences.start-session-min-users-error':
      'Session cannot have less than 2 participants',
    'dashboard.experiences.stars': 'stars',
    'dashboard.experiences.select-categories': 'Select categories',
    'dashboard.experiences.categories-selected': 'Categories selected',
    'dashboard.experiences.select-rating': 'Select rating',
    'dashboard.experiences.ratings-selected': 'Ratings selected',
    'dashboard.experiences.rating': 'Rating',
    'dashboard.experiences.select-language': 'Select language',
    'dashboard.experiences.language-selected': 'Language selected',
    'dashboard.experiences.details-title': 'Details',
    'dashboard.experiences.details.participants-tooltip':
      'Number of participants',
    'dashboard.experiences.details.category-tooltip': 'Category',
    'dashboard.experiences.details.rating-tooltip': 'Average rating',
    'dashboard.experiences.details.experience-of-the-month':
      'Experience of the month',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sessions',
    'dashboard.sessions.no-sessions': 'No sessions yet',
    'dashboard.sessions.finish-success': 'Session finished successfully!',
    'dashboard.sessions.cancel-success': 'Session canceled successfully!',
    'dashboard.sessions.finish-error': 'Failed to finish session',
    'dashboard.sessions.cancel-error': 'Failed to cancel session',
    'dashboard.sessions.finish-title': 'Finish session',
    'dashboard.sessions.cancel-title': 'Cancel session',
    'dashboard.sessions.feedback-copy-success':
      'Feedback link copied to clipboard',
    'dashboard.sessions.table-name': 'Name',
    'dashboard.sessions.table-host': 'Host',
    'dashboard.sessions.table-experience': 'Experience',
    'dashboard.sessions.table-participants': 'Participants & Reviews',
    'dashboard.sessions.table-date': 'Date',
    'dashboard.sessions.table-status': 'Status',
    'dashboard.sessions.table-actions': 'Actions',
    'dashboard.sessions.table-action-copy-feedback': 'Copy feedback link',
    'dashboard.sessions.table-action-send-feedback': 'Send feedback',
    'dashboard.sessions.table-action-finish': 'Finish session',
    'dashboard.sessions.table-action-board': 'Board',
    'dashboard.sessions.card-rating': 'Rating',
    'dashboard.sessions.card-comment': 'Comment',
    'dashboard.sessions.card-feedback-pending': 'Feedback pending',
    'dashboard.sessions.feedback-form-title':
      'Leave your feedback for the last session',
    'dashboard.sessions.feedback-form-rating': 'Rating:',
    'dashboard.sessions.feedback-form-comment-label': 'Comment',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Optional',
    'dashboard.sessions.feedback-form-submit': 'Submit',
    'dashboard.sessions.feedback-form-success': 'Feedback submitted',
    'dashboard.sessions.feedback-form-error': 'Error submitting feedback',
    'dashboard.sessions.loading': 'Loading sessions...',
    'dashboard.sessions.create-session': 'Start session',
    'dashboard.sessions.fetch-sessions-error': 'Error fetching sessions',
    'dashboard.sessions.modal-participants-title':
      'Participants for {{session}}',
    'dashboard.sessions.modal-participants-count': 'participants',
    'dashboard.sessions.modal-no-participants': 'No participants yet',
    'dashboard.sessions.modal-invite-participants':
      'Participants will appear here once they join the session',
    'dashboard.sessions.state.pending': 'Pending',
    'dashboard.sessions.state.finished': 'Finished',
    'dashboard.sessions.state.cancelled': 'Cancelled',
    'dashboard.sessions.filter-states': 'Filter by states',
    'dashboard.sessions.filter-all-states': 'All states',
    'dashboard.sessions.states-selected': 'states selected',
    'dashboard.sessions.clear-selection': 'Clear all',

    // Dashboard - Settings - Tabs
    'dashboard.settings.title': 'Settings',
    'dashboard.settings.tabs.general': 'General',
    'dashboard.settings.tabs.profile': 'Profile',
    'dashboard.settings.tabs.organization': 'Organization',
    'dashboard.settings.tabs.billing': 'Billing',

    // Dashboard - Settings - General
    'dashboard.settings.general.title': 'General',
    'dashboard.settings.general.description':
      'Configure your general settings.',
    'dashboard.settings.general.language.title': 'Language Settings',
    'dashboard.settings.general.language.description':
      'Choose your preferred language for the platform interface.',

    'dashboard.settings.general.guide.title': 'Platform Guide',
    'dashboard.settings.general.guide.description':
      'Need help navigating the platform? Take an interactive tour to learn about all the features.',
    'dashboard.settings.general.guide.tip':
      'The tour will guide you through the main features of Together, showing you how to make the most of the platform.',
    'dashboard.settings.general.guide.start-tour': 'Start Platform Tour',

    // Dashboard - Settings - Profile
    'dashboard.settings.profile.title': 'Profile',
    'dashboard.settings.profile.description': 'Profile settings',
    'dashboard.settings.profile.form-submit-btn': 'Save',
    'dashboard.settings.profile.form.submit-success':
      'Profile updated successfully!',
    'dashboard.settings.profile.form.submit-error': 'Error at updating profile',
    'dashboard.settings.profile.form.name-label': 'Name:',
    'dashboard.settings.profile.form-name-placeholder': 'Name',
    'dashboard.settings.profile.form.name-max':
      'Name must be less than 20 characters',
    'dashboard.settings.profile.form.last-name-label': 'Last name:',
    'dashboard.settings.profile.form.last-name-placeholder': 'Last name',
    'dashboard.settings.profile.form.last-name-max':
      'Last name must be less than 20 characters',
    'dashboard.settings.profile.form.avatar-label': 'Avatar:',
    'dashboard.settings.profile.form.avatar-max-size':
      'Image must be less than 2MB, try with a smaller image, or crop the current image.',
    'dashboard.settings.profile.form.avatar-compression-failed':
      'Image is too large even after compression. Please try a different image or crop the image to be smaller.',
    'dashboard.settings.profile.form.avatar-aspect-ratio':
      'Image must be square (1:1 aspect ratio)',
    'dashboard.settings.profile.form.avatar-type-error':
      'File must be an image',
    'dashboard.settings.profile.form.avatar-compress-error':
      'Error compressing image',
    'dashboard.settings.profile.form.avatar-process-error':
      'Error processing image',
    'dashboard.settings.profile.form.cancel-btn': 'Cancel',
    'dashboard.settings.profile.form.crop-btn': 'Crop',
    'dashboard.settings.profile.form.processing': 'Processing image...',
    'dashboard.settings.profile.form.avatar-needs-crop':
      'Please crop the image to a square format before submitting',
    'dashboard.settings.profile.form.avatar-crop-error':
      'Error cropping image. Please try again.',

    // Dashboard - Organization
    'dashboard.settings.organization.title': 'My organization',
    'dashboard.settings.organization.description':
      'Manage your organization settings and profile preferences.',
    'dashboard.settings.organization.form.title': 'Organization profile',
    'dashboard.settings.organization.form.submit-success':
      'Organization updated successfully!',
    'dashboard.settings.organization.form.submit-error':
      'Error updating organization',
    'dashboard.settings.organization.form-name-label': 'Organization name: ',
    'dashboard.settings.organization.form-name-placeholder':
      'Organization name',
    'dashboard.settings.organization.form.name-required':
      'Organization name is required',
    'dashboard.settings.organization.form.name-max':
      'Organization name must be less than 40 characters',
    'dashboard.settings.organization.form-submit-btn': 'Save',
    'dashboard.settings.organization.integrations.title': 'Integrations',
    'dashboard.settings.organization.integrations.slack-connected':
      'Your are connected to Slack to the following workspace:',
    'dashboard.settings.organization.integrations.slack-disconnect-btn':
      'Disconnect',
    'dashboard.settings.organization.integrations.slack-not-connected':
      'Connect to Slack',

    'dashboard.settings.organization.unlink-workspace-success':
      'Successfully disconnected from your workspace',
    'dashboard.settings.organization.unlink-workspace-error':
      'There was an error disconnecting from your workspace. Please try again.',

    // Dashboard - Billing
    'dashboard.settings.billing.title': 'Billing',
    'dashboard.settings.billing.description':
      'Select the plan that best fits your needs. Current seats: ',
    'dashboard.settings.billing.current-plan':
      'Your current plan is-it ends on-Manage your subscription-Here',
    'dashboard.settings.billing.choose-company-size': 'Choose company size',
    'dashboard.settings.billing.small-checkbox-label': 'Small, {{seats}} seats',
    'dashboard.settings.billing.medium-checkbox-label':
      'Medium, {{seats}} seats',
    'dashboard.settings.billing.large-checkbox-label': 'Large, {{seats}} seats',
    'dashboard.settings.billing.free-plan-card-title': 'Trial',
    'dashboard.settings.billing.free-plan-card-price-label': '/ month',
    'dashboard.settings.billing.free-plan-card-description-1':
      'Discover how our activities can transform collaboration in your team with a free experience for up to 3 teams. Perfect for a first look into the world of team building!',
    'dashboard.settings.billing.free-plan-card-btn-current': 'Current plan',
    'dashboard.settings.billing.free-plan-card-btn-set': 'Cancel current',
    'dashboard.settings.billing.starter-plan-card-title': 'Starter',
    'dashboard.settings.billing.starter-plan-card-price-label': '/ month',
    'dashboard.settings.billing.starter-plan-card-description-1':
      "Foster teamwork month by month with an exclusive experience designed for companies, with no limits on the number of teams. Each month brings a new challenge to enhance your team's unity and performance.",
    'dashboard.settings.billing.starter-plan-card-btn-current': 'Current plan',
    'dashboard.settings.billing.starter-plan-card-btn-update': 'Update',
    'dashboard.settings.billing.starter-plan-card-btn-set': 'Set to Starter',
    'dashboard.settings.billing.pro-plan-card-title': 'Pro',
    'dashboard.settings.billing.pro-plan-card-price-label': '/ month',
    'dashboard.settings.billing.pro-plan-card-description-1':
      'Transform your company culture with unlimited access to all our team building experiences. Designed for companies committed to excellence and continuous collaboration.',
    'dashboard.settings.billing.pro-plan-card-btn-current': 'Current plan',
    'dashboard.settings.billing.pro-plan-card-btn-update': 'Update',
    'dashboard.settings.billing.pro-plan-card-btn-set': 'Set to Pro',

    // Experience Card
    'experience.play-button': 'Play',
    'experience.view-board-button': 'View board',
    'experience.min-participants': 'Min 2 participants',

    // Forgot Password
    'forgot-password.title': 'Forgot password',
    'forgot-password.email-label': 'Email',
    'forgot-password.new-password-label': 'New password',
    'forgot-password.repeat-password-label': 'Repeat password',
    'forgot-password.code-label': 'Code',
    'forgot-password.btn-reset-password': 'Reset password',
    'forgot-password.btn-send-code': 'Send code',
    'forgot-password.reset-password-success': 'Password updated successfully',
    'forgot-password.reset-password-error':
      'Error recovering password, please try again',
    'forgot-password.send-code-success': 'Check your email for the code',
    'forgot-password.send-code-error': 'Error sending code, please try again',

    // Routes - Titles
    'login.route-title': 'Login',
    'signup.route-title': 'Signup',
    'forgot-password.route-title': 'Forgot password',
    'integrations.route-title': 'Integrations',
    'privacy.route-title': 'Privacy',
    'terms.route-title': 'Terms',
    'support.route-title': 'Support',
    'faq.route-title': 'FAQ',
    'session-feedback.route-title': 'Session Feedback',
    'dashboard.users.route-title': 'Users',
    'dashboard.teams.route-title': 'Teams',
    'dashboard.experiences.route-title': 'Experiences',
    'dashboard.sessions.route-title': 'Sessions',
    'dashboard.settings.profile.route-title': 'Settings - Profile',
    'dashboard.settings.organization.route-title': 'Settings - Organization',
    'dashboard.settings.billing.route-title': 'Settings - Billing',
    'dashboard.settings.general.route-title': 'Settings - General',
    'dashboard.mini-games.route-title': 'Mini Games',
    'dashboard.oauth.route-title': 'Connecting to Slack',

    // Misc
    'use-trial-session-button': 'Use trial session',
    'paginator.next': 'Next',
    'paginator.previous': 'Prev',
    'search.by-name': 'Name',
    'search.by-email': 'Email',
    'search.placeholder': 'Search',
    'search.by-host': 'Host',
    'search.by-experience': 'Experience',
    'confirmation.modal.title': 'Confirm',
    'confirmation.modal.text': 'Are you sure?',
    'confirmation.modal.error': 'Error confirming action',
    'confirmation.modal.confirm': 'Confirm',
    'confirmation.modal.close': 'Close',
    'locale.select': 'Language:',
    'session-status-finished': 'Finished',
    'session-status-pending': 'Pending',
    'session-status-canceled': 'Canceled',
    'oauth.connect-success': 'Slack connected successfully!',
    'oauth.connect-error': 'Error connecting to Slack',
    'table.row.common.actions': 'Actions',
    'common.close': 'Close',
    'common.view-more': 'View more',
    'common.view-less': 'View less',
    'common.clear': 'Clear',
    'dashboard.sidebar.menu': 'Menu',
    'dashboard.sidebar.options': 'Options',
    'dashboard.sidebar.sessions-left': 'Sessions left',
    'dashboard.sidebar.navigation': 'Main navigation',
    'dashboard.sidebar.main-menu': 'Main menu',
    'dashboard.sidebar.secondary-menu': 'Secondary menu',
    'dashboard.sidebar.home': 'Go to dashboard home',
    'dashboard.sidebar.trial-section': 'Free trial section',
    'dashboard.sidebar.trial-used': 'You have used all your trial sessions',
    'dashboard.sidebar.use-trial': 'Use free trial session',
    'dashboard.sidebar.users-aria': 'Navigate to users section',
    'dashboard.sidebar.teams-aria': 'Navigate to teams section',
    'dashboard.sidebar.experiences-aria': 'Navigate to experiences section',
    'dashboard.sidebar.sessions-aria': 'Navigate to sessions section',
    'dashboard.sidebar.settings-aria': 'Navigate to settings',
    'dashboard.sidebar.logout-aria': 'Log out from the platform',

    // Tour
    'tour.step.sidebar-users.title': 'Users',
    'tour.step.sidebar-users.description':
      'Here you can manage users in your organization.',
    'tour.step.users-table.title': 'Users Table',
    'tour.step.users-table.description':
      'Here you can see all users in your organization. You can manage their roles, status, and more.',
    'tour.step.users-invite.title': 'Invite Users',
    'tour.step.users-invite.description':
      'Click here to invite new users to your organization.',

    'tour.step.sidebar-teams.title': 'Teams',
    'tour.step.sidebar-teams.description':
      'Here you can manage your teams and members.',
    'tour.step.teams-table.title': 'Teams Overview',
    'tour.step.teams-table.description':
      'Here you can see all your teams and manage their members. Each team can participate in different Experiences together.',
    'tour.step.teams-create.title': 'Create Team',
    'tour.step.teams-create.description':
      'Click here to create a new team and start adding members to it.',

    'tour.step.sidebar-experiences.title': 'Experiences Catalog',
    'tour.step.sidebar-experiences.description':
      'Click here to explore our Experiences catalog.',
    'tour.step.experience-card.title': 'Experience Card',
    'tour.step.experience-card.description': 'This is an Experience card.',
    'tour.step.experience-start.title': 'Start Experience',
    'tour.step.experience-start.description':
      'Click here to view details or start this Experience.',
    'tour.step.experience-monthly.title': 'Monthly Experience',
    'tour.step.experience-monthly.description':
      'This is the featured experience of the month!',

    'tour.step.sidebar-sessions.title': 'Sessions',
    'tour.step.sidebar-sessions.description':
      'Here you can manage and view your sessions.',
    'tour.step.sessions-table.title': 'Sessions Overview',
    'tour.step.sessions-table.description':
      'Here you can see all your sessions. Each row shows important details like session name, host, scheduled date, and current status.',
    'tour.step.sessions-actions.title': 'Session Actions',
    'tour.step.sessions-actions.description':
      'Manage your sessions with these options. You can view participants, access the board, edit details, or mark sessions as finished.',

    'tour.step.sidebar-settings.title': 'Settings',
    'tour.step.sidebar-settings.description':
      'Click here to access platform settings and help guides.',
    'tour.step.settings-guide.title': 'Platform Guide',
    'tour.step.settings-guide.description':
      'You can always restart the tour from here if you need a refresher on platform features.',

    'tour.step.next': 'Next',
    'tour.step.previous': 'Previous',
    'tour.step.close': 'Close',

    // Privacy
    'privacy.title': 'Privacy Policy for Together',
    'privacy.introduction.title': '1. Introduction',
    'privacy.introduction.content':
      'Welcome to Together! This Privacy Policy outlines how Together ("we", "our", "us") collects, uses, and protects your information when you use the Together platform (the "Service"). By using our Service, you agree to the terms and conditions of this Privacy Policy.',

    'privacy.information.title': '2. Information We Collect',
    'privacy.information.intro':
      'We collect the following types of information:',
    'privacy.information.personal.title': '2.1 Personal Information',
    'privacy.information.personal.item1': 'Name',
    'privacy.information.personal.item2': 'Email address',
    'privacy.information.personal.item3': 'Password (encrypted)',
    'privacy.information.personal.item4': 'Team and organisation details',

    'privacy.information.usage.title': '2.2 Usage Data',
    'privacy.information.usage.item1': 'Activities conducted on the platform',
    'privacy.information.usage.item2':
      'Feedback provided, including star ratings and comments',
    'privacy.information.usage.item3':
      'Logs of user interactions with the Service',

    'privacy.usage.title': '3. How We Use Your Information',
    'privacy.usage.intro':
      'We use the collected information for the following purposes:',
    'privacy.usage.item1': 'To provide and maintain our Service',
    'privacy.usage.item2':
      'To process your registration and manage your account',
    'privacy.usage.item3':
      'To enhance communication and collaboration within remote teams',
    'privacy.usage.item4':
      'To improve our Service based on user feedback and activity',
    'privacy.usage.item5':
      'To communicate with you regarding updates, promotions, and other information relevant to your use of our Service',

    'privacy.sharing.title': '4. Information Sharing and Disclosure',
    'privacy.sharing.intro':
      'We do not sell or lease your personal information to third parties. However, we may share your information with:',
    'privacy.sharing.item1':
      'Service Providers: To help us operate our business and perform essential functions such as hosting and maintenance.',
    'privacy.sharing.item2':
      'Third-party integrations: Such as Slack, Miro, and Cognito, to enhance the functionality of our Service.',
    'privacy.sharing.item3':
      'Compliance with Laws: When required by law or to protect our rights, safety, and property.',

    'privacy.security.title': '5. Data Security',
    'privacy.security.content':
      'We implement industry-standard security measures to protect your information. This includes using encryption and secure protocols (e.g., HTTPS). However, no method of transmission over the Internet or electronic storage is completely secure.',
    'privacy.security.intro': 'To prevent any vulnerabilities we:',
    'privacy.security.item1':
      'Perform regular backups and have disaster recovery strategies in place to guarantee data integrity during unforeseen events.',
    'privacy.security.item2':
      'Conduct internal audits of our application to identify and address potential vulnerabilities.',
    'privacy.security.item3':
      "Limit access to personal information to authorised personnel and your organisation's members through role-based access controls, minimising possible risks.",
    'privacy.security.item4':
      'Utilise AWS Cognito for user authentication to enhance the security of stored passwords and reduce risks.',

    'privacy.retention.title': '6. Data Retention',
    'privacy.retention.content':
      'We retain your personal information (name, surname, email, and organisation name) for as long as necessary to fulfil the purposes described in this Privacy Policy, including providing our services and complying with legal requirements. If there is no ongoing legitimate business need to process your personal information, we will either delete or anonymize it. Currently, we do not have a periodic data deletion mechanism, but we are committed to reviewing our practices regularly. Users may contact us to request the deletion of their personal information, and we will accommodate such requests in accordance with applicable laws.',

    'privacy.rights.title': '7. Your Data Protection Rights',
    'privacy.rights.intro':
      'Depending on your location, you may have certain rights regarding your personal data, including:',
    'privacy.rights.item1': 'The right to access',
    'privacy.rights.item2': 'The right to rectification',
    'privacy.rights.item3': 'The right to erasure',
    'privacy.rights.item4': 'The right to restrict processing',
    'privacy.rights.item5': 'The right to object to processing',
    'privacy.rights.item6': 'The right to data portability',
    'privacy.rights.contact':
      'To exercise these rights, please contact us at <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>.',

    'privacy.compliance.title': '8. Compliance with Regulatory Requirements',
    'privacy.compliance.content':
      'We comply with key regulations such as GDPR and implement necessary measures to ensure ongoing compliance.',

    'privacy.changes.title': '9. Changes to This Privacy Policy',
    'privacy.changes.content':
      'We may update this Privacy Policy from time to time. Any changes will be posted on this page, and where appropriate, notified to you by email or through our Service.',

    'privacy.contact.title': '10. Contact Us',
    'privacy.contact.intro':
      'If you have any questions or concerns about this Privacy Policy, please contact us at:',
    'privacy.contact.email':
      'Email: <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',
    'privacy.contact.address':
      'Address: ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia (2000)',
    'privacy.contact.outro':
      'Thank you for trusting Together with your data. We are committed to protecting your privacy and providing a secure experience for all users.',

    // Terms
    'terms.title':
      'Terms and Conditions for Using the "Together" Slack Application',
    'terms.last-updated': 'Last Updated: 2024-09-17',
    'terms.welcome':
      'Welcome to Together. These Terms and Conditions ("Terms") govern the use of the Application, which operates within the Slack platform to facilitate communication and collaboration among teams. By installing and using the Application, you agree to these Terms.',

    'terms.acceptance.title': '1. Acceptance of the Terms',
    'terms.acceptance.content':
      'By accessing and using the Application, you confirm that you have the authority to accept these Terms on behalf of the organization or team you represent, and you agree to comply with them.',

    'terms.service.title': '2. Service Description',
    'terms.service.content':
      'The Application allows Together users to have a dedicated Slack communication channel for participants in each experience, aiming to facilitate organization and encourage communication among them. Functionalities may include, but are not limited to:',
    'terms.service.item1': 'Sending automated messages and reminders.',
    'terms.service.item2': 'Messages may include links to other applications.',
    'terms.service.item3': 'Messages may include attachments.',

    'terms.usage.title': '3. Use of the Application',
    'terms.usage.intro':
      'By using the Application, you agree to the following:',
    'terms.usage.item1':
      'You will not use the Application to send abusive, illegal messages, or messages that violate Slack policies.',
    'terms.usage.item2':
      'You will not interfere with the operation of the Application or attempt to disable or circumvent any of its security measures.',
    'terms.usage.item3':
      'You will respect the privacy and property rights of other users and third parties.',
    'terms.usage.item4':
      'The Application will not be used to send spam or make unsolicited communications to users not involved in the projects.',

    'terms.privacy.title': '4. Privacy and Security',
    'terms.privacy.content':
      'Together respects your privacy. All information collected will be managed in accordance with our <a href="/privacy" class="text-blue-600 hover:text-blue-800">Privacy Policy</a>.',

    'terms.responsibilities.title': '5. User Responsibilities',
    'terms.responsibilities.content':
      'You are responsible for ensuring the security of your Slack account and for actions taken through the Application using your account. You must comply with applicable local, national, and international laws when using the Application.',

    'terms.intellectual.title': '6. Intellectual Property',
    'terms.intellectual.content':
      'All rights, titles, and interests in the Application, including any associated content, software, and materials, are owned by Together or its licensors. You may not reproduce, distribute, modify, or create derivative works from the Application without prior written consent from Together.',

    'terms.liability.title': '7. Limitation of Liability',
    'terms.liability.content':
      'Together will not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use the Application. The Application is provided "as is," without any warranties of any kind.',

    'terms.modifications.title': '8. Modifications to the Terms',
    'terms.modifications.content':
      'We reserve the right to modify these Terms at any time. Modifications will be posted on this page, and continued use of the Application after any changes constitutes acceptance of such changes.',

    'terms.termination.title': '9. Termination',
    'terms.termination.content':
      'We may suspend or terminate your access to the Application at any time if you violate these Terms or if we decide to discontinue the Application. Termination will not affect rights and obligations accrued before the termination date.',

    'terms.contact.title': '10. Contact',
    'terms.contact.content':
      'If you have any questions or concerns about these Terms or the Application, you can contact us at: <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',
    'terms.contact.address':
      'ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia (2000)',

    // Accessibility - Dashboard Experience Details
    'dashboard.experiences.details.image-alt': 'Image for experience: {{name}}',
    'dashboard.experiences.details.instructions-aria-label':
      'Experience instructions',
    'dashboard.experiences.details.details-aria-label': 'Experience details',

    // Accessibility - Dashboard Experience View
    'dashboard.experiences.loading-experience': 'Loading experience details',
    'dashboard.experiences.back-to-list': 'Back to experiences list',
    'dashboard.experiences.main-content-aria-label':
      'Experience information and feedback',
    'dashboard.experiences.loading-feedback': 'Loading user feedback',

    // Accessibility - Feedback Section
    'dashboard.experiences.feedback-section-aria-label':
      'User feedback for this experience',
    'dashboard.experiences.feedback-pagination-aria-label':
      'Feedback pagination',
    'dashboard.experiences.feedback-previous-page': 'Previous page',
    'dashboard.experiences.feedback-next-page': 'Next page',
    'dashboard.experiences.feedback-page-button':
      'Page {{page}} of {{totalPages}}',

    // Accessibility - Feedback Card
    'components.feedback-card.rating-label': 'Rating: {{value}} stars',
    'components.feedback-card.comment-label': 'User feedback comment',
    'components.feedback-card.aria-label':
      'Feedback from {{name}}, rated {{rating}} stars, {{date}}',
  },
  es: {
    // Navbar
    'navbar.element-home': 'Inicio',
    'navbar.element-together': '¿Que es Together?',
    'navbar.element-catalogue': 'Catálogo',
    'navbar.element-aboutus': 'Nosotros',
    'navbar.element-integrations': 'Integraciones',
    'navbar.element-faq': 'FAQ',
    'navbar.element-dashboard': 'Dashboard',
    'navbar.element-logout': 'Cerrar sesión',
    'navbar.element-login': 'Iniciar sesión',
    'navbar.element-signup': 'Registrarse',

    // Home
    'home.title': 'Bring remote<br />teams closer',
    'home.subtitle': 'Connect with your team from anywhere in the world.',
    'home.cta-button': 'Prueba Together!',
    'home.info-title': '¿Que es Together?',
    'home.info-text-1': `Together es una plataforma que ofrece experiencias únicas que refuerzan el trabajo en equipo y fomentan relaciones significativas entre sus miembros, independientemente de la distancia física. 
      <br /><br />
      La integración con plataformas como Miro y Slack, que los equipos utilizan habitualmente, les permite sumergirse rápidamente en las actividades y conectarse. 
      <br /><br />
      Con nuestra plataforma, también cultivará un entorno de trabajo en el que cada miembro se sienta conectado y comprometido, reduciendo los sentimientos de aislamiento del trabajo remoto.
      <br />`,
    'home.info-subtitle': '¡Conecta tu equipo hoy!',
    'home.info-text-2': `No deje que la distancia física afecte a la conexión de su equipo.<br /> Descubra cómo Together puede llevar su colaboración remota al siguiente nivel. 
      <br /><br />
      Solicite una demostración gratuita y empiece a crear un equipo más fuerte, conectado y productivo.`,
    'home.catalogue-title': 'Nuestra Experiencias',
    'home.aboutus-title': 'Nosotros',
    'home.aboutus-text-1':
      'Together nace para responder a la necesidad de Bigger Tech para que sus 100 emplados se conecten en todo el mundo.',
    'home.aboutus-text-2':
      'Las experiencias que ofrece Together están diseñadas basándose en los principios de Agile y coaching para fomentar las conversaciones, la diversión y los momentos de reflexión entre los miembros del equipo.',
    'home.aboutus-text-3':
      'De la experiencia de usar Together en los equipos de Bigger Tech, resaltamos los siguientes beneficios: ',
    'home.aboutus-list-1':
      'Mayor conectividad entre los miembros de los equipos',
    'home.aboutus-list-2': 'Un ambiente de trabajo más positivo y motivante',
    'home.aboutus-list-3':
      'Mayor sensacion de pertenencia, sin importar la distancia',

    // Footer
    'footer.copyright': '© 2025 Together - Todos los derechos reservados',
    'footer.home': 'Inicio',
    'footer.login': 'Iniciar sesión',
    'footer.signup': 'Registrarse',
    'footer.terms': 'Términos',
    'footer.privacy': 'Privacidad',
    'footer.support': 'Soporte',
    'footer.faq': 'FAQ',

    // Integrations
    'integrations.claim-text-1': `La integración de Together con Slack te permite incorporar usuarios fácilmente, enviar recordatorios automáticamente y crear un canal dedicado para cada experiencia.`,
    'integrations.claim-text-2': `Después de registrarte en nuestra plataforma, podrás instalar la aplicación Together en tu espacio de trabajo de Slack e invitar a tu equipo.`,
    'integrations.claim-text-3': `<strong>Importante:</strong> La persona que se registre debe tener permisos de administrador en el espacio de trabajo de Slack de la empresa.`,
    'integrations.claim-text-4': `Si tienes alguna pregunta, escribinos a together@togetherexperiences.com`,
    'integrations.title': 'Integración con ',
    'integrations.instructions-title': 'Conectar con Slack',
    'integrations.instructions-text-1': `Después de iniciar sesión, ve a la sección "Configuración" en el menú lateral y
    haz clic en la pestaña "Organización":`,
    'integrations.instructions-text-2': `En la sección de integraciones verás el botón "Agregar a Slack":`,
    'integrations.instructions-text-3': `Te llevará al sitio web de Slack para autorizar la conexión y elegir un espacio de trabajo:`,
    'integrations.instructions-text-4': `Una vez autorizado, serás redirigido a la plataforma Together
                                      y verás una notificación de que la conexión fue exitosa.`,
    'integrations.instructions-text-5': `Ahora puedes invitar a usuarios de tu espacio de trabajo a tu organización Together
                                      y comenzar a comunicarte con ellos a través de canales de Slack.`,
    'integrations.instructions-unlink-title': 'Desvincular espacio de trabajo',
    'integrations.instructions-unlink-text-1': `Si deseas desvincular tu espacio de trabajo de Together, puedes hacerlo en
                                      la pestaña "Organización" de la sección "Configuración". Al hacer clic en el botón "Desconectar".`,
    'integrations.privacy-title': 'Privacidad',
    'integrations.privacy-text-1': `Para leer más sobre cómo recopilamos y almacenamos datos, consulta nuestra
                                      <a href="/privacy" class="text-blue-600 hover:text-blue-800">Política de privacidad</a>.`,
    'integrations.support-title': 'Soporte',
    'integrations.support-text-1': `Si tienes alguna pregunta o inquietud, por favor contáctanos en
                                      <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>.`,

    // FAQ
    'faq.title': 'Preguntas Frecuentes',
    'faq.about-platform.title': 'Acerca de la Plataforma',
    'faq.about-platform.question-1': '¿Qué es Together?',
    'faq.about-platform.answer-1':
      'Together es una plataforma diseñada para fortalecer las relaciones dentro de los equipos remotos a través de actividades colaborativas y experiencias interactivas. Nuestro objetivo es replicar las interacciones sociales de una oficina física en un entorno digital.',
    'faq.about-platform.question-2': '¿Qué idiomas soporta la plataforma?',
    'faq.about-platform.answer-2':
      'Actualmente, la plataforma está disponible en inglés y español.',
    'faq.about-platform.question-3':
      '¿Necesito algún software especial para usar Together?',
    'faq.about-platform.answer-3':
      'No, solo necesitas un navegador y conexión a internet. Algunas funciones pueden requerir herramientas de terceros como Slack o Miro.',
    'faq.registration-and-setup.title': 'Registro y Configuración',
    'faq.registration-and-setup.question-1':
      '¿Cómo me registro en la plataforma?',
    'faq.registration-and-setup.answer-1':
      'Puedes registrarte completando el formulario en nuestra página de inicio.',
    'faq.registration-and-setup.question-2':
      '¿Puedo agregar a otros miembros de mi equipo?',
    'faq.registration-and-setup.answer-2':
      'Sí, los administradores pueden invitar a hosts y usuarios desde el panel de control de la plataforma.',
    'faq.experiences-and-activities.title': 'Experiencias y Actividades',
    'faq.experiences-and-activities.question-1':
      '¿Qué tipo de actividades están disponibles?',
    'faq.experiences-and-activities.answer-1':
      'Ofrecemos una variedad de actividades de team-building que incluyen juegos interactivos, dinámicas en grupo y ejercicios de colaboración a través de herramientas como Slack y Miro.',
    'faq.experiences-and-activities.question-2':
      '¿Se pueden programar actividades con anticipación?',
    'faq.experiences-and-activities.answer-2':
      'Sí, los hosts pueden programar actividades para fechas y horarios específicos desde el panel de control.',
    'faq.plans-and-payments.title': 'Planes y Pagos',
    'faq.plans-and-payments.question-1': '¿Cuáles son los planes disponibles?',
    'faq.plans-and-payments.answer-1':
      'Ofrecemos planes mensuales y anuales diseñados para equipos de diferentes tamaños.',
    'faq.plans-and-payments.question-2':
      '¿Puedo cambiar de plan en cualquier momento?',
    'faq.plans-and-payments.answer-2':
      'Sí, puedes cambiar tu plan desde el panel de configuración.',
    'faq.plans-and-payments.question-3': '¿Qué pasa si falla mi pago?',
    'faq.plans-and-payments.answer-3':
      'Recibirás una notificación con instrucciones para actualizar tus datos de pago o volver a intentar la transacción.',
    'faq.integrations.title': 'Integraciones',
    'faq.integrations.question-1': '¿Con qué herramientas se integra Together?',
    'faq.integrations.answer-1':
      'Actualmente, nos integramos con Slack y Miro.',
    'faq.integrations.question-2':
      '¿Es necesario tener una cuenta en Slack para usar Together?',
    'faq.integrations.answer-2':
      'No, si tu empresa no utiliza Slack las notificaciones se envian por mail.',
    'faq.integrations.question-3':
      '¿Es necesario tener una cuenta en Miro para usar Together?',
    'faq.integrations.answer-3':
      'No, los tableros se crean de manera automatica y para utilizarlos no es necesario loguearse a Miro.',
    'faq.support-and-assistance.title': 'Soporte y Ayuda',
    'faq.support-and-assistance.question-1':
      '¿Qué hago si tengo problemas con la plataforma?',
    'faq.support-and-assistance.answer-1':
      'Puedes contactarnos a través del formulario de soporte en nuestra página de contacto o enviarnos un correo a <a href="emailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',
    'faq.security-and-privacy.title': 'Seguridad y Privacidad',
    'faq.security-and-privacy.question-1':
      '¿Puedo eliminar mi cuenta o los datos de mi organización?',
    'faq.security-and-privacy.answer-1':
      'Sí, puedes solicitar la eliminación de tu cuenta o datos poniéndote en contacto con nuestro equipo de soporte.',
    'faq.trial-and-demo.title': 'Pruebas y Demostraciones',
    'faq.trial-and-demo.question-1': '¿Hay una prueba gratuita disponible?',
    'faq.trial-and-demo.answer-1':
      'Sí, ofrecemos una prueba gratuita para que los nuevos usuarios exploren las funciones de la plataforma.',
    'faq.trial-and-demo.question-2': '¿Puedo solicitar una demostración?',
    'faq.trial-and-demo.answer-2':
      '¡Por supuesto! Contáctanos a <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a> para programar una demostración personalizada para tu equipo',

    // Support
    'support.title': 'Soporte',
    'support.text-1': `Si necesitas ayuda, tienes alguna pregunta o simplemente quieres saludar, no dudes en contactar a nuestro equipo de soporte dedicado en <a class="text-blue-600 hover:text-blue-800" href="mailto:together@togetherexperiences.com">together@togetherexperiences.com</a>. Estamos aquí para ayudarte y queremos asegurarnos de que tengas una experiencia increíble con nuestra aplicación. Haremos nuestro mejor esfuerzo para responder rápidamente y ayudar a resolver cualquier problema que puedas estar enfrentando.`,
    'support.text-2': `Por favor, lee nuestra <a href="/privacy" class="text-blue-600 hover:text-blue-800">política de privacidad</a> y nuestros <a href="/terms" class="text-blue-600 hover:text-blue-800">términos de servicio</a> para obtener más información. Puedes ejercer tus derechos en cualquier momento contactándonos.`,

    // Signup
    'signup.step-1': 'Información',
    'signup.step-2': 'Registrarse',
    'signup.button-back': 'Atras',
    'signup.button-next': 'Siguiente',
    'signup.button-submit': 'Confirmar',
    'signup.info-text-1': `Gracias por registrarte para probar nuestra plataforma. Estamos encantados de tenerte con nosotros<br /><br /.
                          Una vez aprobado tu periodo de prueba, recibirás tus credenciales de acceso por correo electrónico.`,
    'signup.info-text-2': `Si tiene alguna pregunta, póngase en contacto con nosotros en together@togetherexperiences.com.<br /><br />
                          ¡Disfrute de su prueba!`,
    'signup.form-title': 'Registro',
    'signup.form-subtitle': 'Complete el siguiente formulario para registrarse',
    'signup.form-confirm': 'Confirmar',
    'signup.form-success':
      'Gracias por tu solicitud. Te enviaremos tus credenciales una vez que la solicitud sea aprobada.',
    'signup.form-error': 'No pudimos enviar tu solicitud.',
    'signup.form-orgname-input-label': 'El nombre de tu organización',
    'signup.form-orgname-input-placeholder': 'Nombre de la organización',
    'signup.form-orgname-required':
      'El nombre de la organización es obligatorio',
    'signup.form-name-required': 'El nombre es obligatorio',
    'signup.form-name-input-label': 'Nombre',
    'signup.form-name-input-placeholder': 'Nombre',
    'signup.form-lastname-required': 'El apellido es obligatorio',
    'signup.form-lastname-input-label': 'Apellido',
    'signup.form-lastname-input-placeholder': 'Apellido',
    'signup.form-email-input-label': 'Email',
    'signup.form-email-input-placeholder': 'Email',
    'signup.form-email-required': 'El email es obligatorio',
    'signup.form-email-invalid': 'El email no es válido',
    'signup.form-trial-session-participants-mails-invalid':
      'Los emails no son validos o son duplicados',
    'signup.form-trial-session-schedule-date-input-label': 'Fecha',
    'signup.form-trial-session-schedule-date-input-placeholder': 'Fecha',
    'signup.form-trial-date-required': 'La fecha es obligatoria',
    'signup.form-trial-mail-required': 'Los emails son obligatorios',
    'signup.form-trial-session-participants-mails-input-label': 'Emails',
    'signup.form-accept-terms':
      'Acepto los <a href="/terms" class="text-blue-600 hover:text-blue-800">términos y condiciones</a> y la <a href="/privacy" class="text-blue-600 hover:text-blue-800">política de privacidad</a>',
    'signup.form-terms-required': 'Debe aceptar los términos y condiciones',
    'signup.home-button': 'Ir al inicio',
    'signup.form-orgname-min':
      'El nombre de la organización debe tener al menos 2 caracteres',
    'signup.form-orgname-max':
      'El nombre de la organización debe tener como máximo 25 caracteres',
    'signup.form-name-min': 'El nombre debe tener al menos 2 caracteres',
    'signup.form-name-max': 'El nombre debe tener como máximo 25 caracteres',
    'signup.form-lastname-min': 'El apellido debe tener al menos 2 caracteres',
    'signup.form-lastname-max':
      'El apellido debe tener como máximo 25 caracteres',
    'signup.form-trial-session-participants-mails-repeated':
      'Lista de correos inválida (Correos válidos y no repetidos separados por comas y sin espacios)',
    'signup.form-trial-session-apply-trial-label':
      'Quiero tener una sesión de prueba programada después de registrarme',
    'signup.form-trial-session-trial-instructions':
      'Ingrese los correos de las personas que desea invitar a la sesión de prueba y la fecha en que desea programarla',
    'signup.form-trial-date-not-past': 'La fecha no debe ser pasada',
    'signup.form-trial-session-participants-mails-input-placeholder':
      'Correos no repetidos (incluso el correo del organizador principal) separados por comas y sin espacios',

    // Login
    'login.title': 'Iniciar sesión',
    'login.home-button': 'Ir al inicio',
    'login.form-title': 'Iniciar sesión',
    'login.form-subtitle': 'Conecta con tu equipo en cualquier parte del mundo',
    'login.form-forgot-password': '¿Olvidaste tu contraseña?',
    'login.form-submit-button': 'Ingresar',
    'login.form-no-account': '¿No tienes una cuenta? ',
    'login.form-signup-link': 'Registrate',
    'login.form-email-invalid': 'El email no es válido',
    'login.form-email-required': 'El email es obligatorio',
    'login.form-email-input-placeholder': 'Email',
    'login.form-email-input-label': 'Email',
    'login.form-password-required': 'La contraseña es obligatoria',
    'login.form-password-input-label': 'Contraseña',
    'login.form-password-input-placeholder': 'Contraseña',
    'login.form-new-password-required': 'Necesita crear una nueva contraseña',
    'login.form-success': '¡Inicio de sesión exitoso!',
    'login.form-error':
      'No se pudo iniciar sesión, por favor inténtelo de nuevo',
    'login.form-user-not-active':
      'El usuario no se encuentra activo, por favor contacte al administrador',

    // Login - New Password
    'login.new-password-form-title':
      'Debe establecer una nueva contraseña para su cuenta',
    'login.new-password-form-label': 'Nueva contraseña',
    'login.new-password-form-placeholder': 'Ingrese su nueva contraseña',
    'login.new-password-form-repeat-placeholder': 'Repita su nueva contraseña',
    'login.new-password-form-submit-button': 'Confirmar contraseña',
    'login.new-password-form-required': 'La contraseña es obligatoria',
    'login.new-password-form-min':
      'La contraseña debe tener al menos 8 caracteres',
    'login.new-password-form-invalid':
      'La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula, 1 número, 1 carácter especial',
    'login.new-password-form-mismatch': 'Las contraseñas deben coincidir',
    'login.new-password-form-success': 'Contraseña actualizada con éxito',
    'login.new-password-form-error':
      'Error al actualizar la contraseña, por favor inténtelo de nuevo',

    // Dashboard - Sidebar
    'dashboard.sidebar.welcome': 'Bienvenido',
    'dashboard.sidebar.tenant-selector': 'Organizacion actual:',
    'dashboard.sidebar.users': 'Usuarios',
    'dashboard.sidebar.teams': 'Equipos',
    'dashboard.sidebar.experiences': 'Experiencias',
    'dashboard.sidebar.sessions': 'Sesiones',
    'dashboard.sidebar.settings': 'Ajustes',
    'dashboard.sidebar.logout': 'Salir',

    // Dashboard - Users
    'dashboard.users.title': 'Usuarios',
    'dashboard.users.subtitle': 'Tu equipo',
    'dashboard.users.button': 'Agregar usuario',
    'dashboard.users.table-name': 'Nombre',
    'dashboard.users.table-email': 'Email',
    'dashboard.users.table-role': 'Rol',
    'dashboard.users.table-status': 'Estado',
    'dashboard.users.table-status-active': 'Activo',
    'dashboard.users.table-status-inactive': 'Inactivo',
    'dashboard.users.table-actions': 'Opciones',
    'dashboard.users.table-actions-assign-role': 'Asignar rol',
    'dashboard.users.table-actions-resend-credentials': 'Reenviar credenciales',
    'dashboard.users.table-actions-delete': 'Eliminar',
    'dashboard.users.show-inactive-users-label': 'Mostrar usuarios inactivos',
    'dashboard.users.table-actions-activate': 'Activar',
    'dashboard.users.table-actions-deactivate': 'Desactivar',
    'dashboard.users.deactivate-user-success': 'Usuario desactivado con exito',
    'dashboard.users.activate-user-success': 'Usuario activado con exito',
    'dashboard.users.deactivate-user-error': 'Error al desactivar usuario',
    'dashboard.users.activate-user-error': 'Error al activar usuario',
    'dashboard.users.deactivate-user-modal-title': 'Desactivar usuario',
    'dashboard.users.deactivate-user-modal-text': `¿Estás seguro de que deseas desactivar al usuario con el email {{email}}? \nLos usuarios desactivados serán eliminados de los equipos. Si el usuario es host de algun equipo, el equipo será eliminados.`,
    'dashboard.users.activate-user-modal-title': 'Activar usuario',
    'dashboard.users.activate-user-modal-text':
      '¿Seguro que quieres activar al usuario con el email {{email}}?',
    'dashboard.users.table-no-users': 'No se encontraron usuarios',
    'dashboard.users.assign-role-title': 'Asignar rol a {{name}}',
    'dashboard.users.assign-role-confirm': 'Confirmar',
    'dashboard.users.assign-role-close': 'Cerrar',
    'dashboard.users.assign-role-success': 'Rol asignado con exito',
    'dashboard.users.assign-role-error': 'Error al asignar rol',
    'dashboard.users.invite-users-title': 'Invitar Usuarios',
    'dashboard.users.upgrade-plan-modal-title': 'Límite alcanzado',
    'dashboard.users.upgrade-plan-modal-text':
      'Para invitar nuevos usuarios debes actualizar tu plan. ¿Quieres actualizar ahora?',
    'dashboard.users.invite-users.description':
      'Selecciona a los usuarios de tu espacio de trabajo de Slack que deseas invitar',
    'dashboard.users.invite-users.not-found':
      'No se encontraron usuarios del espacio de trabajo',
    'dashboard.users.invite-users.integration-required': `⚠ Para invitar usuarios necesitas instalar y permitir permisos de la aplicación Together en tu espacio de trabajo de Slack.<br /> ✋ Recuerda que debes ser un <b> administrador del espacio de trabajo</b> en el Slack de tu empresa para proceder con la instalación. <br /> 🚧 La aplicación Together está en proceso de ser aprobada por el equipo de Slack.`,
    'dashboard.users.invite-users.get-success':
      '¡Usuarios de Slack obtenidos con éxito!',
    'dashboard.users.invite-users.get-error': 'Error al conectar con Slack',
    'dashboard.users.invite-users.invite-success':
      '¡Usuario invitado con éxito!',
    'dashboard.users.invite-users.email-invite-success':
      '¡Usuarios invitados con éxito! Se ha enviado un correo electrónico con las credenciales a cada usuario. - ¡Usuario invitado con éxito! Se ha enviado un correo electrónico con las credenciales.',
    'dashboard.users.invite-users.invite-error': 'Error al invitar al usuario',
    'dashboard.users.invite-users.email-invitation-description':
      'Puedes invitar mediante email',
    'dashboard.users.invite-users.email-invitation-btn': 'Invitar',
    'dashboard.users.invite-users.table-name': 'Nombre completo',
    'dashboard.users.invite-users.table-email': 'Email',
    'dashboard.users.invite-users.table-options': 'Opciones',
    'dashboard.users.invite-users.name-placeholder': 'Nombre',
    'dashboard.users.invite-users.last-name-placeholder': 'Apellido',
    'dashboard.users.invite-users.email-placeholder': 'Email',
    'dashboard.users.invite-users.table-no-users':
      'No hay ningun usuario para invitar',
    'dashboard.users.resend-credentials-success':
      'Credenciales reenviadas con éxito',
    'dashboard.users.resend-credentials-error': 'Error al enviar credenciales',
    'dashboard.users.resend-credentials-modal-text': 'Reenviar credenciales a ',
    'dashboard.users.delete-user-success': 'Usuario eliminado con éxito',
    'dashboard.users.delete-user-error': 'Error al eliminar usuario',
    'dashboard.users.delete-user-modal-title': 'Confirmar',
    'dashboard.users.delete-user-modal-text': 'Estas por eliminar al usuario ',
    'dashboard.users.invite-users.slack-not-integrated': `Para integrar con tu espacio de trabajo de Slack, puedes hacerlo en <a
                                                          class="text-blue-600 hover:text-blue-800"
                                                          href="/dashboard/settings/organization">Ajustes de la organización</a
                                                          >`,
    'dashboard.users.no-search-results':
      'No se encontraron usuarios que coincidan con tu búsqueda',
    'dashboard.users.try-different-search':
      'Intenta con otros términos de búsqueda o revisa la ortografía',
    'dashboard.users.clear-search': 'Limpiar búsqueda',
    'dashboard.users.searching': 'Buscando usuarios...',
    'dashboard.users.filter-all-roles': 'Todos los roles',
    'dashboard.users.filter-roles': 'Filtrar por roles',
    'dashboard.users.roles-selected': 'roles seleccionados',
    'dashboard.users.clear-selection': 'Limpiar todo',
    'dashboard.users.fetch-users-error': 'Error al obtener usuarios',

    // Dashboard - Teams
    'dashboard.teams.title': 'Equipos',
    'dashboard.teams.table-name': 'Nombre',
    'dashboard.teams.add-team-button': 'Agregar equipo',
    'dashboard.teams.table-host': 'Host',
    'dashboard.teams.table-members': 'Miembros',
    'dashboard.teams.table-monthly-sessions': 'Sesiones mensuales',
    'dashboard.teams.table-actions': 'Acciones',
    'dashboard.teams.table-actions-manage': 'Administrar',
    'dashboard.teams.table-actions-delete': 'Eliminar',
    'dashboard.teams.create-team-modal.title': 'Crear equipo',
    'dashboard.teams.create-team-modal.name-label': 'Nombre',
    'dashboard.teams.create-team-modal.name-placeholder': 'Nombre del equipo',
    'dashboard.teams.create-team-modal.language-label': 'Lenguaje',
    'dashboard.teams.create-team-modal.monthly-sessions-tooltip':
      'Programa automáticamente una sesión mensual para el equipo. Ajustable en la configuración del equipo. (Solo planes Starter y Pro)',
    'dashboard.teams.create-team-modal.name-error': 'Nombre requerido',
    'dashboard.teams.create-team-modal.language-error': 'Lenguaje requerido',
    'dashboard.teams.create-team-modal.language-english-option': 'Inglés',
    'dashboard.teams.create-team-modal.language-spanish-option': 'Español',
    'dashboard.teams.create-team-modal.success': 'Equipo creado con éxito!',
    'dashboard.teams.create-team-modal.error': 'Error al crear equipo',
    'dashboard.teams.delete-team-modal-title': 'Eliminar equipo',
    'dashboard.teams.delete-team-modal-text':
      '¿Seguro que quieres eliminar el equipo ',
    'dashboard.teams.create-team-modal.submit-button': 'Confirmar',
    'dashboard.teams.create-team-modal.cancel-button': 'Cancelar',
    'dashboard.teams.view.team-title': 'Equipo',
    'dashboard.teams.view.team-sessions-title': 'Sesiones del equipo',
    'dashboard.teams.view.update-session-modal.title': 'Actualizar sesión',
    'dashboard.teams.view.update-session-modal.subtitle':
      'Actualizar detalles de la sesión',
    'dashboard.teams.view.update-session-modal.no-users':
      'No se encontraron usuarios',
    'dashboard.teams.view.update-session-modal.experiences-error':
      'Error al obtener experiencias',
    'dashboard.teams.view.update-session-modal.users-error':
      'Error al obtener usuarios',
    'dashboard.teams.view.update-session-modal.confirm': 'Actualizar',
    'dashboard.teams.view.update-session-modal.success':
      'Sesión actualizada con éxito!',
    'dashboard.teams.view.update-session-modal.error':
      'Error al actualizar sesión',
    'dashboard.teams.view.add-member-button': 'Miembro',
    'dashboard.teams.view.edit-team-button': 'Editar',
    'dashboard.teams.view.session-table-action-edit': 'Editar',
    'dashboard.teams.view.session-table-action-cancel': 'Cancelar',
    'dashboard.teams.view.language-label': 'Lenguaje',
    'dashboard.teams.view.monthly-sessions-label': 'Sesión mensual',
    'dashboard.teams.view.monthly-sessions.enabled': 'Si',
    'dashboard.teams.view.monthly-sessions.disabled': 'No',
    'dashboard.teams.view.edit-team-modal-title': 'Editar equipo',
    'dashboard.teams.create-team-modal.monthly-sessions-label':
      'Sesión mensual',
    'dashboard.teams.view.edit-team-modal.success':
      'Equipo actualizado con éxito!',
    'dashboard.teams.view.edit-team-modal.error': 'Error al actualizar equipo',
    'dashboard.teams.view.add-member-modal-title':
      'Seleccionar nuevos miembros del equipo',
    'dashboard.teams.view.add-member-modal-cancel-button': 'Cancelar',
    'dashboard.teams.view.add-member-modal-add-button': 'Agregar',
    'dashboard.teams.view.add-member-modal-error': 'Error al agregar miembro',
    'dashboard.teams.view.add-member-modal-success':
      'Miembro/s agregado/s con éxito!',
    'dashboard.teams.view.table-action-remove': 'Eliminar',
    'dashboard.teams.view.table-action-remove-tooltip':
      'Elimina el miembro del equipo',
    'dashboard.teams.view.member.table-no-members':
      'No hay miembros en el equipo',
    'dashboard.teams.view.remove-member-modal-text':
      '¿Seguro que quieres quitar a ',
    'dashboard.teams.view.remove-member-modal-title': 'Eliminar miembro',
    'dashboard.teams.view.remove-member-modal-success':
      'Miembro eliminado con éxito!',
    'dashboard.teams.view.remove-member-modal-error':
      'Error al eliminar miembro',
    'dashboard.teams.view.change-host-modal-success':
      'Host cambiado con éxito!',
    'dashboard.teams.view.change-host-modal-error': 'Error al cambiar el host',
    'dashboard.teams.view.change-host-modal-title': 'Seleccionar nuevo host',
    'dashboard.teams.view.change-host-modal-cancel-button': 'Cancelar',
    'dashboard.teams.view.change-host-modal-change-button': 'Confirmar',
    'dashboard.teams.table-actions-manage-tooltip': 'Administrar equipo',
    'dashboard.teams.table-actions-delete-tooltip': 'Eliminar equipo',
    'dashboard.teams.no-teams-found': 'No se encontraron equipos',
    'dashboard.teams.fetch-teams-error': 'Error al obtener equipos',
    'dashboard.teams.loading': 'Cargando equipos...',
    'dashboard.teams.team-deleted': 'Equipo eliminado',
    'dashboard.teams.team-deleted-message':
      'El equipo ha sido eliminado con éxito',
    'dashboard.teams.delete-team-error': 'Error al eliminar equipo',
    'dashboard.teams.delete-team-error-message': 'Error al eliminar equipo',
    'dashboard.teams.create-team-modal.success-title':
      'Equipo creado con éxito!',
    'dashboard.teams.create-team-modal.success-message':
      'El equipo ha sido creado con éxito',
    'dashboard.teams.create-team-modal.error-title': 'Error al crear equipo',
    'dashboard.teams.create-team-modal.error-message': 'Error al crear equipo',
    'dashboard.teams.view.edit-team-modal.success-title':
      'Equipo actualizado con éxito!',
    'dashboard.teams.view.edit-team-modal.success-message':
      'El equipo ha sido actualizado con éxito',
    'dashboard.teams.view.edit-team-modal.error-title':
      'Error al actualizar equipo',
    'dashboard.teams.view.edit-team-modal.error-message':
      'Error al actualizar equipo',

    // Dashboard - Experiences
    'dashboard.experiences.title': 'Experiencias',
    'dashboard.experiences.fetch-error': 'Algo salió mal: {{error}}',
    'dashboard.experiences.block-user-error':
      'Debes tener-plan para usar esta experiencia',
    'dashboard.experiences.monthly-experience': 'Experiencia del mes',
    'dashboard.experiences.details.min-participants':
      'Tamaño de grupo sugerido',
    'dashboard.experiences.details.res-download':
      'Descargar recurso para esta experiencia',
    'dashboard.experiences.details.instructions-title':
      'Instrucciones para el equipo',
    'dashboard.experiences.start-session-title': '¿A quién quieres invitar?',
    'dashboard.experiences.start-session-subtitle':
      'Selecciona los usuarios que quieres invitar',
    'dashboard.experiences.start-session-no-users':
      'No se encontraron usuarios. ¡Intenta invitarlos desde tu espacio de trabajo de Slack!',
    'dashboard.experiences.start-session-fetch-users-error':
      'Error al intentar obtener usuarios.',
    'dashboard.experiences.start-session-success':
      '¡Sesión creada exitosamente!',
    'dashboard.experiences.start-session-error': 'Error al crear la sesión',
    'dashboard.experiences.start-session-name-input-label':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-placeholder':
      'Nombre de la sesión',
    'dashboard.experiences.start-session-name-input-error':
      'El nombre de la sesión solo puede contener letras y números',
    'dashboard.experiences.start-session-date-input-label': 'Fecha',
    'dashboard.experiences.start-session-confirm-button': 'Confirmar',
    'dashboard.experiences.start-session-section-title': 'Iniciar sesión',
    'dashboard.experiences.start-session-section-subtitle':
      'Puedes programar esta experiencia agregando a tus miembros del equipo o invitando a nuevos miembros',
    'dashboard.experiences.start-session-section-button': 'Iniciar experiencia',
    'dashboard.experiences.start-session-select-team-label':
      'Seleccionar equipo',
    'dashboard.experiences.start-session-select-team-option-label':
      'Sin equipo',
    'dashboard.experiences.feedback-title': 'Reseñas',
    'dashboard.experiences.no-feedback': 'No hay reseñas',
    'dashboard.experiences.language-label': 'Lenguaje de las experiencias: ',
    'dashboard.experiences.category': 'Categoría:',
    'dashboard.experiences.no-experiences': 'No hay experiencias disponibles',
    'dashboard.experiences.start-session-max-users-error':
      'La sesión no puede tener mas de 30 participantes',
    'dashboard.experiences.start-session-min-users-error':
      'La sesión no puede tener menos de 2 participantes',
    'dashboard.experiences.stars': 'estrellas',
    'dashboard.experiences.select-categories': 'Categorías',
    'dashboard.experiences.categories-selected': 'Categorías seleccionadas',
    'dashboard.experiences.select-rating': 'Calificación',
    'dashboard.experiences.ratings-selected': 'Calificaciones seleccionadas',
    'dashboard.experiences.rating': 'Calificación',
    'dashboard.experiences.select-language': 'Lenguaje',
    'dashboard.experiences.language-selected': 'Lenguaje seleccionado',
    'dashboard.experiences.details-title': 'Detalles',
    'dashboard.experiences.details.participants-tooltip':
      'Número de participantes',
    'dashboard.experiences.details.category-tooltip': 'Categoría',
    'dashboard.experiences.details.rating-tooltip': 'Calificación promedio',
    'dashboard.experiences.details.experience-of-the-month':
      'Experiencia del mes',

    // Dashboard - Settings - Tabs
    'dashboard.settings.title': 'Ajustes',
    'dashboard.settings.tabs.general': 'General',
    'dashboard.settings.tabs.profile': 'Perfil',
    'dashboard.settings.tabs.organization': 'Organización',
    'dashboard.settings.tabs.billing': 'Facturación',

    // Dashboard - Settings - General
    'dashboard.settings.general.title': 'General',
    'dashboard.settings.general.description':
      'Configuraciones generales de la aplicación',
    'dashboard.settings.general.language.title': 'Configuración de Idioma',
    'dashboard.settings.general.language.description':
      'Elige tu idioma preferido para la interfaz de la plataforma.',

    'dashboard.settings.general.guide.title': 'Guía de la Plataforma',
    'dashboard.settings.general.guide.description':
      '¿Necesitas ayuda para navegar por la plataforma? Toma un tour interactivo para conocer todas las funcionalidades.',
    'dashboard.settings.general.guide.tip':
      'El tour te guiará a través de las principales características de Together, mostrándote cómo aprovechar al máximo la plataforma.',
    'dashboard.settings.general.guide.start-tour':
      'Iniciar Tour de la Plataforma',

    // Dashboard - Settings - Profile
    'dashboard.settings.profile.title': 'Perfil',
    'dashboard.settings.profile.description': 'Configuraciones de tu perfil',
    'dashboard.settings.profile.form-submit-btn': 'Guardar',
    'dashboard.settings.profile.form.submit-success': 'Cambios guardados!',
    'dashboard.settings.profile.form.submit-error':
      'Error al guardar los cambios',
    'dashboard.settings.profile.form.name-label': 'Nombre:',
    'dashboard.settings.profile.form-name-placeholder': 'Nombre',
    'dashboard.settings.profile.form.name-max':
      'El nombre debe tener menos de 20 caracteres',
    'dashboard.settings.profile.form.last-name-label': 'Apellido:',
    'dashboard.settings.profile.form.last-name-placeholder': 'Apellido',
    'dashboard.settings.profile.form.last-name-max':
      'El apellido debe tener menos de 20 caracteres',
    'dashboard.settings.profile.form.avatar-label': 'Avatar:',
    'dashboard.settings.profile.form.avatar-max-size':
      'La imagen debe pesar menos de 2MB, intenta con una imagen mas pequeña, o recortando la imagen actual.',
    'dashboard.settings.profile.form.avatar-compression-failed':
      'La imagen es demasiado grande incluso después de la compresión. Por favor, intenta con otra imagen o recorta la imagen para que sea mas pequeña.',
    'dashboard.settings.profile.form.avatar-aspect-ratio':
      'La imagen debe ser cuadrada (relación de aspecto 1:1)',
    'dashboard.settings.profile.form.avatar-type-error':
      'El archivo debe ser una imagen',
    'dashboard.settings.profile.form.avatar-compress-error':
      'Error al comprimir la imagen',
    'dashboard.settings.profile.form.avatar-process-error':
      'Error al procesar la imagen',
    'dashboard.settings.profile.form.cancel-btn': 'Cancelar',
    'dashboard.settings.profile.form.crop-btn': 'Recortar',
    'dashboard.settings.profile.form.processing': 'Procesando imagen...',
    'dashboard.settings.profile.form.avatar-needs-crop':
      'Por favor recorta la imagen en formato cuadrado antes de enviar',
    'dashboard.settings.profile.form.avatar-crop-error':
      'Error al recortar la imagen. Por favor, inténtalo de nuevo.',

    // Dashboard - Organization
    'dashboard.settings.organization.title': 'Organización',
    'dashboard.settings.organization.description':
      'Configuraciones de la organización',
    'dashboard.settings.organization.form.submit-success': 'Cambios guardados!',
    'dashboard.settings.organization.form.submit-error':
      'Error al guardar los cambios',
    'dashboard.settings.organization.form-name-label':
      'Nombre de la organizacion:',
    'dashboard.settings.organization.form-name-placeholder':
      'Nombre de la organización',
    'dashboard.settings.organization.form.name-required':
      'El nombre de la organización es requerido',
    'dashboard.settings.organization.form.name-max':
      'El nombre de la organización debe tener menos de 40 caracteres',
    'dashboard.settings.organization.form-submit-btn': 'Guardar',
    'dashboard.settings.organization.form.title': 'Perfil de la organización',
    'dashboard.settings.organization.integrations.title': 'Integraciones',
    'dashboard.settings.organization.integrations.slack-connected':
      'Estás conectado a Slack en el siguiente espacio de trabajo:',
    'dashboard.settings.organization.integrations.slack-disconnect-btn':
      'Desconectar',
    'dashboard.settings.organization.integrations.slack-not-connected':
      'Conectar con Slack',
    'dashboard.settings.organization.unlink-workspace-success':
      'Desconexión exitosa del espacio de trabajo',
    'dashboard.settings.organization.unlink-workspace-error':
      'Hubo un error al desconectar del espacio de trabajo. Por favor, inténtalo de nuevo.',

    // Dashboard - Settings - Billing
    'dashboard.settings.billing.title': 'Facturación',
    'dashboard.settings.billing.description':
      'Selecciona tu plan de facturación. Usuarios actuales: ',
    'dashboard.settings.billing.current-plan':
      'Tu plan actual es-termina el dia-Administra tu suscripción-Aqui',
    'dashboard.settings.billing.choose-company-size':
      'Elegir el tamaño de la empresa',
    'dashboard.settings.billing.small-checkbox-label':
      'Pequeña, {{seats}} usuarios',
    'dashboard.settings.billing.medium-checkbox-label':
      'Mediana, {{seats}} usuarios',
    'dashboard.settings.billing.large-checkbox-label':
      'Grande, {{seats}} usuarios',
    'dashboard.settings.billing.free-plan-card-title': 'Prueba',
    'dashboard.settings.billing.free-plan-card-price-label': '/ mes',
    'dashboard.settings.billing.free-plan-card-description-1':
      'Descubre cómo nuestras dinámicas pueden transformar la colaboración en tu equipo con una experiencia gratuita para hasta 3 equipos. ¡Ideal para un primer vistazo al mundo del team building!',
    'dashboard.settings.billing.free-plan-card-btn-current': 'Plan actual',
    'dashboard.settings.billing.free-plan-card-btn-set': 'Cancelar plan',
    'dashboard.settings.billing.starter-plan-card-title': 'Starter',
    'dashboard.settings.billing.starter-plan-card-price-label': '/ mes',
    'dashboard.settings.billing.starter-plan-card-description-1':
      'Fomenta el trabajo en equipo mes a mes con una experiencia exclusiva diseñada para empresas, sin límites en la cantidad de equipos. Cada mes trae un nuevo desafío para potenciar la unión y el desempeño de tu equipo.',
    'dashboard.settings.billing.starter-plan-card-btn-current': 'Plan actual',
    'dashboard.settings.billing.starter-plan-card-btn-update': 'Actualizar',
    'dashboard.settings.billing.starter-plan-card-btn-set': 'Activar Starter',
    'dashboard.settings.billing.pro-plan-card-title': 'Pro',
    'dashboard.settings.billing.pro-plan-card-price-label': '/ mes',
    'dashboard.settings.billing.pro-plan-card-description-1':
      'Transforma tu cultura empresarial con acceso ilimitado a todas nuestras experiencias de team building. Diseñado para empresas comprometidas con la excelencia y la colaboración continua.',
    'dashboard.settings.billing.pro-plan-card-btn-current': 'Plan actual',
    'dashboard.settings.billing.pro-plan-card-btn-update': 'Actualizar',
    'dashboard.settings.billing.pro-plan-card-btn-set': 'Activar Pro',

    // Experience Card
    'experience.play-button': 'Iniciar',
    'experience.view-board-button': 'Ver tablero',
    'experience.min-participants': 'Mínimo 2 participantes',

    // Dashboard - Sessions
    'dashboard.sessions.title': 'Sesiones',
    'dashboard.sessions.no-sessions': 'No hay sesiones aún',
    'dashboard.sessions.finish-success': 'Sesión finalizada con éxito',
    'dashboard.sessions.cancel-success': 'Sesión cancelada con éxito',
    'dashboard.sessions.finish-error': 'Error al finalizar la sesión',
    'dashboard.sessions.cancel-error': 'Error al cancelar la sesión',
    'dashboard.sessions.finish-title': 'Finalizar sesión',
    'dashboard.sessions.cancel-title': 'Cancelar sesión',
    'dashboard.sessions.feedback-copy-success': 'Link de feedback copiado',
    'dashboard.sessions.table-name': 'Nombre',
    'dashboard.sessions.table-host': 'Anfitrión',
    'dashboard.sessions.table-experience': 'Experiencia',
    'dashboard.sessions.table-participants': 'Participantes y reseñas',
    'dashboard.sessions.table-date': 'Fecha',
    'dashboard.sessions.table-status': 'Estado',
    'dashboard.sessions.table-actions': 'Acciones',
    'dashboard.sessions.table-action-copy-feedback': 'Copiar link de reseña',
    'dashboard.sessions.table-action-send-feedback': 'Enviar reseña',
    'dashboard.sessions.table-action-board': 'Tablero',
    'dashboard.sessions.table-action-finish': 'Finalizar',
    'dashboard.sessions.card-rating': 'Calificación',
    'dashboard.sessions.card-comment': 'Comentario',
    'dashboard.sessions.card-feedback-pending': 'Reseña pendiente',
    'dashboard.sessions.feedback-form-title':
      'Deja tu reseña para la última sesión',
    'dashboard.sessions.feedback-form-rating': 'Calificación:',
    'dashboard.sessions.feedback-form-comment-label': 'Comentario',
    'dashboard.sessions.feedback-form-comment-placeholder': 'Opcional',
    'dashboard.sessions.feedback-form-submit': 'Enviar',
    'dashboard.sessions.feedback-form-success': 'Reseña enviada',
    'dashboard.sessions.feedback-form-error': 'Error al enviar reseña',
    'dashboard.sessions.loading': 'Cargando sesiones...',
    'dashboard.sessions.create-session': 'Iniciar sesión',
    'dashboard.sessions.fetch-sessions-error': 'Error al cargar sesiones',
    'dashboard.sessions.modal-participants-title':
      'Participantes de {{session}}',
    'dashboard.sessions.modal-participants-count': 'participantes',
    'dashboard.sessions.modal-no-participants': 'Aún no hay participantes',
    'dashboard.sessions.modal-invite-participants':
      'Los participantes aparecerán aquí una vez que se unan a la sesión',
    'dashboard.sessions.state.pending': 'Pendiente',
    'dashboard.sessions.state.finished': 'Finalizada',
    'dashboard.sessions.state.cancelled': 'Cancelada',
    'dashboard.sessions.filter-states': 'Filtrar por estados',
    'dashboard.sessions.filter-all-states': 'Todos los estados',
    'dashboard.sessions.states-selected': 'estados seleccionados',
    'dashboard.sessions.clear-selection': 'Limpiar selección',

    // Forgot Password
    'forgot-password.title': 'Recuperar contraseña',
    'forgot-password.email-label': 'Email',
    'forgot-password.new-password-label': 'Nueva contraseña',
    'forgot-password.repeat-password-label': 'Repetir contraseña',
    'forgot-password.code-label': 'Codigo',
    'forgot-password.btn-reset-password': 'Recuperar contraseña',
    'forgot-password.btn-send-code': 'Enviar codigo',
    'forgot-password.reset-password-success': 'Contraseña cambiada',
    'forgot-password.reset-password-error':
      'Error al cambiar la contraseña, por favor inténtalo de nuevo',
    'forgot-password.send-code-success': 'Codigo enviado',
    'forgot-password.send-code-error': 'Error al enviar el código',

    // Routes - Titles
    'login.route-title': 'Iniciar sesión',
    'signup.route-title': 'Registrarse',
    'forgot-password.route-title': 'Recuperar contraseña',
    'integrations.route-title': 'Integraciones',
    'privacy.route-title': 'Privacidad',
    'terms.route-title': 'Términos',
    'support.route-title': 'Soporte',
    'faq.route-title': 'Preguntas frecuentes',
    'session-feedback.route-title': 'Reseña de sesión',
    'dashboard.users.route-title': 'Usuarios',
    'dashboard.teams.route-title': 'Equipos',
    'dashboard.experiences.route-title': 'Experiencias',
    'dashboard.sessions.route-title': 'Sesiones',
    'dashboard.settings.profile.route-title': 'Ajustes - Perfil',
    'dashboard.settings.organization.route-title': 'Ajustes - Organización',
    'dashboard.settings.billing.route-title': 'Ajustes - Facturación',
    'dashboard.settings.general.route-title': 'Ajustes - General',
    'dashboard.mini-games.route-title': 'Mini Juegos',
    'dashboard.oauth.route-title': 'Conectando con Slack',

    // Misc
    'together.title': 'Together',
    'use-trial-session-button': 'Sesión de prueba',
    'paginator.next': 'Siguiente',
    'paginator.previous': 'Anterior',
    'search.by-name': 'Nombre',
    'search.by-email': 'Email',
    'search.by-host': 'Anfitrión',
    'search.by-experience': 'Experiencia',
    'search.placeholder': 'Buscar',
    'confirmation.modal.title': 'Confirmar',
    'confirmation.modal.text': '¿Estas seguro?',
    'confirmation.modal.error': 'Error al confirmar',
    'confirmation.modal.confirm': 'Confirmar',
    'confirmation.modal.close': 'Cerrar',
    'locale.select': 'Lenguaje:',
    'session-status-finished': 'Finalizada',
    'session-status-pending': 'Pendiente',
    'session-status-canceled': 'Cancelada',
    'oauth.connect-success': 'Conexion con Slack exitosa',
    'oauth.connect-error': 'Error al conectar con Slack',
    'table.row.common.actions': 'Acciones',
    'common.close': 'Cerrar',
    'common.view-more': 'Ver más',
    'common.view-less': 'Ver menos',
    'common.clear': 'Limpiar',
    'dashboard.sidebar.menu': 'Menú',
    'dashboard.sidebar.options': 'Opciones',
    'dashboard.sidebar.sessions-left': 'Sesiones restantes',
    'dashboard.sidebar.navigation': 'Navegación principal',
    'dashboard.sidebar.main-menu': 'Menú principal',
    'dashboard.sidebar.secondary-menu': 'Menú secundario',
    'dashboard.sidebar.home': 'Ir al panel principal',
    'dashboard.sidebar.trial-section': 'Sección de prueba gratuita',
    'dashboard.sidebar.trial-used':
      'Has utilizado todas tus sesiones de prueba',
    'dashboard.sidebar.use-trial': 'Usar sesión de prueba gratuita',
    'dashboard.sidebar.users-aria': 'Navegar a la sección de usuarios',
    'dashboard.sidebar.teams-aria': 'Navegar a la sección de equipos',
    'dashboard.sidebar.experiences-aria':
      'Navegar a la sección de experiencias',
    'dashboard.sidebar.sessions-aria': 'Navegar a la sección de sesiones',
    'dashboard.sidebar.settings-aria': 'Navegar a la configuración',
    'dashboard.sidebar.logout-aria': 'Cerrar sesión en la plataforma',

    // Tour
    'tour.step.sidebar-users.title': 'Usuarios',
    'tour.step.sidebar-users.description':
      'Aquí puedes gestionar los usuarios de tu organización.',
    'tour.step.users-table.title': 'Tabla de Usuarios',
    'tour.step.users-table.description':
      'Aquí puedes ver todos los usuarios de tu organización. Puedes gestionar sus roles, status, and more.',
    'tour.step.users-invite.title': 'Invitar Usuarios',
    'tour.step.users-invite.description':
      'Haz clic aquí para invitar nuevos usuarios a tu organización.',

    'tour.step.sidebar-teams.title': 'Equipos',
    'tour.step.sidebar-teams.description':
      'Aquí puedes gestionar tus equipos y miembros.',
    'tour.step.teams-table.title': 'Vista General de Equipos',
    'tour.step.teams-table.description':
      'Aquí puedes ver todos tus equipos y gestionar sus miembros. Cada equipo puede participar en diferentes Experiencias juntos.',
    'tour.step.teams-create.title': 'Crear Equipo',
    'tour.step.teams-create.description':
      'Haz clic aquí para crear un nuevo equipo y comenzar a agregar miembros.',

    'tour.step.sidebar-experiences.title': 'Catálogo de Experiencias',
    'tour.step.sidebar-experiences.description':
      'Haz clic aquí para explorar nuestro catálogo de Experiencias.',
    'tour.step.experience-card.title': 'Tarjeta de Experiencia',
    'tour.step.experience-card.description':
      'Esta es una tarjeta de Experiencia.',
    'tour.step.experience-start.title': 'Iniciar Experiencia',
    'tour.step.experience-start.description':
      'Haz clic aquí para ver detalles o iniciar esta Experiencia.',
    'tour.step.experience-monthly.title': 'Experiencia Mensual',
    'tour.step.experience-monthly.description':
      '¡Esta es la experiencia destacada del mes!',

    'tour.step.sidebar-sessions.title': 'Sesiones',
    'tour.step.sidebar-sessions.description':
      'Aquí puedes gestionar y ver tus sesiones.',
    'tour.step.sessions-table.title': 'Vista General de Sesiones',
    'tour.step.sessions-table.description':
      'Aquí puedes ver todas tus sesiones. Cada fila muestra detalles importantes como nombre de la sesión, host, fecha programada y estado actual.',
    'tour.step.sessions-actions.title': 'Acciones de Sesión',
    'tour.step.sessions-actions.description':
      'Gestiona tus sesiones con estas opciones. Puedes ver participantes, acceder al tablero, editar detalles o marcar sesiones como finalizadas.',

    'tour.step.sidebar-settings.title': 'Configuración',
    'tour.step.sidebar-settings.description':
      'Haz clic aquí para acceder a la configuración de la plataforma y guías de ayuda.',
    'tour.step.settings-guide.title': 'Guía de la Plataforma',
    'tour.step.settings-guide.description':
      'Siempre puedes reiniciar el tour desde aquí si necesitas repasar las funcionalidades de la plataforma.',

    'tour.step.next': 'Siguiente',
    'tour.step.previous': 'Anterior',
    'tour.step.close': 'Cerrar',

    // Privacy
    'privacy.title': 'Política de Privacidad de Together',
    'privacy.introduction.title': '1. Introducción',
    'privacy.introduction.content':
      'Bienvenido a Together. Esta Política de Privacidad describe cómo Together ("nosotros", "nuestro", "nos") recopila, utiliza y protege tu información cuando utilizas la plataforma Together (el "Servicio"). Al utilizar nuestro Servicio, aceptas los términos y condiciones de esta Política de Privacidad.',

    'privacy.information.title': '2. Información que Recopilamos',
    'privacy.information.intro':
      'Recopilamos los siguientes tipos de información:',
    'privacy.information.personal.title': '2.1 Información Personal',
    'privacy.information.personal.item1': 'Nombre',
    'privacy.information.personal.item2': 'Dirección de correo electrónico',
    'privacy.information.personal.item3': 'Contraseña (encriptada)',
    'privacy.information.personal.item4':
      'Detalles del equipo y la organización',

    'privacy.information.usage.title': '2.2 Datos de Uso',
    'privacy.information.usage.item1':
      'Actividades realizadas en la plataforma',
    'privacy.information.usage.item2':
      'Comentarios y valoraciones proporcionadas, incluyendo calificaciones con estrellas y comentarios',
    'privacy.information.usage.item3':
      'Registros de interacciones del usuario con el Servicio',

    'privacy.usage.title': '3. Cómo Utilizamos tu Información',
    'privacy.usage.intro':
      'Utilizamos la información recopilada para los siguientes propósitos:',
    'privacy.usage.item1': 'Para proporcionar y mantener nuestro Servicio',
    'privacy.usage.item2': 'Para procesar tu registro y gestionar tu cuenta',
    'privacy.usage.item3':
      'Para mejorar la comunicación y colaboración dentro de equipos remotos',
    'privacy.usage.item4':
      'Para mejorar nuestro Servicio basándonos en los comentarios y actividades de los usuarios',
    'privacy.usage.item5':
      'Para comunicarnos contigo sobre actualizaciones, promociones y otra información relevante relacionada con tu uso de nuestro Servicio',

    'privacy.sharing.title': '4. Compartir y Divulgar Información',
    'privacy.sharing.intro':
      'No vendemos ni alquilamos tu información personal a terceros. Sin embargo, podemos compartir tu información con:',
    'privacy.sharing.item1':
      'Proveedores de Servicios: Para ayudarnos a operar nuestro negocio y realizar funciones esenciales, como alojamiento y mantenimiento.',
    'privacy.sharing.item2':
      'Integraciones de terceros: Como Slack, Miro y Cognito, para mejorar la funcionalidad de nuestro Servicio.',
    'privacy.sharing.item3':
      'Cumplimiento de Leyes: Cuando sea requerido por la ley o para proteger nuestros derechos, seguridad y propiedad.',

    'privacy.security.title': '5. Seguridad de los Datos',
    'privacy.security.content':
      'Implementamos medidas de seguridad estándar de la industria para proteger tu información. Esto incluye el uso de encriptación y protocolos seguros (por ejemplo, HTTPS). Sin embargo, ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro.',
    'privacy.security.intro': 'Para prevenir vulnerabilidades:',
    'privacy.security.item1':
      'Realizamos copias de seguridad periódicas y tenemos estrategias de recuperación ante desastres para garantizar la integridad de los datos durante eventos imprevistos.',
    'privacy.security.item2':
      'Llevamos a cabo auditorías internas de nuestra aplicación para identificar y abordar posibles vulnerabilidades.',
    'privacy.security.item3':
      'Limitamos el acceso a la información personal a personal autorizado y miembros de tu organización mediante controles de acceso basados en roles, minimizando posibles riesgos.',
    'privacy.security.item4':
      'Utilizamos AWS Cognito para la autenticación de usuarios, mejorando la seguridad de las contraseñas almacenadas y reduciendo riesgos.',

    'privacy.retention.title': '6. Retención de Datos',
    'privacy.retention.content':
      'Retenemos tu información personal (nombre, apellido, correo electrónico y nombre de la organización) durante el tiempo necesario para cumplir con los propósitos descritos en esta Política de Privacidad, incluyendo la prestación de nuestros servicios y el cumplimiento de requisitos legales. Si no existe una necesidad comercial legítima en curso para procesar tu información personal, la eliminaremos o anonimizaremos. Actualmente, no tenemos un mecanismo de eliminación periódica de datos, pero nos comprometemos a revisar nuestras prácticas regularmente. Los usuarios pueden contactarnos para solicitar la eliminación de su información personal, y atenderemos dichas solicitudes de acuerdo con las leyes aplicables.',

    'privacy.rights.title': '7. Tus Derechos de Protección de Datos',
    'privacy.rights.intro':
      'Dependiendo de tu ubicación, puedes tener ciertos derechos sobre tus datos personales, incluyendo:',
    'privacy.rights.item1': 'El derecho de acceso',
    'privacy.rights.item2': 'El derecho de rectificación',
    'privacy.rights.item3': 'El derecho de supresión',
    'privacy.rights.item4': 'El derecho a limitar el procesamiento',
    'privacy.rights.item5': 'El derecho a oponerte al procesamiento',
    'privacy.rights.item6': 'El derecho a la portabilidad de los datos',
    'privacy.rights.contact':
      'Para ejercer estos derechos, por favor contáctanos en <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>.',

    'privacy.compliance.title': '8. Cumplimiento con Requisitos Regulatorios',
    'privacy.compliance.content':
      'Cumplimos con regulaciones clave como el GDPR e implementamos las medidas necesarias para garantizar el cumplimiento continuo.',

    'privacy.changes.title': '9. Cambios en esta Política de Privacidad',
    'privacy.changes.content':
      'Podemos actualizar esta Política de Privacidad de vez en cuando. Cualquier cambio será publicado en esta página y, cuando sea apropiado, se te notificará por correo electrónico o a través de nuestro Servicio.',

    'privacy.contact.title': '10. Contáctanos',
    'privacy.contact.intro':
      'Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad, por favor contáctanos en:',
    'privacy.contact.email':
      'Correo electrónico: <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',
    'privacy.contact.address':
      'Dirección: ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia (2000)',
    'privacy.contact.outro':
      'Gracias por confiar en Together con tus datos. Estamos comprometidos a proteger tu privacidad y ofrecer una experiencia segura para todos los usuarios.',

    // Terms
    'terms.title':
      'Términos y Condiciones para el Uso de la Aplicación "Together" en Slack',
    'terms.last-updated': 'Última actualización: 2024-09-17',
    'terms.welcome':
      'Bienvenido a Together. Estos Términos y Condiciones ("Términos") rigen el uso de la Aplicación, que opera dentro de la plataforma Slack para facilitar la comunicación y colaboración entre equipos. Al instalar y utilizar la Aplicación, aceptas estos Términos.',

    'terms.acceptance.title': '1. Aceptación de los Términos',
    'terms.acceptance.content':
      'Al acceder y utilizar la Aplicación, confirmas que tienes la autoridad para aceptar estos Términos en nombre de la organización o equipo que representas, y aceptas cumplirlos.',

    'terms.service.title': '2. Descripción del Servicio',
    'terms.service.content':
      'La Aplicación permite a los usuarios de Together tener un canal de comunicación dedicado en Slack para los participantes de cada experiencia, con el objetivo de facilitar la organización y fomentar la comunicación entre ellos. Las funcionalidades pueden incluir, entre otras:',
    'terms.service.item1': 'Envío de mensajes y recordatorios automatizados.',
    'terms.service.item2':
      'Los mensajes pueden incluir enlaces a otras aplicaciones.',
    'terms.service.item3': 'Los mensajes pueden incluir archivos adjuntos.',

    'terms.usage.title': '3. Uso de la Aplicación',
    'terms.usage.intro': 'Al utilizar la Aplicación, aceptas lo siguiente:',
    'terms.usage.item1':
      'No utilizarás la Aplicación para enviar mensajes abusivos, ilegales o que violen las políticas de Slack.',
    'terms.usage.item2':
      'No interferirás con el funcionamiento de la Aplicación ni intentarás desactivar o eludir cualquiera de sus medidas de seguridad.',
    'terms.usage.item3':
      'Respetarás la privacidad y los derechos de propiedad de otros usuarios y terceros.',
    'terms.usage.item4':
      'La Aplicación no se utilizará para enviar spam o realizar comunicaciones no solicitadas a usuarios no involucrados en los proyectos.',

    'terms.privacy.title': '4. Privacidad y Seguridad',
    'terms.privacy.content':
      'Together respeta tu privacidad. Toda la información recopilada se gestionará de acuerdo con nuestra <a href="/privacy" class="text-blue-600 hover:text-blue-800">Política de Privacidad</a>.',

    'terms.responsibilities.title': '5. Responsabilidades del Usuario',
    'terms.responsibilities.content':
      'Eres responsable de garantizar la seguridad de tu cuenta de Slack y de las acciones realizadas a través de la Aplicación utilizando tu cuenta. Debes cumplir con las leyes locales, nacionales e internacionales aplicables al utilizar la Aplicación.',

    'terms.intellectual.title': '6. Propiedad Intelectual',
    'terms.intellectual.content':
      'Todos los derechos, títulos e intereses sobre la Aplicación, incluido cualquier contenido, software y materiales asociados, son propiedad de Together o sus licenciantes. No podrás reproducir, distribuir, modificar o crear obras derivadas de la Aplicación sin el consentimiento previo por escrito de Together.',

    'terms.liability.title': '7. Limitación de Responsabilidad',
    'terms.liability.content':
      'Together no será responsable por ningún daño directo, indirecto, incidental, especial o consecuente derivado del uso o la incapacidad de usar la Aplicación. La Aplicación se proporciona "tal cual", sin garantías de ningún tipo.',

    'terms.modifications.title': '8. Modificaciones de los Términos',
    'terms.modifications.content':
      'Nos reservamos el derecho de modificar estos Términos en cualquier momento. Las modificaciones se publicarán en esta página, y el uso continuado de la Aplicación después de cualquier cambio constituye la aceptación de dichos cambios.',

    'terms.termination.title': '9. Terminación',
    'terms.termination.content':
      'Podemos suspender o terminar tu acceso a la Aplicación en cualquier momento si violas estos Términos o si decidimos discontinuar la Aplicación. La terminación no afectará los derechos y obligaciones acumulados antes de la fecha de terminación.',

    'terms.contact.title': '10. Contacto',
    'terms.contact.content':
      'Si tienes alguna pregunta o inquietud sobre estos Términos o la Aplicación, puedes contactarnos en: <a href="mailto:together@togetherexperiences.com" class="text-blue-600 hover:text-blue-800">together@togetherexperiences.com</a>',
    'terms.contact.address':
      'ScaleMote Pty Ltd, Suite 302, 13/15 Wentworth Ave, Australia (2000)',

    // Accessibility - Dashboard Experience Details
    'dashboard.experiences.details.image-alt':
      'Imagen de la experiencia: {{name}}',
    'dashboard.experiences.details.instructions-aria-label':
      'Instrucciones de la experiencia',
    'dashboard.experiences.details.details-aria-label':
      'Detalles de la experiencia',

    // Accessibility - Dashboard Experience View
    'dashboard.experiences.loading-experience':
      'Cargando detalles de la experiencia',
    'dashboard.experiences.back-to-list': 'Volver a la lista de experiencias',
    'dashboard.experiences.main-content-aria-label':
      'Información y comentarios de la experiencia',
    'dashboard.experiences.loading-feedback':
      'Cargando comentarios de usuarios',

    // Accessibility - Feedback Section
    'dashboard.experiences.feedback-section-aria-label':
      'Comentarios de usuarios para esta experiencia',
    'dashboard.experiences.feedback-pagination-aria-label':
      'Paginación de comentarios',
    'dashboard.experiences.feedback-previous-page': 'Página anterior',
    'dashboard.experiences.feedback-next-page': 'Página siguiente',
    'dashboard.experiences.feedback-page-button':
      'Página {{page}} de {{totalPages}}',

    // Accessibility - Feedback Card
    'components.feedback-card.rating-label':
      'Calificación: {{value}} estrellas',
    'components.feedback-card.comment-label': 'Comentario de retroalimentación',
    'components.feedback-card.aria-label':
      'Comentario de {{name}}, calificación {{rating}} estrellas, {{date}}',
  },
};

export default translations;
