<script lang="ts">
  export let active = false;
  export let direction: 'asc' | 'desc' | undefined = undefined;
</script>

<svg
  class="h-4 w-4 {active ? 'text-primary' : 'text-gray-400'}"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d={direction === 'desc'
      ? 'M19 9l-7 7-7-7'
      : direction === 'asc'
        ? 'M5 15l7-7 7 7'
        : 'M7 10l5-5 5 5M7 14l5 5 5-5'}
  />
</svg>
