<script lang="ts">
  import type { IWorkspaceUser } from '@/interface/IWorkspaceUser';
  import { t } from '@/locales/i18n';

  export let workspaceUsers: IWorkspaceUser[] = [];
  export let handleInviteSlackUser: (
    name: string,
    lastName: string,
    email: string,
    avatarUrl: string,
  ) => void;
</script>

<div class="h-64 w-full">
  <table class="mt-2 w-full border-gray-500 text-left text-sm text-gray-500">
    <thead class="bg-gray-200 text-xs uppercase text-gray-700">
      <tr>
        <th scope="col" class="px-6 py-3"
          >{$t('dashboard.users.invite-users.table-name')}</th
        >
        <th scope="col" class="px-6 py-3"
          >{$t('dashboard.users.invite-users.table-email')}</th
        >
        <th scope="col" class="px-6 py-3 text-end"
          >{$t('dashboard.users.invite-users.table-options')}</th
        >
      </tr>
    </thead>
  </table>
  <div class="h-48 overflow-y-auto">
    <table class="mt-2 w-full border-gray-500 text-left text-sm text-gray-500">
      <tbody>
        {#if workspaceUsers.length > 0}
          {#each workspaceUsers as user, index}
            <tr class="border-b bg-white">
              <td class="px-6 py-4" data-cy="slack-user-fullname-{index + 1}"
                >{user.name} {user.lastName}</td
              >
              <td class="px-6 py-4" data-cy="slack-user-email-{index + 1}"
                >{user.email}</td
              >
              <td class="flex items-center justify-center px-6 py-4">
                <button
                  class="rounded-full border border-purple-800 p-1 transition-colors hover:bg-purple-800 hover:text-white"
                  data-cy="invite-slack-user-button-{index + 1}"
                  on:click={() =>
                    handleInviteSlackUser(
                      user.name,
                      user.lastName || '_',
                      user.email,
                      user.avatarUrl || '',
                    )}
                >
                  +
                </button>
              </td>
            </tr>
          {/each}
        {:else}
          <tr>
            <td
              class="px-6 py-4 text-center"
              data-cy="invite-users-table-not-found-message"
              >{$t('dashboard.users.invite-users.table-no-users')}</td
            >
          </tr>
        {/if}
      </tbody>
    </table>
  </div>
</div>
