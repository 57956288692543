<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import StarIcon from '@/assets/svg/StarIcon.svelte';
  import type { ICategory } from '@/interface/ICategory';
  import type { IExperience } from '@/interface/IExperience';
  import { tooltip } from '@/lib/actions/tooltip';
  import Button from '@/lib/components/button/Button.svelte';
  import { CategoryIcon } from '@/lib/components/icons';
  import FileDownloadIcon from '@/lib/components/icons/FileDownloadIcon.svelte';
  import PlayIcon from '@/lib/components/icons/PlayIcon.svelte';
  import TrophyIcon from '@/lib/components/icons/TrophyIcon.svelte';
  import UserIcon from '@/lib/components/icons/UserIcon.svelte';
  import { locale, t } from '@/locales/i18n';

  export let experience: IExperience;
  export let showStartButton = false;
  export let isMonthlyExperience = false;

  const dispatch = createEventDispatcher();

  $: categoryName =
    typeof experience.category === 'object'
      ? ($locale === 'es'
          ? (experience.category as ICategory).spanishName
          : (experience.category as ICategory).englishName) || 'General'
      : experience.category || 'General';

  $: isCategoryNameTooLong = categoryName.length > 10;

  $: averageRating =
    experience.totalRaters > 0
      ? (experience.totalRatings / experience.totalRaters).toFixed(1)
      : 'N/A';

  $: experienceOfMonthText = $t(
    'dashboard.experiences.details.experience-of-the-month',
  );
  $: downloadResourceText = $t('dashboard.experiences.details.res-download');
  $: participantsTooltip = $t(
    'dashboard.experiences.details.participants-tooltip',
  );
  $: categoryTooltip = $t('dashboard.experiences.details.category-tooltip');
  $: ratingTooltip = $t('dashboard.experiences.details.rating-tooltip');
  $: imageAltText = $t('dashboard.experiences.details.image-alt', {
    name: experience.name,
  });
  $: instructionsAriaLabel = $t(
    'dashboard.experiences.details.instructions-aria-label',
  );
  $: detailsAriaLabel = $t('dashboard.experiences.details.details-aria-label');

  function handleStartSession() {
    dispatch('startSession');
  }
</script>

<div class="flex w-full flex-col">
  <div class="flex flex-col items-start gap-6 md:flex-row">
    <div class="md:w-auto">
      <div
        class="aspect-square h-auto w-full max-w-[200px] overflow-hidden rounded-lg shadow-sm"
        role="img"
        aria-label={imageAltText}
      >
        <img
          class="h-full w-full object-cover object-center"
          src={experience.imageUrl}
          alt={imageAltText}
        />
      </div>
    </div>

    <div
      class="flex h-full max-h-[200px] flex-col gap-2 px-4 md:w-[37.5%]"
      role="region"
      aria-labelledby="details-title"
    >
      <h3
        id="details-title"
        class="mb-2 border-b border-light-grey pb-2 text-lg font-bold text-primary"
        aria-label={detailsAriaLabel}
      >
        {$t('dashboard.experiences.details-title', { fallback: 'Detalles' })}
      </h3>

      <div class="mb-2 flex flex-row flex-wrap items-center gap-4" role="list">
        <div
          class="flex flex-row items-center gap-2 rounded-full bg-blue-50 px-3 py-1"
          use:tooltip={() => ({
            content: participantsTooltip,
            placement: 'bottom',
          })}
          role="listitem"
          aria-label={participantsTooltip}
        >
          <span aria-hidden="true">
            <UserIcon style="w-5 h-5 text-primary" />
          </span>
          <span class="font-semibold text-primary">
            {experience.minParticipants}
            {experience.maxParticipants
              ? `- ${experience.maxParticipants}`
              : ''}</span
          >
        </div>

        <div
          class="flex flex-row items-center gap-2 rounded-full bg-blue-50 px-3 py-1"
          use:tooltip={() =>
            isCategoryNameTooLong
              ? { content: categoryName, placement: 'bottom' }
              : { content: categoryTooltip, placement: 'bottom' }}
          role="listitem"
          aria-label={`${categoryTooltip}: ${categoryName}`}
        >
          <span aria-hidden="true">
            <CategoryIcon style="w-5 h-5 text-primary" />
          </span>
          <span class="font-semibold text-primary">
            {isCategoryNameTooLong
              ? categoryName.substring(0, 7) + '...'
              : categoryName}
          </span>
        </div>

        <div
          class="flex flex-row items-center gap-2 rounded-full bg-amber-50 px-3 py-1"
          use:tooltip={() => ({ content: ratingTooltip, placement: 'bottom' })}
          role="listitem"
          aria-label={`${ratingTooltip}: ${averageRating}`}
        >
          <span aria-hidden="true">
            <StarIcon style="w-5 h-5 fill-amber-500" />
          </span>
          <span class="font-semibold text-amber-500">
            {averageRating}
          </span>
        </div>

        {#if isMonthlyExperience}
          <div
            class="flex h-8 w-8 flex-row items-center justify-center rounded-full bg-amber-50 p-1"
            use:tooltip={() => ({
              content: experienceOfMonthText,
              placement: 'bottom',
            })}
            role="listitem"
            aria-label={experienceOfMonthText}
          >
            <span aria-hidden="true">
              <TrophyIcon style="w-5 h-5 fill-amber-500" />
            </span>
          </div>
        {/if}
      </div>

      <div class="max-h-24 overflow-y-auto pr-2">
        <p class="whitespace-pre-wrap break-words text-sm text-grey">
          {experience.description}
        </p>
      </div>

      {#if showStartButton}
        <div class="mt-4">
          <Button
            testId="start-session-button"
            rounded="rounded-md"
            on:click={handleStartSession}
          >
            <span class="flex items-center gap-2">
              <span aria-hidden="true">
                <PlayIcon style="w-4 h-4 fill-white" />
              </span>
              {$t('dashboard.experiences.start-session-section-button')}
            </span>
          </Button>
        </div>
      {/if}
    </div>

    <div
      class="flex h-full flex-col gap-2 md:w-[37.5%]"
      role="region"
      aria-labelledby="instructions-title"
    >
      <div
        class="mb-2 flex items-center justify-between border-b border-light-grey pb-2"
      >
        <h3
          id="instructions-title"
          class="text-lg font-bold text-primary"
          aria-label={instructionsAriaLabel}
        >
          {$t('dashboard.experiences.details.instructions-title')}
        </h3>
        {#if experience.documentUrl}
          <a
            data-cy="download-experience-document"
            target="_blank"
            href={experience.documentUrl}
            class="hover:text-primary-dark flex flex-row items-center gap-2 font-semibold text-primary"
            use:tooltip={() => ({
              content: downloadResourceText,
              placement: 'bottom',
            })}
            aria-label={downloadResourceText}
          >
            <span aria-hidden="true">
              <FileDownloadIcon
                style="w-5 h-5 fill-primary hover:fill-primary-dark"
              />
            </span>
          </a>
        {/if}
      </div>

      <div class="max-h-[150px] overflow-y-auto pr-2">
        <pre
          class="h-auto w-full overflow-x-hidden whitespace-pre-wrap break-words rounded-md bg-gray-50 p-3 text-sm text-grey shadow-inner"
          aria-label={instructionsAriaLabel}>{experience.instructions}</pre>
      </div>
    </div>
  </div>
</div>
