<script lang="ts">
  import type { ITeam } from '@/interface/ITeam';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import Table from '@/lib/components/tables/Table.svelte';
  import { getTeamMemberActions } from '@/lib/components/tables/teams/team-member-actions';
  import { getTeamMemberColumns } from '@/lib/components/tables/teams/team-member-columns';
  import { t } from '@/locales/i18n';

  import TeamViewTableTools from './TeamViewTableTools.svelte';

  export let team: ITeam;
  export let onRefresh: () => Promise<void>;

  $: columns = getTeamMemberColumns();
  $: actions = getTeamMemberActions(team.id, onRefresh);
</script>

<div
  class="flex w-full flex-col gap-4 rounded-lg border border-light-grey bg-white p-8"
>
  <TeamViewTableTools team={team} onRefresh={onRefresh} />

  {#if team.users.length > 0}
    <div class="flex h-full min-h-[400px] flex-col">
      <div class="w-full overflow-x-auto">
        <Table columns={columns} actions={actions} data={team.users} />
      </div>
    </div>
  {:else if team.users.length === 0}
    <div
      class="flex h-80 w-full grow items-center justify-center text-dark-grey"
    >
      {$t('dashboard.teams.view.member.table-no-members')}
    </div>
  {:else}
    <div class="flex h-80 w-full grow items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {/if}
</div>
