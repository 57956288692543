import tippy, { type Props } from 'tippy.js';

export const tooltip = (node: HTMLElement, params: () => Partial<Props>) => {
  const tip = tippy(node, params());
  return {
    update: (newParams: Partial<Props>) => {
      tip.setProps(newParams);
    },
    destroy: () => {
      tip.destroy();
    },
  };
};
