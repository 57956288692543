import { get } from 'svelte/store';
import type { ITeam } from '@/interface/ITeam';
import { UserIcon } from '@/lib/components/icons';
import {
  BadgeVariant,
  TemplateType,
  type Column,
  type TableItem,
} from '@/lib/components/tables/table.types';
import { t } from '@/locales/i18n';

export function getTeamColumns(): Column<TableItem>[] {
  const translate = get(t);

  const teamTableKeys = {
    name: 'name',
    host: 'host',
    members: 'members',
    monthlySession: 'monthlySession',
  };

  return [
    {
      key: teamTableKeys.host,
      label: translate('dashboard.teams.table-host'),
      width: 30,
      sortable: true,
      render: (item: TableItem) => {
        const team = item as ITeam;
        return {
          template: team.host.avatarUrl
            ? { type: TemplateType.AVATAR, url: team.host.avatarUrl }
            : { type: TemplateType.ICON, component: UserIcon },
          text: `${team.host.name} ${team.host.lastName}`,
        };
      },
    },
    {
      key: teamTableKeys.name,
      label: translate('dashboard.teams.table-name'),
      width: 30,
      sortable: true,
      render: (item: TableItem) => {
        const team = item as ITeam;
        return {
          text: team.name,
        };
      },
    },
    {
      key: teamTableKeys.members,
      label: translate('dashboard.teams.table-members'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const team = item as ITeam;
        return { text: team.users.length.toString() };
      },
    },
    {
      key: teamTableKeys.monthlySession,
      label: translate('dashboard.teams.table-monthly-sessions'),
      width: 15,
      sortable: false,
      render: (item: TableItem) => {
        const team = item as ITeam;
        const variant = team.monthlySession
          ? BadgeVariant.SUCCESS
          : BadgeVariant.INFO;
        return {
          template: {
            type: TemplateType.BADGE,
            variant,
          },
          text: team.monthlySession
            ? translate('dashboard.teams.view.monthly-sessions.enabled')
            : translate('dashboard.teams.view.monthly-sessions.disabled'),
        };
      },
    },
  ];
}
