<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>Together - {$t('terms.route-title')}</title>
</svelte:head>

<Page>
  <div class="mx-auto mb-8 max-w-2xl p-4 md:p-6 lg:p-8">
    <h1 class="mb-4 text-4xl font-bold text-primary" data-cy="terms-page-title">
      {$t('terms.title')}
    </h1>
    <p class="text-md mb-2">{$t('terms.last-updated')}</p>
    <p class="mb-10 text-lg">
      {$t('terms.welcome')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.acceptance.title')}</h2>
    <p class="mb-10 text-lg">
      {$t('terms.acceptance.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.service.title')}</h2>
    <p class="mb-4 text-lg">
      {$t('terms.service.content')}
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li class="mb-2">{$t('terms.service.item1')}</li>
      <li class="mb-2">{$t('terms.service.item2')}</li>
      <li class="mb-2">{$t('terms.service.item3')}</li>
    </ul>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.usage.title')}</h2>
    <p class="mb-4 text-lg">
      {$t('terms.usage.intro')}
    </p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li class="mb-2">{$t('terms.usage.item1')}</li>
      <li class="mb-2">{$t('terms.usage.item2')}</li>
      <li class="mb-2">{$t('terms.usage.item3')}</li>
      <li class="mb-2">{$t('terms.usage.item4')}</li>
    </ul>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.privacy.title')}</h2>
    <p class="mb-10 text-lg">
      {@html $t('terms.privacy.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">
      {$t('terms.responsibilities.title')}
    </h2>
    <p class="mb-10 text-lg">
      {$t('terms.responsibilities.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.intellectual.title')}</h2>
    <p class="mb-10 text-lg">
      {$t('terms.intellectual.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.liability.title')}</h2>
    <p class="mb-10 text-lg">
      {$t('terms.liability.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.modifications.title')}</h2>
    <p class="mb-10 text-lg">
      {$t('terms.modifications.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.termination.title')}</h2>
    <p class="mb-10 text-lg">
      {$t('terms.termination.content')}
    </p>

    <h2 class="mb-2 text-2xl font-bold">{$t('terms.contact.title')}</h2>
    <p class="mb-4 text-lg">
      {@html $t('terms.contact.content')}
    </p>

    <p class="mb-4 text-lg">
      {$t('terms.contact.address')}
    </p>
  </div>
</Page>
