<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';
  import clickOutside from '@/lib/components/helpers/clickOutside';
  import { handleKeyboardControls } from '@/lib/components/helpers/keyboardControls';
  import SearchInputArrow from '@/lib/components/icons/SearchInputArrow.svelte';
  import SearchInputClear from '@/lib/components/icons/SearchInputClear.svelte';
  import SearchInputIcon from '@/lib/components/icons/SearchInputIcon.svelte';
  import { t } from '@/locales/i18n';

  import Loading from '../loading/Loading.svelte';
  import type { SearchByOption, SearchEvent } from './search-input.types';

  export let searchByOptions: SearchByOption[] = [];
  export let isAsync = false;
  export let isLoading = false;
  export let value = '';

  let searchBy: string;
  if (searchByOptions.length > 0) {
    searchBy = searchByOptions[0]?.value || '';
  }

  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let showDropdown = false;

  const dispatch = createEventDispatcher<{
    search: SearchEvent;
  }>();

  const handleSearchByChange = (option: SearchByOption) => {
    searchBy = option.value;
    showDropdown = false;
    if (value) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (isAsync) {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch('search', {
          searchBy: searchBy,
          searchQuery: value,
        });
      }, 1500);
    } else {
      dispatch('search', { searchBy: searchBy, searchQuery: value });
    }
  };

  const clearSearch = () => {
    value = '';
    dispatch('search', { searchBy: searchBy, searchQuery: '' });
  };

  const toggleDropdown = () => {
    if (!isLoading) {
      showDropdown = !showDropdown;
    }
  };

  function handleKeyDown(event: KeyboardEvent) {
    handleKeyboardControls(event, {
      onEscape: () => (showDropdown = false),
      onEnter: toggleDropdown,
      onSpace: toggleDropdown,
    });
  }
</script>

<div class="relative mb-2 mt-1 flex w-full items-center justify-start">
  <div
    class="relative mr-2"
    use:clickOutside={() => (showDropdown = false)}
    role="listbox"
    tabindex="0"
  >
    <button
      type="button"
      class="flex h-10 w-[120px] items-center justify-between rounded border border-gray-300 px-4 text-base capitalize transition-all duration-100 hover:border-gray-400 focus:ring-2 focus:ring-primary {showDropdown
        ? 'border-gray-400 ring-1 ring-primary'
        : ''}"
      on:click={toggleDropdown}
      on:keydown={handleKeyDown}
      disabled={isLoading}
      data-cy="search-by-selector"
    >
      <span class="truncate">
        {searchByOptions.find((opt) => opt.value === searchBy)?.label ||
          searchBy}
      </span>
      <SearchInputArrow rotated={showDropdown} />
    </button>
    {#if showDropdown}
      <div
        class="absolute z-10 mt-1 w-full rounded-md border bg-white shadow-lg"
        transition:slide={{ duration: 200 }}
      >
        {#each searchByOptions as option (option.value)}
          <button
            type="button"
            class="w-full px-4 py-2 text-left text-base capitalize hover:bg-gray-100 {searchBy ===
            option.value
              ? 'bg-gray-50 font-medium'
              : ''}"
            on:click={() => handleSearchByChange(option)}
            data-cy={`search-by-${option.value}`}
          >
            {option.label}
          </button>
        {/each}
      </div>
    {/if}
  </div>
  <div class="relative w-full">
    <input
      type="text"
      placeholder={$t('search.placeholder')}
      class="w-full rounded-md border border-gray-300 p-2 pl-10 pr-8 text-base text-gray-700 transition-all duration-100 hover:border-gray-400 focus:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary"
      data-cy={isAsync ? 'search-input-async' : 'search-input'}
      bind:value={value}
      on:input={handleSearch}
    />
    {#if value}
      <button
        class="absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-400 hover:text-gray-600"
        on:click={clearSearch}
      >
        <SearchInputClear />
      </button>
    {/if}
    {#if isLoading}
      <div class="absolute right-8 top-1/2 -translate-y-1/2 transform">
        <Loading loadingSize="h-5 w-5" />
      </div>
    {/if}
    <div
      class="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-500"
    >
      <SearchInputIcon />
    </div>
  </div>
</div>
