import type { CropperOptions, ImageUploaderOptions } from './types/types';

export const DEFAULT_OPTIONS: ImageUploaderOptions = {
  maxSizeMB: 2,
  maxWidth: 1024,
  maxHeight: 1024,
  quality: 0.8,
  aspectRatio: 1,
};

export const DEFAULT_CROPPER_OPTIONS: CropperOptions = {
  aspectRatio: 1,
  viewMode: 1 as const,
  dragMode: 'move',
  autoCropArea: 1,
  restore: false,
  modal: true,
  guides: true,
  highlight: false,
  cropBoxMovable: true,
  cropBoxResizable: true,
  toggleDragModeOnDblclick: false,
};

export const PREVIEW_DIMENSIONS = {
  width: 24,
  height: 20,
};

export const DOM_UPDATE_DELAY = 100;

export const IMAGE_VALIDATION = {
  MAX_FILE_SIZE: 2 * 1024 * 1024, // 2MB
  DIMENSIONS: {
    width: 1024,
    height: 1024,
  },
  QUALITY: 0.8,
};
