<script lang="ts">
  import { t } from '@/locales/i18n';
</script>

<div
  class="fixed inset-0 z-[300] flex items-center justify-center bg-black/50"
  data-cy="loader-modal"
>
  <div class="rounded-lg bg-white p-8">
    <div class="flex flex-col items-center gap-4">
      <div
        class="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent"
      />
      <p>{$t('dashboard.settings.profile.form.processing')}</p>
    </div>
  </div>
</div>
