import { get } from 'svelte/store';
import type { IUser } from '@/interface/IUser';
import { UserIcon } from '@/lib/components/icons';
import {
  BadgeVariant,
  TemplateType,
  type Column,
  type TableItem,
} from '@/lib/components/tables/table.types';
import { t } from '@/locales/i18n';

export function getUserColumns(): Column<TableItem>[] {
  const translate = get(t);

  const userTableKeys = {
    name: 'name',
    email: 'email',
    role: 'role',
    isActive: 'isActive',
  };

  return [
    {
      key: userTableKeys.name,
      label: translate('dashboard.users.table-name'),
      width: 30,
      sortable: true,
      render: (item: TableItem) => {
        const user = item as IUser;
        return {
          template: user.avatarUrl
            ? { type: TemplateType.AVATAR, url: user.avatarUrl }
            : { type: TemplateType.ICON, component: UserIcon },
          text: `${user.name} ${user.lastName}`,
        };
      },
    },
    {
      key: userTableKeys.email,
      label: translate('dashboard.users.table-email'),
      width: 25,
      sortable: true,
      render: (item: TableItem) => {
        const user = item as IUser;
        return { text: user.email };
      },
    },
    {
      key: userTableKeys.role,
      label: translate('dashboard.users.table-role'),
      width: 15,
      sortable: true,
      render: (item: TableItem) => {
        const user = item as IUser;
        return { text: user.role };
      },
    },
    {
      key: userTableKeys.isActive,
      label: translate('dashboard.users.table-status'),
      width: 10,
      sortable: true,
      render: (item: TableItem) => {
        const user = item as IUser;
        const variant = user.isActive
          ? BadgeVariant.SUCCESS
          : BadgeVariant.ERROR;
        return {
          template: {
            type: TemplateType.BADGE,
            variant,
          },
          text: user.isActive
            ? translate('dashboard.users.table-status-active')
            : translate('dashboard.users.table-status-inactive'),
        };
      },
    },
  ];
}
