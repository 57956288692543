import moment from 'moment';
import { get } from 'svelte/store';
import { SessionStatus, type ISession } from '@/interface/ISession';
import {
  BadgeVariant,
  TemplateType,
  type Column,
  type TableItem,
} from '@/lib/components/tables/table.types';
import { t } from '@/locales/i18n';

export function getSessionColumns(): Column<TableItem>[] {
  const translate = get(t);

  const sessionTableKeys = {
    host: 'host',
    name: 'name',
    experience: 'experience',
    date: 'date',
    status: 'status',
  };

  return [
    {
      key: sessionTableKeys.host,
      label: translate('dashboard.sessions.table-host'),
      width: 20,
      sortable: true,
      render: (item: TableItem) => {
        const session = item as ISession;
        return {
          template: {
            type: TemplateType.AVATAR,
            url: session.host.avatarUrl,
          },
          text: `${session.host.name} ${session.host.lastName}`,
        };
      },
    },
    {
      key: sessionTableKeys.name,
      label: translate('dashboard.sessions.table-name'),
      width: 20,
      sortable: true,
      render: (item: TableItem) => {
        const session = item as ISession;
        return { text: session.name };
      },
    },
    {
      key: sessionTableKeys.experience,
      label: translate('dashboard.sessions.table-experience'),
      width: 20,
      sortable: true,
      render: (item: TableItem) => {
        const session = item as ISession;
        return { text: session.experience.name };
      },
    },
    {
      key: sessionTableKeys.date,
      label: translate('dashboard.sessions.table-date'),
      width: 10,
      sortable: true,
      render: (item: TableItem) => {
        const session = item as ISession;
        return {
          text: moment(session.scheduledDate.slice(0, 10)).format('DD MMM YY'),
        };
      },
    },
    {
      key: sessionTableKeys.status,
      label: translate('dashboard.sessions.table-status'),
      width: 10,
      sortable: true,
      render: (item: TableItem) => {
        const session = item as ISession;
        const status = session.status;
        let variant = BadgeVariant.WARNING;

        switch (status) {
          case SessionStatus.FINISHED:
            variant = BadgeVariant.SUCCESS;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-finished'),
            };
          case SessionStatus.CANCELED:
            variant = BadgeVariant.ERROR;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-canceled'),
            };
          case SessionStatus.PENDING:
          default:
            variant = BadgeVariant.WARNING;
            return {
              template: {
                type: TemplateType.BADGE,
                variant,
              },
              text: translate('session-status-pending'),
            };
        }
      },
    },
  ];
}
