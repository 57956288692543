<script lang="ts">
  import type { ITeam } from '@/interface/ITeam';
  import type { IUser } from '@/interface/IUser';
  import Button from '@/lib/components/button/Button.svelte';
  import InviteUserList from '@/lib/components/lists/InviteUserList.svelte';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { closeModal } from '@/lib/components/modal/modal.store';
  import type {
    SearchByOption,
    SearchEvent,
  } from '@/lib/components/search-input/search-input.types';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import {
    addTeamMembers,
    getActiveTenantUsers,
  } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  export let team: ITeam;
  export let onSubmit: (() => void) | undefined = undefined;
  let tenantUsers: IUser[] = [];
  let filteredUsers: IUser[] = [];
  let selectedUserIds = new Set<IUser['id']>();

  const getUsers = async () => {
    try {
      const { users } = await getActiveTenantUsers();
      tenantUsers = users;

      filteredUsers = tenantUsers;

      return filteredUsers;
    } catch (error) {
      ToastNotification.error('Could not fetch users');
      return [];
    }
  };

  const handleSelect = (user: IUser) => {
    selectedUserIds.has(user.id)
      ? selectedUserIds.delete(user.id)
      : selectedUserIds.add(user.id);
    selectedUserIds = new Set(selectedUserIds);
  };

  const handleSearch = (event: CustomEvent<SearchEvent>) => {
    const { searchBy, searchQuery } = event.detail;
    filteredUsers = tenantUsers.filter((user) =>
      searchBy === 'name'
        ? `${user.name} ${user.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };
  let isSubmitting = false;

  const handleAddMember = async () => {
    isSubmitting = true;
    try {
      await addTeamMembers(team.id, Array.from(selectedUserIds));

      ToastNotification.success(
        $t('dashboard.teams.view.add-member-modal-success'),
      );
      onSubmit?.();
      closeModal();
    } catch (error) {
      console.error(error);
      ToastNotification.error(
        $t('dashboard.teams.view.add-member-modal-error'),
      );
    } finally {
      isSubmitting = false;
    }
  };

  const filterOutMembers = (users: IUser[], teamUsers: IUser[]) =>
    users.filter(
      (user) => !teamUsers.some((teamUser) => teamUser.id === user.id),
    );

  const searchByOptions: SearchByOption[] = [
    { label: $t('search.by-name'), value: 'name' },
    { label: $t('search.by-email'), value: 'email' },
  ];

  $: filteredUsers = filterOutMembers(filteredUsers, team.users);
</script>

<div class="flex flex-col gap-4 rounded-lg bg-white p-6">
  <div class="w-full text-center">
    <h2 class="text-2xl font-bold text-dark-grey">
      {$t('dashboard.teams.view.add-member-modal-title')}
    </h2>
  </div>
  {#await getUsers()}
    <Loading loadingSize="h-20 w-20" />
  {:then users}
    {#if users}
      <SearchInput searchByOptions={searchByOptions} on:search={handleSearch} />
      <InviteUserList
        users={filteredUsers}
        selectedUserIds={selectedUserIds}
        onSelect={handleSelect}
      />
    {:else}
      <p>Could not fetch users</p>
    {/if}
  {/await}
  <div class="flex w-full flex-row justify-between">
    <Button on:click={() => closeModal()}
      >{$t('dashboard.teams.view.add-member-modal-cancel-button')}</Button
    >
    <Button
      testId="add-member-confirm-button"
      disabled={selectedUserIds.size === 0 || isSubmitting}
      on:click={handleAddMember}
      >{$t('dashboard.teams.view.add-member-modal-add-button')}</Button
    >
  </div>
</div>
