<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { quintOut } from 'svelte/easing';
  import { slide } from 'svelte/transition';
  import type {
    FilterDropdownEvent,
    FilterOption,
  } from '@/lib/components/filter-dropdowns/filter-dropdown.types';
  import clickOutside from '@/lib/components/helpers/clickOutside';
  import { handleKeyboardControls } from '@/lib/components/helpers/keyboardControls';

  type T = $$Generic;

  const dispatch = createEventDispatcher<{
    changeSelection: FilterDropdownEvent<T>;
  }>();

  export let selectedValues: T[] = [];
  export let options: FilterOption<T>[] = [];
  export let title = '';
  export let placeholderText = '';
  export let multipleSelectionText = '';
  export let clearText = '';
  export let allowClean = true;

  $: if (
    !allowClean &&
    (!selectedValues || selectedValues.length === 0) &&
    options.length > 0
  ) {
    selectedValues = [options[0]!.value];
    dispatch('changeSelection', { values: selectedValues });
  }

  $: selectedValues = selectedValues || [];

  let isOpen = false;

  const handleToggle = (value: T) => {
    const index = selectedValues.indexOf(value);

    if (index === -1) {
      selectedValues = [...selectedValues, value];
    } else {
      const wouldBeEmpty = selectedValues.length === 1;

      if (!allowClean && wouldBeEmpty) {
        return;
      }

      selectedValues = selectedValues.filter((v) => v !== value);
    }

    dispatch('changeSelection', { values: selectedValues });
  };

  const toggleDropdown = () => {
    isOpen = !isOpen;
  };

  const clearSelection = () => {
    if (allowClean) {
      selectedValues = [];
      dispatch('changeSelection', { values: selectedValues });
    }
  };

  function handleKeyDown(event: KeyboardEvent) {
    handleKeyboardControls(event, {
      onEscape: () => (isOpen = false),
      onEnter: toggleDropdown,
      onSpace: toggleDropdown,
    });
  }
</script>

<div
  class="relative mb-2 mt-1 min-w-[200px]"
  use:clickOutside={() => (isOpen = false)}
  role="listbox"
  tabindex="0"
>
  <button
    type="button"
    class="flex h-10 w-full items-center justify-between rounded border border-gray-300 px-4 text-base text-gray-700 transition-all duration-100 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary {isOpen
      ? 'border-gray-400 ring-1 ring-primary'
      : ''}"
    on:click|stopPropagation={toggleDropdown}
    on:keydown={handleKeyDown}
    data-cy="filter-dropdown-button"
  >
    <span class="flex items-center" data-cy="filter-dropdown-button-text">
      {#if !selectedValues?.length}
        {placeholderText}
      {:else if selectedValues?.length === 1}
        <span class="inline-block max-w-[160px] truncate">
          {options.find((o) => o.value === selectedValues[0])?.label}
        </span>
      {:else}
        {selectedValues?.length} {multipleSelectionText}
      {/if}
    </span>
    <svg
      class="h-4 w-4 transform text-gray-400 transition-transform {isOpen
        ? 'rotate-180'
        : ''}"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </button>

  {#if isOpen}
    <div
      class="absolute z-10 mt-1 w-full min-w-[200px] rounded-md border bg-white py-1 shadow-lg"
      transition:slide|local={{ duration: 200, easing: quintOut }}
    >
      <div class="flex items-center justify-between gap-2 px-4 py-2">
        <span class="text-base font-medium text-gray-900">
          {title}
        </span>
        {#if selectedValues?.length > 0 && allowClean}
          <button
            class="text-sm text-gray-500 transition-colors hover:text-gray-700"
            on:click={clearSelection}
          >
            {clearText}
          </button>
        {/if}
      </div>
      <div class="border-t" data-cy="filter-dropdown-options">
        {#each options as { value, label }, index}
          {@const isChecked = selectedValues.includes(value)}
          {@const isDisabled =
            !allowClean && isChecked && selectedValues.length === 1}
          <label
            class="flex cursor-pointer items-center px-4 py-2.5 transition-colors hover:bg-gray-100 {isDisabled
              ? 'cursor-not-allowed'
              : ''}"
          >
            <input
              type="checkbox"
              class="mr-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              checked={isChecked}
              disabled={isDisabled}
              on:change={() => handleToggle(value)}
            />
            <span
              class="max-w-[160px] truncate text-base text-gray-700"
              data-cy={`filter-dropdown-option-${index}`}>{label}</span
            >
          </label>
        {/each}
      </div>
    </div>
  {/if}
</div>
