<script lang="ts">
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import { onDestroy, onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-navigator';

  import '../src/app.css';
  import 'tippy.js/dist/tippy.css';

  import Loading from '@/lib/components/loading/Loading.svelte';
  import Modal from '@/lib/components/modal/Modal.svelte';
  import { refreshSession } from '@/lib/services/together/user.api';
  import LandingLayout from '@/routes/layouts/LandingLayout.svelte';
  import LoggedLayout from '@/routes/layouts/LoggedLayout.svelte';
  import Login from '@/routes/login/Login.svelte';
  import Signup from '@/routes/signup/Signup.svelte';
  import { userStore } from '@/store/userStore';

  const allowedPublicRoutesForLoggedInUsers = ['/session', '/mini-games'];

  $: if (
    $userStore?.role &&
    !window.location.pathname.startsWith('/dashboard') &&
    !allowedPublicRoutesForLoggedInUsers.some((route) =>
      window.location.pathname.startsWith(route),
    )
  ) {
    navigate('/dashboard', { replace: true });
  }

  let checkAuth = false;
  let refreshInterval: NodeJS.Timeout;
  const refreshIntervalTime = 1000 * 60 * 15; // 15 minutes
  onMount(() => {
    const refresh = async () => {
      try {
        await refreshSession();
        console.info('Session refreshed successfully');
      } catch (error) {
        console.error('Session refresh failed:', error);
      }
    };

    refresh().finally(() => {
      checkAuth = true;
    });

    refreshInterval = setInterval(refresh, refreshIntervalTime);
  });

  onDestroy(() => {
    clearInterval(refreshInterval);
  });
</script>

{#if checkAuth}
  <main class="flex max-h-fit min-h-screen w-full flex-col">
    <div class="wrap">
      <SvelteToast />
    </div>
    <Router primary={false}>
      <Route path="/*" component={LandingLayout} />
      <Route path="/dashboard/*" component={LoggedLayout} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
    </Router>
    <Modal />
  </main>
{:else}
  <main
    class="min-w-screen flex min-h-screen flex-col items-center justify-center"
  >
    <Loading loadingSize="h-32 w-32" />
  </main>
{/if}

<style>
  .wrap {
    display: contents;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    --toastBackground: white;
    --toastColor: #030303;
  }
  .wrap :global(strong) {
    font-weight: 600;
  }
</style>
