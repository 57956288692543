<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  import type { ICategory } from '@/interface/ICategory';
  import type { CategoryFilterEvent } from '@/lib/components/filter-dropdowns/category-filter/category-filter.types';
  import FilterDropdown from '@/lib/components/filter-dropdowns/FilterDropdown.svelte';
  import { getAllCategories } from '@/lib/services/together/together.api';
  import { locale, t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    changeCategories: CategoryFilterEvent;
  }>();

  let selectedCategories: number[] = [];
  let categories: ICategory[] = [];

  const getCategories = async () => {
    const result = await getAllCategories();
    categories = result.categories;
  };

  $: categoriesOptions = categories.map((category) => ({
    value: category.id,
    label: $locale === 'en' ? category.englishName : category.spanishName,
  }));

  function handleSelectionChange(event: CustomEvent<{ values: number[] }>) {
    const values = event.detail.values;
    selectedCategories = values;
    dispatch('changeCategories', { categories: selectedCategories });
  }

  onMount(async () => {
    await getCategories();
  });
</script>

<FilterDropdown
  selectedValues={selectedCategories}
  options={categoriesOptions}
  title={$t('dashboard.experiences.category')}
  placeholderText={$t('dashboard.experiences.select-categories')}
  multipleSelectionText={$t('dashboard.experiences.categories-selected')}
  clearText={$t('common.clear')}
  on:changeSelection={handleSelectionChange}
/>
