import { get } from 'svelte/store';
import type { IUser } from '@/interface/IUser';
import type { Column, TableItem } from '@/lib/components/tables/table.types';
import { t } from '@/locales/i18n';

const teamMemberTableKeys = {
  name: 'name',
  email: 'email',
};

export function getTeamMemberColumns(): Column<TableItem>[] {
  const translate = get(t);
  return [
    {
      key: teamMemberTableKeys.name,
      label: translate('dashboard.users.table-name'),
      width: 50,
      render: (item: TableItem) => {
        const user = item as IUser;
        return {
          text: `${user.name} ${user.lastName}`,
        };
      },
    },
    {
      key: teamMemberTableKeys.email,
      label: translate('dashboard.users.table-email'),
      width: 50,
      render: (item: TableItem) => {
        const user = item as IUser;
        return { text: user.email };
      },
    },
  ];
}
