<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import BackArrow from '@/assets/svg/BackArrow.svelte';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import type {
    SignupFormStepType,
    StepsState,
  } from '@/lib/components/signup-form/@types/SubmissionFormTypes';
  import SignupForm from '@/lib/components/signup-form/signup-form-step/SignupForm.svelte';
  import SignupInfo from '@/lib/components/signup-form/signup-info-step/SignupInfo.svelte';
  import { ToastNotification } from '@/lib/services/toast';
  import { sendSubmission } from '@/lib/services/together/onboarding.api';
  import { locale, t } from '@/locales/i18n';
  import { submissionFormStore } from '@/store/submissionFormStore';

  enum StepsIndex {
    SIGNUP_STEP = 0,
    INFO_STEP = 1,
  }

  let currentStep = 0;

  let stepsState: StepsState = [
    {
      name: $submissionFormStore?.name ?? '',
      lastName: $submissionFormStore?.lastName ?? '',
      email: $submissionFormStore?.email ?? '',
      orgName: $submissionFormStore?.orgName ?? '',
      acceptTerms: $submissionFormStore?.acceptTerms ?? false,
      language:
        $locale === 'en'
          ? ExperienceLanguages.english
          : ExperienceLanguages.spanish,
    },
  ];

  async function onSubmit(values: SignupFormStepType) {
    stepsState[currentStep] = values;
    $submissionFormStore = values;

    try {
      await sendSubmission({
        orgName: values.orgName,
        name: values.name,
        lastName: values.lastName,
        email: values.email,
        language: values.language,
      });

      ToastNotification.success($t('signup.form-success'));
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        ToastNotification.error(errorMessage);
      } else {
        ToastNotification.error($t('signup.form-error'));
      }
      return;
    }

    currentStep += 1;
  }
</script>

<div class="z-10 flex h-[45rem] w-full flex-col items-center justify-center">
  <div class="mb-8 flex items-center justify-start gap-12 md:gap-24">
    <button
      class="z-20 -ml-32 text-gray-500 transition duration-300 hover:text-gray-700 md:-ml-60"
      on:click={() => navigate('/', { replace: true })}
    >
      <BackArrow />
    </button>
  </div>
  <h2
    class="mb-8 text-center text-5xl font-bold text-primary"
    data-cy="submission-form-title"
  >
    {@html $t('signup.form-title')}
  </h2>
  <div>
    {#if currentStep === StepsIndex.SIGNUP_STEP}
      <SignupForm
        onSubmit={onSubmit}
        initialValues={stepsState[StepsIndex.SIGNUP_STEP]}
      />
    {:else if currentStep === StepsIndex.INFO_STEP}
      <SignupInfo />
    {/if}
  </div>
</div>
