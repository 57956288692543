<script lang="ts">
  import Button from '@/lib/components/button/Button.svelte';
  import { PlusIcon } from '@/lib/components/icons';
  import type { SearchByOption } from '@/lib/components/search-input/search-input.types';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { t } from '@/locales/i18n';

  export let isSearchLoading = false;
  export let searchInputValue = '';
  export let handleCreateTeam: () => void;

  const searchByOptions: SearchByOption[] = [
    { label: $t('search.by-name'), value: 'name' },
    { label: $t('search.by-host'), value: 'hostName' },
  ];
</script>

<div class="flex flex-row items-center justify-between">
  <div class="flex flex-row items-center gap-4">
    <SearchInput
      searchByOptions={searchByOptions}
      isAsync
      isLoading={isSearchLoading}
      bind:value={searchInputValue}
      on:search
    />
  </div>

  <div>
    <Button
      testId="add-team-button"
      tourIdentifier="dashboard-teams-create-button"
      on:click={handleCreateTeam}
      style="fill"
      textSize="md"
    >
      <PlusIcon />
      <span>{$t('dashboard.teams.add-team-button')}</span>
    </Button>
  </div>
</div>
