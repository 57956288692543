import { get } from 'svelte/store';
import * as yup from 'yup';
import { t } from '@/locales/i18n';

const translate = get(t);

export const signUpFormSchema = yup.object({
  orgName: yup
    .string()
    .required(translate('signup.form-orgname-required'))
    .min(2, translate('signup.form-orgname-min'))
    .max(25, translate('signup.form-orgname-max')),
  name: yup
    .string()
    .required(translate('signup.form-name-required'))
    .min(2, translate('signup.form-name-min'))
    .max(25, translate('signup.form-name-max')),
  lastName: yup
    .string()
    .required(translate('signup.form-lastname-required'))
    .min(2, translate('signup.form-lastname-min'))
    .max(25, translate('signup.form-lastname-max')),
  email: yup
    .string()
    .email(translate('signup.form-email-invalid'))
    .required(translate('signup.form-email-required')),
  acceptTerms: yup
    .boolean()
    .oneOf([true], translate('signup.form-terms-required')),
});
