<script lang="ts">
  import { onMount, tick } from 'svelte';
  import { Route } from 'svelte-navigator';
  import { UserRole } from '@/interface/IUser';
  import { startFirstTimeTour } from '@/lib/actions/tour/tour';
  import AuthGuard from '@/lib/components/auth-guard/AuthGuard.svelte';
  import DashboardSidebar from '@/lib/components/sidebar/DashboardSidebar.svelte';
  import { userStore } from '@/store/userStore';

  import DashboardExperiences from '../dashboard/experiences/DashboardExperiences.svelte';
  import ExperienceView from '../dashboard/experiences/ExperienceView.svelte';
  import DashboardSessions from '../dashboard/sessions/DashboardSessions.svelte';
  import DashboardSettings from '../dashboard/settings/DashboardSettings.svelte';
  import DashboardTeams from '../dashboard/teams/DashboardTeams.svelte';
  import TeamView from '../dashboard/teams/TeamView.svelte';
  import DashboardUsers from '../dashboard/users/DashboardUsers.svelte';
  import MiniGames from '../mini-games/MiniGames.svelte';
  import OAuthCallback from '../oauth/OAuthCallback.svelte';

  let isAdmin: boolean =
    $userStore?.role === UserRole.ADMIN ||
    $userStore?.role === UserRole.SUPER_ADMIN;
  let isHost: boolean = $userStore?.role === UserRole.HOST;
  let isUser: boolean = $userStore?.role === UserRole.USER;
  let isAuthenticated = $userStore?.role !== undefined;

  onMount(async () => {
    if (!isAuthenticated) return;

    await tick();

    setTimeout(() => {
      startFirstTimeTour();
    }, 1500);
  });
</script>

<AuthGuard authCheck={() => isAdmin || isHost || isUser} />
<div class="flex h-screen w-full grow flex-row">
  <DashboardSidebar />
  <div class="h-screen w-full overflow-auto">
    <Route
      default
      path="/"
      component={isUser
        ? DashboardSessions
        : isAdmin
          ? DashboardUsers
          : DashboardExperiences}
    />
    <Route path="/users" component={DashboardUsers} />
    <Route path="/teams" component={DashboardTeams} />
    <Route path="/teams/:id" component={TeamView} />
    <Route path="/experiences" component={DashboardExperiences} />
    <Route path="/experiences/:id" component={ExperienceView} />
    <Route path="/sessions" component={DashboardSessions} />
    <Route path="/settings/*" component={DashboardSettings} />
    <Route path="/mini-games" component={MiniGames} />
    <Route path="/oauth/callback" component={OAuthCallback} />
  </div>
</div>
