<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { tooltip } from '@/lib/actions/tooltip';
  import Avatar from '@/lib/components/avatar/Avatar.svelte';
  import Badge from '@/lib/components/badge/Badge.svelte';
  import { EmptyTrayIcon, SortIcon } from '@/lib/components/icons';
  import LoadingSpinner from '@/lib/components/loading/Loading.svelte';
  import {
    TemplateType,
    type Action,
    type AvatarTemplate,
    type BadgeTemplate,
    type Column,
    type IconTemplate,
    type SortEvent,
    type SortOrder,
    type TableItem,
  } from '@/lib/components/tables/table.types';
  import { t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    sort: SortEvent;
  }>();

  export let columns: Column<TableItem>[] = [];
  export let data: TableItem[] = [];
  export let actions: Action<TableItem>[] = [];
  export let isLoading = false;
  export let emptyMessage = '';
  export let loadingMessage = '';
  export let sortBy = '';
  export let sortOrder: SortOrder = 'asc';

  let avatarUrls: Record<string, string> = {};
  let iconComponents: Record<string, any> = {};
  let badgeVariants: Record<string, 'success' | 'warning' | 'error' | 'info'> =
    {};

  function handleSort(column: Column<TableItem>) {
    if (!column.sortable) return;

    const newOrder =
      column.key === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';
    dispatch('sort', { column: column.key, order: newOrder });
  }

  $: {
    avatarUrls = {};
    iconComponents = {};
    badgeVariants = {};

    data.forEach((item, itemIndex) => {
      columns.forEach((column, colIndex) => {
        const template = column.render(item).template;
        if (template) {
          const key = `${itemIndex}-${colIndex}`;
          if (template.type === TemplateType.AVATAR) {
            avatarUrls[key] = (template as AvatarTemplate).url;
          } else if (template.type === TemplateType.ICON) {
            iconComponents[key] = (template as IconTemplate).component;
          } else if (template.type === TemplateType.BADGE) {
            const badgeTemplate = template as BadgeTemplate;
            badgeVariants[key] = badgeTemplate.variant || 'info';
          }
        }
      });
    });
  }
</script>

<div
  class="relative flex h-full w-full flex-col rounded-lg border border-light-grey shadow-sm"
>
  <div class="h-full overflow-x-auto">
    <table class="w-full border-collapse text-left text-dark-grey">
      <thead>
        <tr class="h-12 bg-gray-50">
          {#each columns as column}
            <th
              scope="col"
              class="border-b border-light-grey px-4 py-3 text-sm font-semibold text-gray-600"
              style="width: {column.width}%"
              class:cursor-pointer={column.sortable}
              on:click={() => handleSort(column)}
            >
              <div class="flex items-center gap-2">
                <span>{column.label}</span>
                {#if column.sortable}
                  <SortIcon
                    active={column.key === sortBy}
                    direction={column.key === sortBy ? sortOrder : undefined}
                  />
                {/if}
              </div>
            </th>
          {/each}
          {#if actions.length > 0}
            <th
              scope="col"
              class="w-[10%] rounded-tr-lg border-b border-light-grey px-4 text-right text-sm font-semibold text-gray-600"
            >
              <span>{$t('table.row.common.actions')}</span>
            </th>
          {/if}
        </tr>
      </thead>
      <tbody>
        {#if isLoading}
          <tr class="h-[400px] items-center justify-center">
            <td
              colspan={columns.length + (actions.length ? 1 : 0)}
              class="p-4 text-center"
            >
              <div class=" gap-3">
                <LoadingSpinner />
                <span class="text-gray-500">{loadingMessage}</span>
              </div>
            </td>
          </tr>
        {:else if data.length === 0}
          <tr class="h-[400px] items-center justify-center">
            <td
              colspan={columns.length + (actions.length ? 1 : 0)}
              class="h-full min-h-full items-center justify-center gap-2 p-8 text-center"
            >
              <div
                class="flex h-full min-h-full flex-col items-center justify-center gap-2"
              >
                <div
                  class="flex h-16 w-16 items-center justify-center rounded-full bg-gray-100"
                >
                  <EmptyTrayIcon class_="h-8 w-8 text-gray-400" />
                </div>
                <span class="text-gray-500">{emptyMessage}</span>
              </div>
            </td>
          </tr>
        {:else}
          {#each data as item, rowIndex}
            <tr
              class="border-b border-light-grey transition-all duration-200 ease-in-out hover:bg-gray-50 hover:shadow-sm"
            >
              {#each columns as column, colIndex}
                <td class="px-4 py-3">
                  <div class="flex flex-row items-center gap-2">
                    {#if column.render(item).template}
                      {#if column.render(item).template?.type === TemplateType.AVATAR}
                        <Avatar
                          src={avatarUrls[`${rowIndex}-${colIndex}`]}
                          alt="User avatar"
                          size="md"
                          className="border-2 border-gray-200"
                        />
                        <span class="text-base">{column.render(item).text}</span
                        >
                      {:else if column.render(item).template?.type === TemplateType.ICON}
                        <div
                          class="h-10 w-10 overflow-hidden rounded-lg border-2 border-gray-200 bg-white"
                        >
                          <svelte:component
                            this={iconComponents[`${rowIndex}-${colIndex}`]}
                            style="text-grey w-full h-full"
                          />
                        </div>
                        <span class="text-base">{column.render(item).text}</span
                        >
                      {:else if column.render(item).template?.type === TemplateType.BADGE}
                        <Badge
                          text={column.render(item).text}
                          variant={badgeVariants[`${rowIndex}-${colIndex}`]}
                        />
                      {/if}
                    {:else}
                      <span class="text-base">{column.render(item).text}</span>
                    {/if}
                  </div>
                </td>
              {/each}
              {#if actions.length > 0}
                <td class="px-4 py-3 text-right">
                  <div class="flex flex-row items-center justify-end gap-3">
                    {#each actions as { name, icon: Icon, onAction, disabledCondition, hideOnDisabled, infoText }}
                      {#if !(hideOnDisabled && disabledCondition?.(item))}
                        <button
                          type="button"
                          disabled={disabledCondition?.(item) ?? false}
                          data-cy={`${name}-button-${rowIndex}`}
                          on:click={() => onAction(item)}
                          class="text-gray-400 transition-colors duration-200 ease-in-out hover:text-primary disabled:cursor-not-allowed disabled:opacity-50"
                          use:tooltip={() => ({ content: infoText })}
                        >
                          <svelte:component this={Icon} class_="h-5 w-5" />
                        </button>
                      {/if}
                    {/each}
                  </div>
                </td>
              {/if}
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>
