<script lang="ts">
  import type { IUserQuery } from '@/interface/IUserQuery';
  import Button from '@/lib/components/button/Button.svelte';
  import { UserRoleFilter } from '@/lib/components/filter-dropdowns';
  import { PlusIcon } from '@/lib/components/icons';
  import Input from '@/lib/components/input/Input.svelte';
  import type { SearchByOption } from '@/lib/components/search-input/search-input.types';
  import SearchUserInput from '@/lib/components/search-input/SearchInput.svelte';
  import { t } from '@/locales/i18n';

  export let query: IUserQuery;
  export let isSearchLoading = false;
  export let searchInputValue = '';
  export let showInactive = false;
  export let onShowInactiveChange: () => void;
  export let onInviteUsers: () => void;

  const searchOptions: SearchByOption[] = [
    { value: 'name', label: $t('search.by-name') },
    { value: 'email', label: $t('search.by-email') },
  ];
</script>

<div class="flex flex-row items-center justify-between">
  <div class="flex flex-row items-center gap-4">
    <SearchUserInput
      searchByOptions={searchOptions}
      isAsync
      isLoading={isSearchLoading}
      bind:value={searchInputValue}
      on:search
    />

    <UserRoleFilter bind:selectedRoles={query.role} on:changeRoles />

    <div class="flex w-fit items-center justify-center">
      <Input
        styleClass="w-5"
        type="checkbox"
        bind:checked={showInactive}
        on:change={onShowInactiveChange}
        testId="show-inactive-users-checkbox"
      />
      <span class="whitespace-nowrap text-sm text-grey">
        {$t('dashboard.users.show-inactive-users-label')}
      </span>
    </div>
  </div>

  <div>
    <Button
      testId="invite-users-button"
      tourIdentifier="dashboard-users-invite-button"
      on:click={onInviteUsers}
      style="fill"
      textSize="md"
    >
      <PlusIcon />
      <span>{$t('dashboard.users.button')}</span>
    </Button>
  </div>
</div>
