export const waitForElement = (
  selector: string,
  callback: () => void,
  maxAttempts = 20,
  interval = 300,
) => {
  let attempts = 0;

  const checkElement = setInterval(() => {
    const element = document.querySelector(selector);

    if (element) {
      clearInterval(checkElement);
      callback();
    }

    attempts++;
    if (attempts >= maxAttempts) {
      clearInterval(checkElement);
    }
  }, interval);
};
