<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import {
    CategoryFilter,
    LanguageSelector,
    StarRatingFilter,
  } from '@/lib/components/filter-dropdowns';
  import type { CategoryFilterEvent } from '@/lib/components/filter-dropdowns/category-filter/category-filter.types';
  import type { LanguageSelectorEvent } from '@/lib/components/filter-dropdowns/language-selector/language-selector.types';
  import type { StarRatingFilterEvent } from '@/lib/components/filter-dropdowns/star-rating-filter/star-rating-filter.types';
  import type { SearchEvent } from '@/lib/components/search-input/search-input.types';
  import SearchInput from '@/lib/components/search-input/SearchInput.svelte';
  import { locale, t } from '@/locales/i18n';

  export let selectedLanguage: string =
    $locale === 'en'
      ? ExperienceLanguages.english
      : ExperienceLanguages.spanish;
  export let selectedCategories: number[] = [];
  export let selectedRating: number[] = [];
  export let isSearchLoading = false;
  export let searchInputValue = '';

  const dispatch = createEventDispatcher<{
    languageChange: string;
    categoryChange: number[];
    ratingChange: number[];
    search: SearchEvent;
  }>();

  const handleLanguageChange = (event: CustomEvent<LanguageSelectorEvent>) => {
    const { language } = event.detail;
    selectedLanguage = language;
    dispatch('languageChange', language);
  };

  const handleCategoryChange = (event: CustomEvent<CategoryFilterEvent>) => {
    const { categories } = event.detail;
    selectedCategories = categories;
    dispatch('categoryChange', categories);
  };

  const handleRatingChange = (event: CustomEvent<StarRatingFilterEvent>) => {
    const { rating } = event.detail;
    selectedRating = rating;
    dispatch('ratingChange', rating);
  };

  const searchOptions = [{ value: 'name', label: $t('search.by-name') }];
</script>

<div
  class="flex w-full flex-row items-center justify-start gap-4 border-b border-light-grey bg-white px-1 pb-1 pt-0"
>
  <div class="flex-grow">
    <SearchInput
      searchByOptions={searchOptions}
      isAsync
      isLoading={isSearchLoading}
      bind:value={searchInputValue}
      on:search
    />
  </div>

  <div data-cy="experience-category-filter">
    <CategoryFilter on:changeCategories={handleCategoryChange} />
  </div>

  <div data-cy="experience-rating-filter">
    <StarRatingFilter on:changeRating={handleRatingChange} />
  </div>

  <div data-cy="experience-language-filter">
    <LanguageSelector
      selectedLanguage={selectedLanguage}
      on:changeLanguage={handleLanguageChange}
    />
  </div>
</div>
