<script lang="ts">
  export let class_ = '';
</script>

<svg class={class_} viewBox="0 0 24 24" fill="none" stroke="currentColor">
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M13 10V3L4 14h7v7l9-11h-7z"
  />
</svg>
