<script lang="ts">
  export let rotated = false;
  export let class_ = '';
</script>

<svg
  class="ml-2 h-4 w-4 transform text-gray-400 transition-transform {rotated
    ? 'rotate-180'
    : ''} {class_}"
  viewBox="0 0 20 20"
  fill="currentColor"
>
  <path
    fill-rule="evenodd"
    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
    clip-rule="evenodd"
  />
</svg>
