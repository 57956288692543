import Cropper from 'cropperjs';
import { writable } from 'svelte/store';

import { DEFAULT_CROPPER_OPTIONS } from '../constants';
import { ImageProcessingState } from '../types/processing-state.enum';

interface CropperState {
  cropper: Cropper | null;
  isProcessing: boolean;
  processingState: ImageProcessingState;
  showCropper: boolean;
}

function createCropperStore() {
  const { subscribe, set, update } = writable<CropperState>({
    cropper: null,
    isProcessing: false,
    processingState: ImageProcessingState.IDLE,
    showCropper: false,
  });

  return {
    subscribe,
    setShowCropper: (show: boolean) =>
      update((state) => ({
        ...state,
        showCropper: show,
      })),
    initCropper: (image: HTMLImageElement) =>
      update((state) => {
        if (state.cropper) {
          state.cropper.destroy();
        }
        return {
          ...state,
          cropper: new Cropper(image, DEFAULT_CROPPER_OPTIONS),
          showCropper: true,
        };
      }),
    destroyCropper: () =>
      update((state) => {
        state.cropper?.destroy();
        return {
          ...state,
          cropper: null,
          showCropper: false,
        };
      }),
    setProcessing: (isProcessing: boolean, state: ImageProcessingState) =>
      update((s) => ({
        ...s,
        isProcessing,
        processingState: state,
      })),
    reset: () =>
      set({
        cropper: null,
        isProcessing: false,
        processingState: ImageProcessingState.IDLE,
        showCropper: false,
      }),
  };
}

export const cropperStore = createCropperStore();
