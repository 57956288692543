<script lang="ts">
  import type { SvelteComponent } from 'svelte';
  import { UserIcon } from '@/lib/components/icons';

  export let src: string | null | undefined = null;

  export let alt = 'User avatar';

  export let size: 'sm' | 'md' | 'lg' | 'custom' = 'md';

  export let shape: 'rounded' | 'circle' = 'rounded';

  export let fallbackIcon: typeof SvelteComponent = UserIcon;

  export let className = '';

  export let width: string | undefined = undefined;
  export let height: string | undefined = undefined;

  export let clickable = false;
  export let onClick: (() => void) | undefined = undefined;

  const sizeClasses = {
    sm: 'h-8 w-8',
    md: 'h-10 w-10',
    lg: 'h-16 w-16',
    custom: '',
  };

  const shapeClasses = {
    rounded: 'rounded-lg',
    circle: 'rounded-full',
  };

  $: actualSizeClass = size === 'custom' ? '' : sizeClasses[size];
  $: actualShapeClass = shapeClasses[shape];
  $: customSizeStyle =
    size === 'custom' ? `width: ${width}; height: ${height};` : '';
  $: cursorClass = clickable ? 'cursor-pointer' : '';
</script>

{#if clickable}
  <button
    type="button"
    class="{actualSizeClass} {actualShapeClass} overflow-hidden border border-gray-200 bg-white {cursorClass} {className} p-0"
    style={customSizeStyle}
    on:click={onClick}
    aria-label={alt}
  >
    {#if src}
      <img src={src} alt={alt} class="h-full w-full object-cover" />
    {:else}
      <svelte:component this={fallbackIcon} style="text-grey w-full h-full" />
    {/if}
  </button>
{:else}
  <div
    class="{actualSizeClass} {actualShapeClass} overflow-hidden border border-gray-200 bg-white {className}"
    style={customSizeStyle}
  >
    {#if src}
      <img src={src} alt={alt} class="h-full w-full object-cover" />
    {:else}
      <svelte:component this={fallbackIcon} style="text-grey w-full h-full" />
    {/if}
  </div>
{/if}
