<script lang="ts">
  import SessionsTable from '@/lib/components/tables/sessions/SessionsTable.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>{$t('dashboard.sessions.route-title')}</title>
</svelte:head>

<div
  class="flex h-full w-full flex-col bg-gray-50"
  data-tour="dashboard-sessions"
>
  <div
    class="flex min-h-[4rem] w-full flex-row items-center justify-start border-b border-light-grey px-6"
  >
    <h1 class="mt-1 ps-4 text-2xl font-bold text-primary">
      {$t('dashboard.sessions.title')}
    </h1>
  </div>
  <div class="flex-1 overflow-auto px-6 py-4">
    <div
      class="flex h-fit min-h-full w-full flex-col justify-center rounded-lg border border-light-grey bg-white shadow-sm"
      data-tour="dashboard-sessions-container"
    >
      <div class="px-6 pb-2 pt-4">
        <SessionsTable />
      </div>
    </div>
  </div>
</div>
