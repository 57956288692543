<script lang="ts">
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';
</script>

<svelte:head>
  <title>Together - {$t('privacy.route-title')}</title>
</svelte:head>

<Page>
  <div class="mx-auto mb-8 max-w-2xl p-4 md:p-6 lg:p-8">
    <h1
      class="mb-8 text-4xl font-bold text-primary"
      data-cy="privacy-page-title"
    >
      {$t('privacy.title')}
    </h1>

    <h2 class="text-2xl font-bold">{$t('privacy.introduction.title')}</h2>
    <p class="mb-10 text-lg">
      {@html $t('privacy.introduction.content')}
    </p>

    <h2 class="text-2xl font-bold">{$t('privacy.information.title')}</h2>
    <p class="mb-4 text-lg">{$t('privacy.information.intro')}</p>
    <ul class="mb-10 text-lg">
      <li>
        <p>{$t('privacy.information.personal.title')}</p>
        <ul class="mb-4 list-disc pl-4">
          <li>{$t('privacy.information.personal.item1')}</li>
          <li>{$t('privacy.information.personal.item2')}</li>
          <li>{$t('privacy.information.personal.item3')}</li>
          <li>{$t('privacy.information.personal.item4')}</li>
        </ul>
      </li>
      <li>
        <p>{$t('privacy.information.usage.title')}</p>
        <ul class="mb-4 list-disc pl-4">
          <li>{$t('privacy.information.usage.item1')}</li>
          <li>{$t('privacy.information.usage.item2')}</li>
          <li>{$t('privacy.information.usage.item3')}</li>
        </ul>
      </li>
    </ul>

    <h2 class="text-2xl font-bold">{$t('privacy.usage.title')}</h2>
    <p class="mb-4 text-lg">{$t('privacy.usage.intro')}</p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>{$t('privacy.usage.item1')}</li>
      <li>{$t('privacy.usage.item2')}</li>
      <li>{$t('privacy.usage.item3')}</li>
      <li>{$t('privacy.usage.item4')}</li>
      <li>{$t('privacy.usage.item5')}</li>
    </ul>

    <h2 class="text-2xl font-bold">{$t('privacy.sharing.title')}</h2>
    <p class="mb-4 text-lg">{$t('privacy.sharing.intro')}</p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>{$t('privacy.sharing.item1')}</li>
      <li>{$t('privacy.sharing.item2')}</li>
      <li>{$t('privacy.sharing.item3')}</li>
    </ul>

    <h2 class="text-2xl font-bold">{$t('privacy.security.title')}</h2>
    <p class="mb-4 text-lg">{@html $t('privacy.security.content')}</p>
    <p class="mb-4 text-lg">{$t('privacy.security.intro')}</p>
    <ul class="mb-10 list-disc pl-4 text-lg">
      <li>{$t('privacy.security.item1')}</li>
      <li>{$t('privacy.security.item2')}</li>
      <li>{$t('privacy.security.item3')}</li>
      <li>{$t('privacy.security.item4')}</li>
    </ul>

    <h2 class="text-2xl font-bold">{$t('privacy.retention.title')}</h2>
    <p class="mb-10 text-lg">{@html $t('privacy.retention.content')}</p>

    <h2 class="text-2xl font-bold">{$t('privacy.rights.title')}</h2>
    <p class="mb-4 text-lg">{$t('privacy.rights.intro')}</p>
    <ul class="mb-4 list-disc pl-4 text-lg">
      <li>{$t('privacy.rights.item1')}</li>
      <li>{$t('privacy.rights.item2')}</li>
      <li>{$t('privacy.rights.item3')}</li>
      <li>{$t('privacy.rights.item4')}</li>
      <li>{$t('privacy.rights.item5')}</li>
      <li>{$t('privacy.rights.item6')}</li>
    </ul>
    <p class="mb-10 text-lg">{@html $t('privacy.rights.contact')}</p>

    <h2 class="text-2xl font-bold">{$t('privacy.compliance.title')}</h2>
    <p class="mb-10 text-lg">{$t('privacy.compliance.content')}</p>

    <h2 class="text-2xl font-bold">{$t('privacy.changes.title')}</h2>
    <p class="mb-10 text-lg">{$t('privacy.changes.content')}</p>

    <h2 class="text-2xl font-bold">{$t('privacy.contact.title')}</h2>
    <p class="mb-4 text-lg">{$t('privacy.contact.intro')}</p>
    <ul class="mb-6 text-lg">
      <li>{@html $t('privacy.contact.email')}</li>
      <li>{$t('privacy.contact.address')}</li>
    </ul>

    <p class="text-lg">{$t('privacy.contact.outro')}</p>
  </div>
</Page>
