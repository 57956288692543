<script lang="ts">
  import { slide } from 'svelte/transition';
  import ArrowIcon from '@/lib/components/icons/ArrowIcon.svelte';
  import Page from '@/lib/components/page/Page.svelte';
  import { t } from '@/locales/i18n';

  interface FAQItem {
    question: string;
    answer: string;
  }

  interface Topic {
    title: string;
    questions: FAQItem[];
  }

  const aboutPlatformQuestions: FAQItem[] = [
    {
      question: 'faq.about-platform.question-1',
      answer: 'faq.about-platform.answer-1',
    },
    {
      question: 'faq.about-platform.question-2',
      answer: 'faq.about-platform.answer-2',
    },
    {
      question: 'faq.about-platform.question-3',
      answer: 'faq.about-platform.answer-3',
    },
  ];

  const registrationAndSetupQuestions: FAQItem[] = [
    {
      question: 'faq.registration-and-setup.question-1',
      answer: 'faq.registration-and-setup.answer-1',
    },
    {
      question: 'faq.registration-and-setup.question-2',
      answer: 'faq.registration-and-setup.answer-2',
    },
  ];

  const experiencesAndActivitiesQuestions: FAQItem[] = [
    {
      question: 'faq.experiences-and-activities.question-1',
      answer: 'faq.experiences-and-activities.answer-1',
    },
    {
      question: 'faq.experiences-and-activities.question-2',
      answer: 'faq.experiences-and-activities.answer-2',
    },
  ];
  const plansAndPaymentsQuestions: FAQItem[] = [
    {
      question: 'faq.plans-and-payments.question-1',
      answer: 'faq.plans-and-payments.answer-1',
    },
    {
      question: 'faq.plans-and-payments.question-2',
      answer: 'faq.plans-and-payments.answer-2',
    },
    {
      question: 'faq.plans-and-payments.question-3',
      answer: 'faq.plans-and-payments.answer-3',
    },
  ];
  const integrationsQuestions: FAQItem[] = [
    {
      question: 'faq.integrations.question-1',
      answer: 'faq.integrations.answer-1',
    },
    {
      question: 'faq.integrations.question-2',
      answer: 'faq.integrations.answer-2',
    },
    {
      question: 'faq.integrations.question-3',
      answer: 'faq.integrations.answer-3',
    },
  ];
  const supportAndAssistanceQuestions: FAQItem[] = [
    {
      question: 'faq.support-and-assistance.question-1',
      answer: 'faq.support-and-assistance.answer-1',
    },
  ];
  const securityAndPrivacyQuestions: FAQItem[] = [
    {
      question: 'faq.security-and-privacy.question-1',
      answer: 'faq.security-and-privacy.answer-1',
    },
  ];

  const trialAndDemoQuestions: FAQItem[] = [
    {
      question: 'faq.trial-and-demo.question-1',
      answer: 'faq.trial-and-demo.answer-1',
    },
    {
      question: 'faq.trial-and-demo.question-2',
      answer: 'faq.trial-and-demo.answer-2',
    },
  ];

  const topics: Topic[] = [
    { title: 'faq.about-platform.title', questions: aboutPlatformQuestions },
    {
      title: 'faq.registration-and-setup.title',
      questions: registrationAndSetupQuestions,
    },
    {
      title: 'faq.experiences-and-activities.title',
      questions: experiencesAndActivitiesQuestions,
    },
    {
      title: 'faq.plans-and-payments.title',
      questions: plansAndPaymentsQuestions,
    },
    { title: 'faq.integrations.title', questions: integrationsQuestions },
    {
      title: 'faq.support-and-assistance.title',
      questions: supportAndAssistanceQuestions,
    },
    {
      title: 'faq.security-and-privacy.title',
      questions: securityAndPrivacyQuestions,
    },
    { title: 'faq.trial-and-demo.title', questions: trialAndDemoQuestions },
  ];

  let expandedTopics: number[] = [];
  let expandedQuestions: { [key: number]: number[] } = {};

  const toggleTopic = (index: number) => {
    if (expandedTopics.includes(index)) {
      expandedTopics = expandedTopics.filter((i) => i !== index);
    } else {
      expandedTopics = [...expandedTopics, index];
    }
  };

  const toggleQuestion = (topicIndex: number, questionIndex: number) => {
    expandedQuestions[topicIndex] = expandedQuestions[topicIndex] || [];

    if (expandedQuestions[topicIndex]!.includes(questionIndex)) {
      expandedQuestions[topicIndex] = expandedQuestions[topicIndex]!.filter(
        (i) => i !== questionIndex,
      );
    } else {
      expandedQuestions[topicIndex] = [
        ...expandedQuestions[topicIndex]!,
        questionIndex,
      ];
    }
  };
</script>

<svelte:head>
  <title>Together - {$t('faq.route-title')}</title>
</svelte:head>

<Page>
  <div class="mx-auto w-full max-w-3xl px-6 py-12">
    <h1 class="mb-12 text-4xl font-bold text-primary" data-cy="faq-page-title">
      {$t('faq.title')}
    </h1>
    <div class="space-y-8">
      {#each topics as topic, topicIndex}
        <div class="w-full rounded-md border">
          <button
            class="flex w-full items-center justify-between bg-gray-100 px-4 py-2 text-left focus:outline-none"
            on:click={() => toggleTopic(topicIndex)}
            data-cy="faq-topic-button-{topicIndex}"
          >
            <span
              class="text-2xl font-semibold"
              data-cy="faq-topic-title-{topicIndex}"
              >{@html $t(topic.title)}</span
            >
            <ArrowIcon
              style={`transition-transform duration-300 ease-in-out ${expandedTopics.includes(topicIndex) ? 'rotate-180' : 'rotate-0'}`}
            />
          </button>
          {#if expandedTopics.includes(topicIndex)}
            <div
              class="w-full space-y-4 px-4 py-2"
              transition:slide={{ duration: 300 }}
            >
              {#each topic.questions as { question, answer }, questionIndex}
                <div class="w-full rounded-md border">
                  <button
                    class="flex w-full items-center justify-between bg-gray-50 px-4 py-2 text-left focus:outline-none"
                    on:click={() => toggleQuestion(topicIndex, questionIndex)}
                    data-cy="faq-question-button-{topicIndex}-{questionIndex}"
                  >
                    <span
                      class="text-lg"
                      data-cy="faq-question-{topicIndex}-{questionIndex}"
                      >{$t(question)}</span
                    >
                    <ArrowIcon
                      style={`transition-transform duration-300 ease-in-out ${expandedQuestions[topicIndex] && expandedQuestions[topicIndex]?.includes(questionIndex) ? 'rotate-180' : 'rotate-0'}`}
                    />
                  </button>
                  {#if expandedQuestions[topicIndex] && expandedQuestions?.[topicIndex]?.includes(questionIndex)}
                    <div
                      class="bg-gray-10 w-full px-4 py-2"
                      transition:slide={{ duration: 300 }}
                      data-cy="faq-answer-{topicIndex}-{questionIndex}"
                    >
                      <p class="text-md">{@html $t(answer)}</p>
                    </div>
                  {/if}
                </div>
              {/each}
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</Page>
