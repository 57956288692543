<script lang="ts">
  import { ToastNotification } from '@/lib/services/toast';
  import { t } from '@/locales/i18n';

  import Button from '../../button/Button.svelte';
  import Loading from '../../loading/Loading.svelte';
  import { closeModal } from '../modal.store';

  export let title = $t('confirmation.modal.title');
  export let text = $t('confirmation.modal.text');
  export let onSubmit: (() => void | Promise<void>) | undefined = undefined;

  let isLoading = false;

  const handleConfirm = async () => {
    try {
      isLoading = true;
      await onSubmit?.();
      closeModal();
    } catch (error) {
      ToastNotification.error($t('confirmation.modal.error'));
    } finally {
      isLoading = false;
    }
  };
</script>

<div
  class="min-h-60 flex w-80 flex-col items-center justify-between gap-6 rounded-lg bg-white p-6 text-center"
>
  <h1 class="text-2xl font-bold">{title}</h1>
  {#if isLoading}
    <Loading loadingSize="h-20 w-20" />
  {:else}
    <p>{text}</p>
  {/if}
  <div class="flex w-full flex-row items-center justify-between">
    <Button disabled={isLoading} style="outline" on:click={() => closeModal()}
      ><span>{$t('confirmation.modal.close')}</span></Button
    >
    <Button
      testId="confirm-button"
      on:click={handleConfirm}
      disabled={isLoading}
      ><span>{$t('confirmation.modal.confirm')}</span></Button
    >
  </div>
</div>
