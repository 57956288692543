<script lang="ts">
  import { languageEnumTranslation } from '@/interface/IExperience';
  import type { ITeam } from '@/interface/ITeam';
  import Button from '@/lib/components/button/Button.svelte';
  import ChangeUserIcon from '@/lib/components/icons/ChangeUserIcon.svelte';
  import PlusIcon from '@/lib/components/icons/PlusIcon.svelte';
  import { openModal } from '@/lib/components/modal/modal.store';
  import AddTeamMemberModal from '@/lib/components/modal/modals/AddTeamMemberModal.svelte';
  import ChangeHostModal from '@/lib/components/modal/modals/ChangeHostModal.svelte';
  import CreateTeamModal from '@/lib/components/modal/modals/CreateTeamModal.svelte';
  import { t } from '@/locales/i18n';

  export let team: ITeam;
  export let onRefresh: () => Promise<void>;

  const handleEditTeam = async (team: ITeam) => {
    openModal(CreateTeamModal, {
      allowCloseClick: true,
      props: { team, onSubmit: onRefresh },
    });
  };

  const handleChangeHost = async (team: ITeam) => {
    openModal(ChangeHostModal, {
      allowCloseClick: true,
      props: { team, onSubmit: onRefresh },
    });
  };

  const handleAddMember = async (team: ITeam) => {
    openModal(AddTeamMemberModal, {
      allowCloseClick: true,
      props: { team, onSubmit: onRefresh },
    });
  };
</script>

<div class="flex flex-row justify-between">
  <div class="flex flex-col gap-1">
    <div class="flex flex-row items-center gap-12">
      <span>Host</span>
      <ChangeUserIcon
        on:click={() => handleChangeHost(team)}
        style="h-6 w-6 cursor-pointer bottom-[.2rem]"
        bgColor="bg-white"
        fillColor="fill-primary"
        testId="change-host-button"
      />
    </div>
    <span class="font-semibold text-dark-grey"
      >{team.host.name} {team.host.lastName}</span
    >
  </div>

  <div class="flex flex-row gap-2">
    <div class="flex flex-col gap-1">
      <span>{$t('dashboard.teams.view.language-label')}:</span>
      <span>{$t('dashboard.teams.view.monthly-sessions-label')}:</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-semibold text-dark-grey"
        >{languageEnumTranslation(team.language)}</span
      >
      <span class="font-semibold text-dark-grey"
        >{team.monthlySession
          ? $t('dashboard.teams.view.monthly-sessions.enabled')
          : $t('dashboard.teams.view.monthly-sessions.disabled')}</span
      >
    </div>

    <Button on:click={() => handleEditTeam(team)} testId="edit-team-button"
      >{$t('dashboard.teams.view.edit-team-button')}</Button
    >
    <Button
      testId="add-member-button"
      on:click={() => handleAddMember(team)}
      style="outline"
      ><PlusIcon /><span>{$t('dashboard.teams.view.add-member-button')}</span
      ></Button
    >
  </div>
</div>
