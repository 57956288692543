<script>
  import { Route } from 'svelte-navigator';
  import Footer from '@/lib/components/footer/Footer.svelte';
  import Navbar from '@/lib/components/navbar/Navbar.svelte';
  import Faq from '@/routes/faq/Faq.svelte';
  import ForgotPassword from '@/routes/forgot-password/ForgotPassword.svelte';
  import Home from '@/routes/home/Home.svelte';
  import Integrations from '@/routes/integrations-info/IntegrationsInfo.svelte';
  import Dices from '@/routes/mini-games/dice/Dices.svelte';
  import LuckyCards from '@/routes/mini-games/lucky-cards/LuckyCards.svelte';
  import Roulette from '@/routes/mini-games/roulette/Roulette.svelte';
  import NotFound from '@/routes/NotFound.svelte';
  import Privacy from '@/routes/privacy/Privacy.svelte';
  import SessionFeedback from '@/routes/session-feedback/SessionFeedback.svelte';
  import Support from '@/routes/support/Support.svelte';
  import Terms from '@/routes/terms/Terms.svelte';
</script>

<Navbar />
<Route path="/" component={Home} />

<Route path="/forgot-password" component={ForgotPassword} />
<Route path="/integrations" component={Integrations} />
<Route path="/privacy" component={Privacy} />
<Route path="/terms" component={Terms} />
<Route path="/support" component={Support} />
<Route path="/faq" component={Faq} />
<Route path="/session/:id/feedback" component={SessionFeedback} />
<Route path="/mini-games/lucky-cards/:id" component={LuckyCards} />
<Route path="/mini-games/roulette/:id" component={Roulette} />
<Route path="/mini-games/dice" component={Dices} />
<Route path="/*" component={NotFound} />
<Footer />
