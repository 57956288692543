<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { ExperienceLanguages } from '@/interface/IExperience';
  import type { FilterOption } from '@/lib/components/filter-dropdowns/filter-dropdown.types';
  import FilterDropdown from '@/lib/components/filter-dropdowns/FilterDropdown.svelte';
  import type { LanguageSelectorEvent } from '@/lib/components/filter-dropdowns/language-selector/language-selector.types';
  import { t } from '@/locales/i18n';

  const dispatch = createEventDispatcher<{
    changeLanguage: LanguageSelectorEvent;
  }>();

  export let selectedLanguage: string;

  const languageOptions: FilterOption<string>[] = Object.values(
    ExperienceLanguages,
  ).map((language) => ({
    value: language,
    label: language === ExperienceLanguages.english ? 'English' : 'Español',
  }));

  function handleSelectionChange(event: CustomEvent<{ values: string[] }>) {
    const values = event.detail.values;
    if (values.length > 0) {
      const language = values[values.length - 1]!;
      selectedLanguage = language;
      dispatch('changeLanguage', { language });
    }
  }
</script>

<div class="min-w-[150px]" data-cy="experience-language-selector">
  <FilterDropdown
    selectedValues={[selectedLanguage]}
    options={languageOptions}
    title={$t('dashboard.experiences.language-label')}
    placeholderText={$t('dashboard.experiences.select-language')}
    multipleSelectionText={$t('dashboard.experiences.language-selected')}
    clearText={$t('common.clear')}
    allowClean={false}
    on:changeSelection={handleSelectionChange}
  />
</div>
