import type { SubscriptionPlan } from './IExperience';
import type { ISessionUser } from './ISessionUser';

export enum UserRole {
  SUPER_ADMIN = 'Super admin',
  ADMIN = 'Admin',
  HOST = 'Host',
  USER = 'User',
}

export interface IUser {
  id: number;
  name: string;
  lastName: string;
  email: string;
  role: UserRole;
  sessions: ISessionUser[];
  tenant: ITenant;
  phoneNumber: string;
  avatarUrl?: string;
  isConfirmed: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}

export interface ITenant {
  id: number;
  name: string;
  subscriptionPlan: SubscriptionPlan;
  subscriptionExpirationDate: Date | null;
  seats: number;
  subscriptionId: string;
  slackIntegrationEnabled: boolean;
  slackWorkspaceId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
