const TOUR_KEY = 'tourMade';

export const hasUserCompletedTour = (): boolean => {
  return localStorage.getItem(TOUR_KEY) === 'true';
};

export const markTourAsCompleted = (): void => {
  localStorage.setItem(TOUR_KEY, 'true');
};

export const resetTourState = (): void => {
  localStorage.removeItem(TOUR_KEY);
};
