<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from '@/locales/i18n';

  import { cropperStore } from '../stores/cropper.store';

  export let imageFile: File;
  export let onSave: () => void;
  export let onCancel: () => void;

  onMount(() => {
    setTimeout(() => {
      const img = document.querySelector('#cropperImage');
      if (img instanceof HTMLImageElement) {
        cropperStore.initCropper(img);
      }
    }, 100);
  });
</script>

<div
  class="fixed inset-0 z-[100] flex items-center justify-center bg-black/50"
  data-cy="cropper-modal"
>
  <div
    class="relative h-[600px] w-[800px] overflow-hidden rounded-lg bg-white p-4"
  >
    <div class="h-[500px] w-full">
      <img
        id="cropperImage"
        data-cy="cropper-image"
        src={URL.createObjectURL(imageFile)}
        alt="crop preview"
        class="max-h-full max-w-full"
      />
    </div>
    <div class="absolute bottom-4 right-4 flex gap-2">
      <button
        type="button"
        class="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600 disabled:opacity-50"
        disabled={$cropperStore.isProcessing}
        on:click={onCancel}
        data-cy="cropper-cancel-btn"
      >
        {$t('dashboard.settings.profile.form.cancel-btn')}
      </button>
      <button
        type="button"
        class="rounded bg-primary px-4 py-2 text-white hover:bg-primary/90 disabled:opacity-50"
        disabled={$cropperStore.isProcessing}
        on:click={onSave}
        data-cy="cropper-save-btn"
      >
        {$t('dashboard.settings.profile.form.crop-btn')}
      </button>
    </div>
  </div>
</div>
