<script lang="ts">
  import { navigate } from 'svelte-navigator';
  import ChevronIcon from '@/assets/svg/ChevronIcon.svelte';
  import type { ITeam } from '@/interface/ITeam';
  import Loading from '@/lib/components/loading/Loading.svelte';
  import { getTeamById } from '@/lib/services/together/together.api';
  import { t } from '@/locales/i18n';

  import TeamMembersSection from './TeamMembersSection.svelte';
  import TeamSessionsSection from './TeamSessionsSection.svelte';

  export let id: string;

  let team: ITeam | null = null;
  let loading = true;
  let activeTab = 'members';

  const fetchTeam = async () => {
    loading = true;
    team = await getTeamById(Number(id));
    loading = false;
  };

  const switchTab = (tab: string) => {
    activeTab = tab;
  };

  $: fetchTeam();
</script>

<svelte:head>
  <title>{$t('dashboard.teams.route-title')}</title>
</svelte:head>

<div class="flex h-full w-full flex-col">
  {#if loading}
    <div class="flex h-full w-full grow flex-col items-center justify-center">
      <Loading loadingSize="h-32 w-32" />
    </div>
  {:else if team}
    <div
      class="flex h-16 w-full flex-row items-center justify-start border-b border-light-grey px-6"
    >
      <div
        class="flex flex-row items-center gap-2 py-4 hover:cursor-pointer"
        on:keypress
        on:click={() => navigate(-1)}
      >
        <ChevronIcon style="rotate-180 fill-primary" />
        <h1 class="mt-1 text-xl font-bold text-primary">{team.name}</h1>
      </div>
    </div>

    <div class="my-2 ms-6 flex flex-wrap items-center gap-2">
      <button
        class={`rounded-t-lg px-4 py-2 text-gray-600 hover:bg-primary hover:text-white focus:bg-primary focus:opacity-90 focus:outline-none ${
          activeTab === 'members' ? 'bg-primary text-white opacity-90' : ''
        }`}
        on:click={() => switchTab('members')}
        data-cy="team-tab-members"
      >
        {$t('dashboard.teams.view.team-title')}
      </button>
      <button
        class={`rounded-t-lg px-4 py-2 text-gray-600 hover:bg-primary hover:text-white focus:bg-primary focus:opacity-90 focus:outline-none ${
          activeTab === 'sessions' ? 'bg-primary text-white opacity-90' : ''
        }`}
        on:click={() => switchTab('sessions')}
        data-cy="team-tab-sessions"
      >
        {$t('dashboard.teams.view.team-sessions-title')}
      </button>
    </div>

    <div class="p-6">
      {#if activeTab === 'members'}
        <div class="flex flex-col gap-2">
          <TeamMembersSection team={team} onRefresh={fetchTeam} />
        </div>
      {:else if activeTab === 'sessions'}
        <div class="flex flex-col gap-2">
          <TeamSessionsSection teamId={+id} />
        </div>
      {/if}
    </div>
  {/if}
</div>
