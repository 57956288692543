<script lang="ts">
  export let class_ = 'h-5 w-5';
  export let style = '';
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  class={class_}
  style={style}
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M5 13l4 4L19 7"
  />
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M15 3h5a2 2 0 012 2v14a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5"
  />
</svg>
