import type { SvelteComponent } from 'svelte';

export interface TableItem {
  [key: string]: any;
}

export type SortOrder = 'asc' | 'desc';

export interface SortEvent {
  column: string;
  order: SortOrder;
}

export enum TemplateType {
  AVATAR = 'avatar',
  ICON = 'icon',
  BADGE = 'badge',
}

export enum BadgeVariant {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export interface AvatarTemplate {
  type: TemplateType.AVATAR;
  url: string;
}

export interface IconTemplate {
  type: TemplateType.ICON;
  component: typeof SvelteComponent;
}

export interface BadgeTemplate {
  type: TemplateType.BADGE;
  variant: BadgeVariant;
}

export type TemplateTypes = AvatarTemplate | IconTemplate | BadgeTemplate;

export interface Column<T extends TableItem> {
  key: string;
  label: string;
  width?: number;
  sortable?: boolean;
  render: (item: T) => {
    template?: TemplateTypes;
    text: string;
  };
}

export interface Action<T extends TableItem> {
  name: string;
  infoText: string;
  onAction: (item: T) => void;
  disabledCondition?: (item: T) => boolean;
  hideOnDisabled?: boolean;
  icon: typeof SvelteComponent;
}
