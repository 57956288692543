<script lang="ts">
  import moment from 'moment';
  import StarIcon from '@/assets/svg/StarIcon.svelte';
  import type { ISessionUser } from '@/interface/ISessionUser';
  import Avatar from '@/lib/components/avatar/Avatar.svelte';
  import { t } from '@/locales/i18n';

  export let sessionUser: ISessionUser;

  $: feedbackDate = moment(sessionUser.updatedAt).fromNow();
  $: userFullName = `${sessionUser.user.name} ${sessionUser.user.lastName}`;
  $: ratingLabel = $t('components.feedback-card.rating-label', {
    value: sessionUser.feedbackRating,
  });
  $: commentLabel = $t('components.feedback-card.comment-label');
  $: cardLabel = $t('components.feedback-card.aria-label', {
    name: userFullName,
    rating: sessionUser.feedbackRating,
    date: feedbackDate,
  });
</script>

<div
  data-cy="feedback-card"
  class="min-h-32 grid h-auto w-[24rem] shrink-0 overflow-hidden rounded-lg border border-light-grey bg-white p-4 shadow-sm transition-all duration-200 hover:shadow-md"
  role="article"
  aria-label={cardLabel}
>
  <div
    class="mb-2 flex flex-row items-start gap-3 border-b border-light-grey pb-2"
  >
    <span aria-hidden="true">
      <Avatar
        src={sessionUser.user.avatarUrl}
        size="sm"
        className="border-2 border-gray-200"
      />
    </span>
    <div class="flex flex-grow flex-col">
      <div class="flex w-full flex-row items-center justify-between">
        <span
          class="truncate text-sm font-bold text-primary"
          role="heading"
          aria-level={3}>{userFullName}</span
        >
        <span class="text-xs text-grey" aria-label={feedbackDate}
          >{feedbackDate}</span
        >
      </div>
      <div class="flex flex-row items-center gap-1" aria-label={ratingLabel}>
        <span class="font-medium text-amber-500"
          >{sessionUser.feedbackRating}</span
        >
        <span aria-hidden="true">
          <StarIcon style="fill-amber-500 w-3 h-3" />
        </span>
      </div>
    </div>
  </div>

  <div
    class="max-h-24 w-full overflow-y-auto rounded-md bg-gray-50 p-3 pr-5"
    role="region"
    aria-label={commentLabel}
    tabindex="-1"
  >
    <p class="whitespace-pre-wrap break-words text-xs text-grey">
      {sessionUser.feedbackComment}
    </p>
  </div>
</div>
