<script lang="ts">
  export let name = '';
  export let text = 'menu button';
  export let selected = false;
  export let ariaLabel: string | undefined = undefined;
  export let ariaCurrent:
    | 'page'
    | 'step'
    | 'location'
    | 'date'
    | 'time'
    | 'true'
    | 'false'
    | boolean
    | null
    | undefined = undefined;

  $: selectedStyles = selected
    ? 'text-primary border-r-4 border-r-primary'
    : 'text-dark-grey';
</script>

<button
  class={`${selectedStyles} flex w-full flex-row items-center gap-5 px-10 py-2 font-semibold transition duration-200 ease-in hover:text-primary`}
  on:click
  data-cy="sidebar-menu-button-{name}"
  data-tour="sidebar-menu-button-{name}"
  aria-label={ariaLabel}
  aria-current={ariaCurrent}
>
  <slot />

  <span>{text}</span>
</button>
